import { Add, Delete, Done, Edit, Handshake, House } from "@mui/icons-material";
import { Button, Collapse, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { Frequency, TransactionAccountRequest } from "../../api_client";
import { DisplayState, MortgageVM, RealEstateVM, TransactionAccountVM } from "../Portfolio";
import SliderInput from "../../MultiGoal/SliderInput";
import { CurrencyFormatCustom, PercentFormatCustom, TextNumberFormat } from "../../NumberFormatCustom";
import { SettingsContext } from "../../settings/SettingsContext";
import { AssetsAndDebtsDisplayHeader } from "./AssetsAndDebtsDisplayHeader";

export default function MortgageView(props: { mortgage: MortgageVM, setMortgage: (newValue: MortgageVM) => void, onDelete: () => void, accounts: { name: string, id: string }[] }) {

    const mortgage = props.mortgage;
    const setMortgage = props.setMortgage;
    const theme = useTheme();
    const settings = useContext(SettingsContext);


    return (
        <div>
            <Collapse in={mortgage.displayState === DisplayState.Edit || mortgage.displayState === undefined} timeout='auto' unmountOnExit>
                <Grid container>
                    <Grid item xs>
                        <TextField
                            variant='filled'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Handshake />
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginBottom: theme.spacing(2) }}
                            margin='none'
                            size='small'
                            fullWidth={true}
                            required label={'Name'}
                            onChange={(e) => {
                                setMortgage({ ...mortgage, name: e.target.value });
                            }}
                            value={mortgage.name}
                        />
                    </Grid>
                    {/* <Grid item xs='auto'>
                        <IconButton style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={props.onDelete}>
                            <Delete />
                        </IconButton>
                    </Grid> */}
                    <Grid item xs='auto'>
                        <IconButton style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={() => setMortgage({ ...mortgage, displayState: DisplayState.Display })}>
                            <Done />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            style={{ marginBottom: theme.spacing(1) }}
                            InputProps={{ inputComponent: CurrencyFormatCustom }}
                            margin='none'
                            size="small"
                            fullWidth={true}
                            required label='Current value'
                            onChange={(e) => {
                                setMortgage({ ...mortgage, currentValue: Number(e.target.value) });
                            }}
                            value={mortgage.currentValue} />
                    </Grid>
                    <Grid item xs='auto'>
                        <TextField
                            error={mortgage.annualInterestRate < 0}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputComponent: PercentFormatCustom }}
                            style={{ width: theme.spacing(12) }}
                            // variant='filled'
                            margin='none'
                            size="small"
                            // fullWidth={true}
                            required label='Interest rate'
                            onChange={(e) => {
                                setMortgage({ ...mortgage, annualInterestRate: Number(e.target.value) / 100 })
                            }}
                            value={mortgage.annualInterestRate * 100} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs>
                            <SliderInput
                                value={mortgage.durationUnit === Frequency.Annual ? mortgage.fixedRatePeriodInMonths / 12 : mortgage.fixedRatePeriodInMonths}
                                onChange={(newValue: any) => {
                                    setMortgage({ ...mortgage, fixedRatePeriodInMonths: mortgage.durationUnit === Frequency.Annual ? newValue * 12 : newValue });
                                }}
                                minValue={1}
                                maxValue={mortgage.durationUnit === Frequency.Annual ? 40 : 480} title={'Fixed-rate period'} />
                        </Grid>
                        <Grid item xs='auto'>
                            <FormControl size="small">
                                {/* <InputLabel id="durationUnit-select">Unit</InputLabel> */}
                                <Select
                                    // label="Frequency"
                                    labelId="durationUnit-select"
                                    style={{ width: theme.spacing(12) }}

                                    value={mortgage.durationUnit} onChange={e => {
                                        setMortgage({ ...mortgage, durationUnit: e.target.value as Frequency });
                                    }}>
                                    <MenuItem value={Frequency.Annual}>Years</MenuItem>
                                    <MenuItem value={Frequency.Monthly}>Months</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <FormControl fullWidth={true} size="small" error={!props.accounts.find(a => a.id === mortgage.interestRatePaymentAccountId) && mortgage.interestRatePaymentAccountId !== ''}>
                            <InputLabel required id="source-select">Interest rate payment account</InputLabel>
                            <Select label="Interest rate payment account"
                                labelId="source-select"
                                required
                                fullWidth={true}
                                value={mortgage.interestRatePaymentAccountId}
                                onChange={e => {
                                    setMortgage({ ...mortgage, interestRatePaymentAccountId: e.target.value });
                                }}>
                                {props.accounts.map(a => (<MenuItem value={a.id}>{a.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid> */}

                </Grid>
            </Collapse>
            <Collapse in={mortgage.displayState === DisplayState.Display} timeout="auto" unmountOnExit>
                <AssetsAndDebtsDisplayHeader
                    name={mortgage.name}
                    currentValue={-mortgage.currentValue}
                    delete={props.onDelete}
                    color={mortgage.color}
                    edit={() => setMortgage({ ...mortgage, displayState: DisplayState.Edit })} />
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="caption" color='text.secondary'>{
                            mortgage.annualInterestRate * 100}%, fixed: {mortgage.durationUnit === Frequency.Annual ? mortgage.fixedRatePeriodInMonths / 12 : mortgage.fixedRatePeriodInMonths
                            } {mortgage.durationUnit === Frequency.Annual ? 'year(s)' : 'month(s)'}</Typography>
                    </Grid>
                </Grid>
            </Collapse>

        </div>
    );
}