import {
    useState,
    useCallback,
} from 'react';

import { InteractionType, PopupRequest } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { loginRequest } from '../authConfig';
import { historicalUri } from '../api_client/tsClient';
import moment from 'moment';
import { Configuration, HistoricalDataController2Api, ITimeSeriesPoint } from '../historicalApiClient';


const priceIndexCache = {} as any;

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */
const useHistoricalApi = () => {
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const tokenRequest = {
        account: instance.getActiveAccount(),
        scopes: loginRequest.scopes
    }

    const getClient = () => instance.acquireTokenSilent(tokenRequest).then((response) => {
        // Call your API with the access token and return the data you need to save in state
        const customFetch: WindowOrWorkerGlobalScope['fetch'] = async (input: RequestInfo, init?: RequestInit) => {
            const bearer = `Bearer ${response.accessToken}`;
            
            const customHeaders = {
                'Authorization': bearer
            };

            if (init && init.headers) {
                init.headers = new Headers(init.headers);
                Object.entries(customHeaders).forEach(([key, value]) => {
                    (init.headers as Headers).append(key, value);
                });
            } else {
                init = { ...init, headers: customHeaders };
            }

            return fetch(input, init);
        };
        return new HistoricalDataController2Api(undefined, historicalUri, customFetch);
        // return response;
    }).catch(async (e) => {
        instance.acquireTokenRedirect(tokenRequest).then(r=>console.log('got token'));
        throw e;
    });

    const priceIndex = async (isin: string): Promise<ITimeSeriesPoint[]> => {
        if (priceIndexCache[isin] !== undefined) {
            return Promise.resolve(priceIndexCache[isin]);
        }
        else {
            try {
                setIsLoading(true);
                const client = await getClient();
                const result = (await client.apiV2TimeSeriesMarketContractGet(isin, moment().add(-10, 'years').toDate(), moment().toDate()))?.[0];
                priceIndexCache[isin] = result;
                return result;
            } catch (e) {
                console.log(e);
                setError(e);
                setIsLoading(false);
                throw e;
            }
        }
    };
    return {
        isLoading,
        error,
        data,
        priceIndex: useCallback(priceIndex, [])
    };
};

export default useHistoricalApi;