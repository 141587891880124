import { Add, Delete, Done, Edit, House } from "@mui/icons-material";
import { Button, Collapse, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { TransactionAccountRequest } from "../../api_client";
import { DisplayState, RealEstateVM, TransactionAccountVM } from "../../financialPlanning/Portfolio";
import { CurrencyFormatCustom, TextNumberFormat } from "../../NumberFormatCustom";
import { SettingsContext } from "../../settings/SettingsContext";
import { AssetsAndDebtsDisplayHeader } from "./AssetsAndDebtsDisplayHeader";

export default function RealEstateView(props: { realEstate: RealEstateVM, setRealEstate: (newValue: RealEstateVM) => void, onDelete: () => void }) {

    const realEstate = props.realEstate;
    const setRealEstate = props.setRealEstate;
    const theme = useTheme();
    const settings = useContext(SettingsContext);

    return (
        <div>
            <Collapse in={realEstate.displayState === DisplayState.Edit || realEstate.displayState === undefined} timeout='auto' unmountOnExit>
                <Grid container>
                    <Grid item xs>
                        <TextField
                            variant='filled'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <House />
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginBottom: theme.spacing(2) }}
                            margin='none'
                            size='small'
                            fullWidth={true}
                            required label={'Name'}
                            onChange={(e) => {
                                setRealEstate({ ...realEstate, name: e.target.value });
                            }}
                            value={realEstate.name}
                        />
                    </Grid>
                    {/* <Grid item xs='auto'>
                        <IconButton style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={props.onDelete}>
                            <Delete />
                        </IconButton>
                    </Grid> */}
                    <Grid item xs='auto'>
                        <IconButton style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={() => setRealEstate({ ...realEstate, displayState: DisplayState.Display })}>
                            <Done />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputComponent: CurrencyFormatCustom }}
                            // variant='filled'
                            margin='none'
                            size="small"
                            fullWidth={true}
                            // fullWidth={true}
                            required label='Current value'
                            onChange={(e) => {
                                setRealEstate({ ...realEstate, currentValue: Number(e.target.value) });
                            }}
                            value={realEstate.currentValue} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputComponent: CurrencyFormatCustom }}
                            // variant='filled'
                            margin='none'
                            size="small"
                            fullWidth={true}
                            // fullWidth={true}
                            required label='Acquisition value'
                            onChange={(e) => {
                                setRealEstate({ ...realEstate, acquisitionValue: Number(e.target.value) });
                            }}
                            value={realEstate.acquisitionValue} />
                    </Grid>
                </Grid>
            </Collapse>
            <Collapse in={realEstate.displayState === DisplayState.Display} timeout="auto" unmountOnExit>
                <AssetsAndDebtsDisplayHeader
                    name={realEstate.name}
                    currentValue={realEstate.currentValue}
                    delete={props.onDelete}
                    color={realEstate.color}
                    edit={() => setRealEstate({ ...realEstate, displayState: DisplayState.Edit })} />
            </Collapse>

        </div>
    );
}