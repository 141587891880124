import { AccountCircle, Add, BusinessCenter, Delete, Done, Edit, FeedSharp, Home, HomeOutlined, Percent, Warning } from "@mui/icons-material";
import { Badge, Button, Collapse, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, SvgIcon, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useContext } from "react";
import { InvestmentAccountRequest, InvestmentAccountTaxationType, InvestmentStrategyType, RiskLevel } from "../../api_client";
import { DisplayState, InvestmentAccountVM, ProductCodeVM, WeightCalculationType } from "../Portfolio";
import { PercentFormatCustom, TextNumberFormat } from "../../NumberFormatCustom";
import { SettingsContext } from "../../settings/SettingsContext";
import { isAbsolute } from "path";
import { InvestmentAllocationsView } from "./InvestmentAllocationsView";
import { AssetsAndDebtsDisplayHeader } from "./AssetsAndDebtsDisplayHeader";

export function InvestmentAccountView(props: { investmentAccount: InvestmentAccountVM, setInvestmentAccount: (newValue: InvestmentAccountVM) => void, onDelete: () => void }) {

    const investmentAccount = props.investmentAccount as InvestmentAccountVM;
    const setInvestmentAccount = props.setInvestmentAccount;
    const theme = useTheme();
    const settings = useContext(SettingsContext);
    //ProductCode must only appear once per account

    const hasDuplicate = Object.values(investmentAccount.investmentAllocations.map(a => a.productCode?.code).reduce((total: any, value) => {
        total[value] = (total[value] || 0) + 1;
        return total;
    }, {})).find(count => count > 1) !== undefined;


    const hasInvalidWeight = Math.abs(1 - investmentAccount.investmentAllocations.reduce((a, b) => a + b.strategyWeight, 0)) > 0.01;

    const errorText = (hasInvalidWeight ? 'Total weight of allocations must be 100%. ' : '') + (hasDuplicate ? 'Product must only appear once per account.' : '');
    return (
        <div>
            <Collapse in={investmentAccount.displayState === DisplayState.Edit || investmentAccount.displayState === undefined} timeout='auto' unmountOnExit>
                <Grid container>
                    <Grid item xs>
                        <TextField
                            variant='filled'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <BusinessCenter />
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginBottom: theme.spacing(2) }}
                            margin='none'
                            size='small'
                            fullWidth={true}
                            required label={'Name'}
                            onChange={(e) => {
                                setInvestmentAccount({ ...investmentAccount, name: e.target.value });
                            }}
                            value={investmentAccount.name} />
                    </Grid>
                    <Grid item xs='auto'>
                        <Tooltip title={errorText}>
                            <Badge color='warning' badgeContent={errorText === '' ? undefined : '!'}>
                                <IconButton style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={() => setInvestmentAccount({ ...investmentAccount, displayState: DisplayState.Display })}>
                                    <Done />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                    </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginBottom: theme.spacing(1) }}>
                    <Grid item xs={8}>
                        <FormControl fullWidth={true} size="small" >
                            <InputLabel id="taxation-select">Account type</InputLabel>
                            <Select label="Account type"
                                labelId="taxation-select"
                                fullWidth={true} 
                                value={investmentAccount.taxationType} 
                                onChange={e => {
                                    setInvestmentAccount({ ...investmentAccount, taxationType: e.target.value as InvestmentAccountTaxationType });
                                }}>
                                <MenuItem value={InvestmentAccountTaxationType.NoTax}>No tax</MenuItem>
                                <MenuItem value={InvestmentAccountTaxationType.SweEndowmentInsurance}>Endowment insurance</MenuItem>
                                <MenuItem value={InvestmentAccountTaxationType.SweIsk}>ISK</MenuItem>
                                <MenuItem value={InvestmentAccountTaxationType.SweCapitalGains}>Capital gains tax</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            error={investmentAccount.annualAccountFee < 0}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ inputComponent: PercentFormatCustom }}
                            margin='none'
                            fullWidth={true}
                            // sx={{ width: theme.spacing(14) }}
                            size="small"
                            required
                            label='Fee'
                            onChange={(e) => setInvestmentAccount({ ...investmentAccount, annualAccountFee: Number(e.target.value) / 100 })}
                            value={investmentAccount.annualAccountFee * 100} />
                    </Grid>

                </Grid>

                {/* <FormControl fullWidth={true} size="small">
                    <InputLabel id="strategy-select">Strategy</InputLabel>
                    <Select label="Strategy"
                        labelId="strategy-select"
                        fullWidth={true} value={investmentAccount.strategy} onChange={e => {
                            setInvestmentAccount({ ...investmentAccount, strategy: e.target.value as InvestmentStrategyType });
                        }}>
                        <MenuItem value={InvestmentStrategyType.BuyAndHold}>Buy and hold</MenuItem>
                        <MenuItem value={InvestmentStrategyType.RebalanceToPlan}>Rebalance to plan</MenuItem>
                    </Select>
                </FormControl> */}
                <InvestmentAllocationsView
                    title="Allocations"
                    investmentAllocations={investmentAccount.investmentAllocations}
                    setInvestmentAllocations={(e, s) => {
                        setInvestmentAccount({
                            ...investmentAccount,
                            investmentAllocations: e,
                            // deriveWeightsFromCurrentValue: s.deriveWeightsFromCurrentValue,
                            weightCalculationType: s.weightCalculationType,
                            optimizationHorizonInYears: s.optimizationHorizonInYears,
                            optimizationRiskLevel: s.optimizationRiskLevel,
                            strategy: s.investmentStrategy
                        })
                    }}
                    strategySettings={
                        {
                            weightCalculationType: investmentAccount.weightCalculationType !== undefined ? investmentAccount.weightCalculationType : WeightCalculationType.NoCalculation,
                            optimizationHorizonInYears: investmentAccount.optimizationHorizonInYears !== undefined ? investmentAccount.optimizationHorizonInYears : 10,
                            optimizationRiskLevel: investmentAccount.optimizationRiskLevel !== undefined ? investmentAccount.optimizationRiskLevel : RiskLevel.MediumHighRisk,
                            investmentStrategy: investmentAccount.strategy
                        }
                    }
                // setStrategySettings={(newValue: {deriveWeightsFromCurrentValue: boolean, investmentStrategy: InvestmentStrategyType}) => {
                //     setInvestmentAccount({
                //         ...investmentAccount,
                //         deriveWeightsFromCurrentValue: newValue.deriveWeightsFromCurrentValue,
                //         strategy: newValue.investmentStrategy
                //     })
                // }}
                />
            </Collapse>
            <Collapse in={investmentAccount.displayState === DisplayState.Display} timeout="auto" unmountOnExit>

                <AssetsAndDebtsDisplayHeader
                    name={investmentAccount.name}
                    currentValue={investmentAccount.investmentAllocations?.reduce((accumulator, currentValue) => accumulator + currentValue.currentValue, 0)}
                    delete={props.onDelete}
                    color={investmentAccount.color}
                    edit={() => setInvestmentAccount({ ...investmentAccount, displayState: DisplayState.Edit })}
                    errorText={errorText} />
                <Grid container>
                    {investmentAccount.investmentAllocations?.map((a, i) => {
                        return (<Grid key={a.productCode?.code} item xs={12}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography style={{ marginLeft: theme.spacing(1) }} variant="caption">{(a.productCode as ProductCodeVM)?.name}</Typography>
                                </Grid>
                                <Grid item xs='auto'>
                                    <Typography style={{ paddingRight: theme.spacing(1) }} variant="caption">{Math.round(a.strategyWeight * 10000) / 100}%</Typography>
                                </Grid>
                            </Grid>
                        </Grid>)
                    })}

                </Grid>
            </Collapse>
        </div>
    );

}

export function areEqual(prevProps: any, nextProps: any) {

    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
        return true;
    }
    return false;
}

function mapTaxation(type: InvestmentAccountTaxationType): string {
    switch (type) {
        case InvestmentAccountTaxationType.SweIsk:
            return "ISK";
        case InvestmentAccountTaxationType.SweEndowmentInsurance:
            return "Endowment insurance";
        case InvestmentAccountTaxationType.SweCapitalGains:
            return "Capital gains tax";
        case InvestmentAccountTaxationType.NoTax:
            return "No tax";
    }
    return "Not mapped";
}

export default React.memo(InvestmentAccountView);