import { Delete, Edit } from "@mui/icons-material";
import { Badge, Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { TextNumberFormat } from "../../NumberFormatCustom";

export function AssetsAndDebtsDisplayHeader(props: { name: string; currentValue: number; edit: () => void; delete: () => void; color: string; errorText?: string }) {
    const theme = useTheme();

    return <Grid container>
        <Grid item xs={'auto'}>
            <div style={{ background: props.color, width: 2, height: '100%', marginRight: 3, marginLeft: -7 }}></div>
        </Grid>
        <Grid item xs style={{ paddingTop: 6 }}>
            <Typography variant="body2">{props.name}</Typography>
        </Grid>
        <Grid item xs='auto' style={{ paddingTop: 6, width: theme.spacing(12) }}>
            <Typography variant="body2"><TextNumberFormat roundToNearest={1} value={props.currentValue} /></Typography>
        </Grid>
        <Grid item xs='auto'>
            <IconButton style={{ color: theme.palette.text.secondary }} size="small" onClick={props.edit}>
                <Edit fontSize="small" />
            </IconButton>
        </Grid>
        <Grid item xs='auto'>
            <Tooltip title={props.errorText}>
                <Badge color='warning' badgeContent={props.errorText === undefined || props.errorText === '' ? undefined : '!'}>
                    <IconButton style={{ color: theme.palette.text.secondary }} size="small" onClick={props.delete}>
                        <Delete fontSize="small" />
                    </IconButton>
                </Badge>
            </Tooltip>
        </Grid>
    </Grid>;
}
