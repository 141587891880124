import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";

export function PortfolioStats(props: { statistics: { horizon: number; cagr: number; volatility: number; }[]; }) {
    const theme = useTheme();
    const { statistics } = props;
    const itemWidth = 7;

    return <Grid container>
        <Grid item xs>
            <Typography variant='caption' color='text.secondary'>Statistics</Typography><br />
        </Grid>
        <Grid item xs='auto'>
            <div style={{ width: theme.spacing(itemWidth) }}>
                <Typography variant='caption' color='text.secondary'>1Y</Typography><br />
            </div>
        </Grid>
        <Grid item xs='auto'>
            <div style={{ width: theme.spacing(itemWidth) }}>
                <Typography variant='caption' color='text.secondary'>3Y</Typography><br />
            </div>
        </Grid>
        <Grid item xs='auto'>
            <div style={{ width: theme.spacing(itemWidth) }}>
                <Typography variant='caption' color='text.secondary'>5Y</Typography><br />
            </div>
        </Grid>
        <Grid item xs='auto'>
            <div style={{ width: theme.spacing(itemWidth) }}>
                <Typography variant='caption' color='text.secondary'>10Y</Typography><br />
            </div>
        </Grid>
        <Grid item xs={12}></Grid>
        {statistics ? <><Grid item xs>
            <Typography variant='caption'>Cagr</Typography><br />
        </Grid>
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption'>{statistics.find(s => s.horizon === 1).cagr}</Typography><br />
                </div>
            </Grid>
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption'>{statistics.find(s => s.horizon === 3).cagr}</Typography><br />
                </div>
            </Grid>
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption'>{statistics.find(s => s.horizon === 5).cagr}</Typography><br />
                </div>
            </Grid>
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption'>{statistics.find(s => s.horizon === 10).cagr}</Typography><br />
                </div>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs>
                <Typography variant='caption'>Volatility</Typography><br />
            </Grid>
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption'>{statistics.find(s => s.horizon === 1).volatility}</Typography><br />
                </div>
            </Grid>
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption'>{statistics.find(s => s.horizon === 3).volatility}</Typography><br />
                </div>
            </Grid>
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption'>{statistics.find(s => s.horizon === 5).volatility}</Typography><br />
                </div>
            </Grid>
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption'>{statistics.find(s => s.horizon === 10).volatility}</Typography><br />
                </div>
            </Grid>
            <Grid item xs={12}></Grid></>
            : <></>}
    </Grid>;
}
