import React from 'react';
import {Typography, TextField} from '@mui/material';

export function ResultOutput(props : any) : any{

    const text = props.text;

    return (
        <div>
            <Typography variant='body1'>
                Response
            </Typography>
            <TextField
            type = "text"
            variant = "outlined"
            id="Result-Body"
            margin="normal"
            inputProps={
                {readOnly: true}
            }
            multiline
            fullWidth
            rows={20}
            value = {JSON.stringify(text, null, 2) }
            ></TextField>
        </div>
    )
}
