import { Add, Done, Money } from "@mui/icons-material";
import { Button, Collapse, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { CurrencyRequest, TransactionAccountRequest } from "../../api_client";
import { DisplayState, TransactionAccountVM } from "../../financialPlanning/Portfolio";
import { CurrencyFormatCustom, NumberFormatCustom } from "../../NumberFormatCustom";
import { SettingsContext } from "../../settings/SettingsContext";
import { AssetsAndDebtsDisplayHeader } from "./AssetsAndDebtsDisplayHeader";

export default function TransactionAccountView(props: { transactionAccount: TransactionAccountVM, setTransactionAccount: (newValue: TransactionAccountVM) => void, onDelete: () => void }) {

    const transactionAccount = props.transactionAccount as TransactionAccountVM;
    const setTransactionAccount = props.setTransactionAccount;
    const theme = useTheme();


    return (<div>
        <Collapse in={transactionAccount.displayState === DisplayState.Edit || transactionAccount.displayState === undefined} timeout="auto" unmountOnExit>
            <Grid container>
                <Grid item xs>
                    <TextField
                        variant='filled'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Money />
                                </InputAdornment>
                            ),
                        }}
                        style={{ marginBottom: theme.spacing(2) }}
                        margin='none'
                        size='small'
                        fullWidth={true}
                        required label={'Name'}
                        onChange={(e) => {
                            setTransactionAccount({ ...transactionAccount, name: e.target.value });
                        }}
                        value={transactionAccount.name}
                    />
                </Grid>
                {/* <Grid item xs='auto'>
                    <IconButton style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={props.onDelete}>
                        <Delete />
                    </IconButton>
                </Grid> */}
                <Grid item xs='auto'>
                    <IconButton style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={() => setTransactionAccount({ ...transactionAccount, displayState: DisplayState.Display })}>
                        <Done />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputComponent: NumberFormatCustom }}
                        // variant='filled'
                        margin='none'
                        size="small"
                        fullWidth={true}
                        // fullWidth={true}
                        required label='Current value'
                        onChange={(e) => {
                            setTransactionAccount({ ...transactionAccount, currentValue: Number(e.target.value) });
                        }}
                        value={transactionAccount.currentValue} />
                </Grid>
                <Grid item xs='auto'>
                    <FormControl fullWidth={true} size="small">
                        <InputLabel id="currency-select">Currency</InputLabel>
                        <Select label="Currency"
                            labelId="currency-select"
                            fullWidth={true} value={transactionAccount.currency} onChange={e => {
                                setTransactionAccount({ ...transactionAccount, currency: e.target.value as CurrencyRequest });
                            }}>
                            <MenuItem value={CurrencyRequest.SEK}>SEK</MenuItem>
                            <MenuItem value={CurrencyRequest.USD}>USD</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Collapse >
        <Collapse in={transactionAccount.displayState === DisplayState.Display} timeout="auto" unmountOnExit>
        <AssetsAndDebtsDisplayHeader name={transactionAccount.name} currentValue={transactionAccount.currentValue} delete={props.onDelete} color={transactionAccount.color} edit={() => setTransactionAccount({ ...transactionAccount, displayState: DisplayState.Edit })} />
        </Collapse>
    </div>);
}
