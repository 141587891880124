import { Help, Storage, SettingsBrightness, Timeline, Check, Menu as MenuIcon, TrendingDown } from "@mui/icons-material";
import { AppBar, Box, Container, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Toolbar, Typography, useTheme } from "@mui/material";
import React from "react";
import { useContext, useState } from "react";
import { defaultPortfolio } from "./financialPlanning/Portfolio";
import { PortfolioSelect } from "./MultiGoal/PortfolioSelect";
import { PortfolioContext } from "./settings/PortfolioContext";
import { SettingsContext } from "./settings/SettingsContext";

export default function AppMenuTop(props: { resetResults: () => void }) {

    const theme = useTheme();
    const settings = useContext(SettingsContext);
    const portfolioContext = useContext(PortfolioContext);


    const [anchorElSettings, setAnchorElSettings] = useState(null);
    const handleClickSettings = (event: any) => {
        setAnchorElSettings(event.currentTarget);
    };

    const handleCloseSettings = () => {
        setAnchorElSettings(null);
    };


    const [anchorElCurrency, setAnchorElCurrency] = useState(null);
    const handleClickCurrency = (event: any) => {
        setAnchorElCurrency(event.currentTarget);
    };

    const handleCloseCurrency = () => {
        handleCloseSettings();
        setAnchorElCurrency(null);
    };


    const [anchorElStorage, setAnchorElStorage] = useState(null);
    const handleClickStorage = (event: any) => {
        setAnchorElStorage(event.currentTarget);
    };

    const handleCloseStorage = () => {
        handleCloseSettings();
        setAnchorElStorage(null);
    };


    const [anchorElScenarioSet, setAnchorElScenarioSet] = useState(null);

    const handleClickScenarioSet = (event: any) => {
        setAnchorElScenarioSet(event.currentTarget);
    };

    const handleCloseScenarioSet = () => {
        handleCloseSettings();
        setAnchorElScenarioSet(null);
    };

    const [anchorElStressScenarioSet, setAnchorElStressScenarioSet] = useState(null);

    const handleClickStressScenarioSet = (event: any) => {
        setAnchorElStressScenarioSet(event.currentTarget);
    };

    const handleCloseStressScenarioSet = () => {
        handleCloseSettings();
        setAnchorElStressScenarioSet(null);
    };

    const scenarioSets = settings.availableScenarioSets;


    const importPortfolios = (e: any) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = (e) => {
            const text = reader.result as string
            var obj = JSON.parse(text);
            portfolioContext.setPortfolios(portfolioContext.portfolios.concat(obj));
        };
        reader.readAsText(e.target.files[0])
    }

    const exportPortfolios = () => {
        const element = document.createElement("a")
        var pretty = JSON.stringify(portfolioContext.portfolios, null, 2)
        const file = new Blob([pretty],
            { type: "text/plain" })

        element.href = URL.createObjectURL(file)
        element.download = "request.json"
        document.body.appendChild(element)
        element.click()
    }

    const fileInput = React.useRef();
    return (
        <AppBar variant={theme.palette.mode === "dark" ? "elevation" : "outlined"} elevation={0} color="transparent" position="static">
            <Toolbar disableGutters variant="dense">
                <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ width: theme.spacing(4), paddingTop: theme.spacing(1), paddingLeft: theme.spacing(2) }}
                >

                    <Box style={{ maxHeight: 30, maxWidth: 30 }}>
                        <img
                            height="25"
                            width="25"
                            src={settings.isLightTheme ? 'logolight.png' : 'logodark.png'}
                            alt=""
                        />
                    </Box>
                    <Typography color='text.secondary' style={{ fontSize: 12 }}>ONE</Typography>
                </Grid>
                <Container maxWidth="sm">
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                        <Paper variant="elevation" elevation={0} style={{ width: '100%', background: theme.palette.background.paper, padding: theme.spacing(1), marginBottom: 0, marginTop: 4 }}>
                            <PortfolioSelect resetResults={props.resetResults} />
                        </Paper>
                    </Box>
                </Container>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}></Box>

                <IconButton onClick={handleClickSettings} style={{ marginRight: theme.spacing(1) }}>
                    <MenuIcon style={{ color: theme.palette.text.secondary }}></MenuIcon>
                </IconButton>
                <Menu
                    id="settings-menu"
                    anchorEl={anchorElSettings}
                    keepMounted
                    open={Boolean(anchorElSettings)}
                    onClose={handleCloseSettings}
                >
                    <MenuItem>
                        <div onClick={handleClickScenarioSet}>
                            <IconButton >
                                <Timeline style={{ color: theme.palette.text.secondary }}></Timeline>
                            </IconButton>
                            Scenario set</div>
                        <Menu
                            id="scenario-sets-menu"
                            anchorEl={anchorElScenarioSet}
                            keepMounted
                            open={Boolean(anchorElScenarioSet)}
                            onClose={handleCloseScenarioSet}
                        >
                            {scenarioSets.map((p) => p.id === settings.currentScenarioSet.id
                                ? (<MenuItem key={p.id} onClick={() => {
                                    settings.setCurrentScenarioSet(p);
                                    handleCloseScenarioSet();
                                }}><ListItemIcon><Check /></ListItemIcon>{p.name}</MenuItem>)
                                : (<MenuItem key={p.id} onClick={() => {
                                    settings.setCurrentScenarioSet(p);
                                    handleCloseScenarioSet();
                                }}><ListItemText inset>{p.name}</ListItemText></MenuItem>))}
                        </Menu>
                    </MenuItem>
                    <MenuItem>
                        <div onClick={handleClickStressScenarioSet}>
                            <IconButton >
                                <TrendingDown style={{ color: theme.palette.text.secondary }}></TrendingDown>
                            </IconButton>
                            Historical stress
                        </div>
                        <Menu
                            id="stress-scenario-sets-menu"
                            anchorEl={anchorElStressScenarioSet}
                            keepMounted
                            open={Boolean(anchorElStressScenarioSet)}
                            onClose={handleCloseStressScenarioSet}
                        >
                            {settings.availableStressScenarioSets.map((p) => p.id === settings.currentScenarioSet.id
                                ? (<MenuItem key={p.id} onClick={() => {
                                    settings.setCurrentScenarioSet(p);
                                    handleCloseStressScenarioSet();
                                }}><ListItemIcon><Check /></ListItemIcon>{p.name}</MenuItem>)
                                : (<MenuItem key={p.id} onClick={() => {
                                    settings.setCurrentScenarioSet(p);
                                    handleCloseStressScenarioSet();
                                }}><ListItemText inset>{p.name}</ListItemText></MenuItem>))}
                        </Menu>
                    </MenuItem>
                    <MenuItem>
                        <div onClick={handleClickCurrency}>
                            <IconButton style={{ color: theme.palette.text.secondary }} >
                                <Typography variant='body2'> {settings.currency}</Typography>
                            </IconButton>
                            Currency
                        </div>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElCurrency}
                            keepMounted
                            open={Boolean(anchorElCurrency)}
                            onClose={handleCloseCurrency}
                        >
                            {settings.availableCurrencies.map(c => settings.currency === c ?
                                (<MenuItem key={c} onClick={() => {
                                    settings.setCurrency(c);
                                    handleCloseCurrency();
                                }}><ListItemIcon><Check /></ListItemIcon>{c}</MenuItem>)
                                : (<MenuItem key={c} onClick={() => {
                                    settings.setCurrency(c);
                                    handleCloseCurrency();
                                }}><ListItemText inset>{c}</ListItemText></MenuItem>))}
                        </Menu>
                    </MenuItem>
                    <MenuItem>
                        <div onClick={() => {
                            settings.setTheme(!settings.isLightTheme);
                        }}>
                            <IconButton aria-label="darkmode" style={{ color: theme.palette.text.secondary }}>
                                <SettingsBrightness />
                            </IconButton>
                            {settings.isLightTheme ? "Dark mode" : "Light mode"}
                        </div>
                    </MenuItem>
                    <MenuItem>
                        <div onClick={handleClickStorage}>
                            <IconButton style={{ color: theme.palette.text.secondary }}>
                                <Storage />
                            </IconButton>
                            Data management
                        </div>
                        <input
                            ref={fileInput}
                            type="file"
                            value=''
                            onChange={importPortfolios}
                            style={{ display: 'none' }}
                        />
                        <Menu
                            id="storage-menu"
                            anchorEl={anchorElStorage}
                            keepMounted
                            open={Boolean(anchorElStorage)}
                            onClose={handleCloseStorage}
                        >
                            <MenuItem key={'portfoliosfromfile'} onClick={(e) => {
                                const input: any = fileInput?.current;
                                input?.click();
                                handleCloseStorage();
                            }}>Import portfolios from file</MenuItem>
                            <MenuItem key={'portfoliosExport'} onClick={() => {
                                exportPortfolios();
                                handleCloseStorage();
                            }}>Export portfolios to file</MenuItem>
                            <MenuItem key={'portfoliosClear'} onClick={() => {
                                portfolioContext.setPortfolios([]);
                                handleCloseStorage();
                            }}>Delete all portfolios</MenuItem>
                        </Menu>
                    </MenuItem>
                </Menu>
            </Toolbar>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <Paper variant="elevation" elevation={0} style={{ width: '100%', background: theme.palette.background.default, padding: theme.spacing(1), marginBottom: 2, marginTop: 2 }}>
                    <PortfolioSelect resetResults={props.resetResults} />
                </Paper>
            </Box>
        </AppBar>
    );
}

