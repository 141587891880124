import React, { useContext, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import withStyles from '@mui/styles/withStyles';
// import makeStyles from '@mui/styles/makeStyles';
import { Snackbar, Grid, TextField, Button, List, ListItem, ListItemText, Typography, ListItemSecondaryAction, Switch, ListItemIcon, Collapse, IconButton, ListSubheader, Paper, Slider, Tooltip, Dialog, DialogTitle, DialogActions, Fade, Fab, Box, Link, Alert, AlertTitle, AppBar, Toolbar, Drawer, Menu, MenuItem, Select, InputLabel, FormControl, Divider, ListItemButton, Checkbox, FormGroup, FormControlLabel, Container, Badge, DialogContent, FormLabel, RadioGroup, Radio } from '@mui/material';
import { CheckCircleTwoTone, NotificationImportantTwoTone, WarningTwoTone, ExpandLess, ExpandMore, Delete, Add, ArrowBack, Lightbulb, LightbulbTwoTone, Timeline, CompareArrows, Check, Settings, FilterAlt, SwapHoriz, Close, OpenInFull } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import PercentileFanChart from '../visualization/PercentileFanChart';
import { createInvestmentAccount, createPortfolioRequest } from './MultiGoalRequestFactory';
import { getMappedFunds, callFinancialPlanning, getDefaultInvestmentGoalPlanningResult, InvestmentGoalPlanningResult, OutcomesAtHorizon } from '../api_client/tsClient';
import { useAppColors, useHeaderStyle } from '../theming/useAppColors';
import { AssetsAnddebtsItemGroupRequest, AssetsAndDebtsRequest, FinancialPlanningV3Request, Frequency, ValidationErrorModel } from '../api_client';
import { TextNumberFormat } from '../NumberFormatCustom'
// import PensionAdviceAppBar from './PensionAdviceAppBar';
// import { styleDelegate } from './useStyles';
import { SettingsContext } from '../settings/SettingsContext';
import { useDebounce } from '../hooks/useDebounce';
import SliderInput from './SliderInput';
import AppMenu from '../AppMenu';
import GoalChart from './GoalChart';
import { getAccounts, getAccountsWithSetFunction, GroupVM, InvestmentAccountVM, MortgageVM, Portfolio, ProductCodeVM, RealEstateVM, TransactionAccountVM, TransactionVM, WaterfallAllocationStrategyVM } from '../financialPlanning/Portfolio';
import AssetsAndDebtsView from '../financialPlanning/assetsAndDebts/AssetsAndDebtsView';
import { ResultOutput } from '../debugComponents/ResultOutput';
import { PortfolioContext } from '../settings/PortfolioContext';
import IncomeAndExpensesView from '../financialPlanning/assetsAndDebts/IncomeAndExpensesView';
import IncomeBarChart from '../visualization/IncomeBarChart';
import { RequestInput } from '../debugComponents/RequestInput';
import GroupResultsChart from '../visualization/GroupResultsChart';
import AppMenuTop from '../AppMenuTop';
import EfficientFrontierChart from '../visualization/EfficientFrontierChart';

export default function MultiGoalLifeEvents(props: any) {

  const theme = useTheme();
  const settings = useContext(SettingsContext);
  const headerStyle = useHeaderStyle();
  const portfolioContext = useContext(PortfolioContext);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(undefined as ValidationErrorModel);
  const [resultState, setResultState] = useState<{ forecast: InvestmentGoalPlanningResult | undefined, referenceForecast: InvestmentGoalPlanningResult | undefined }>({ forecast: getDefaultInvestmentGoalPlanningResult(), referenceForecast: getDefaultInvestmentGoalPlanningResult() });
  const portfolioDevelopmentTimeSeries = resultState?.forecast?.financialPlanningResponseModel.percentileForecast;
  const cashFlows = resultState?.forecast?.financialPlanningResponseModel.cashFlows;
  const referencePortfolioDevelopmentTimeSeries = resultState?.referenceForecast?.financialPlanningResponseModel.percentileForecast;
  const [groupChartDialogOpen, setGroupChartDialogOpen] = useState(false);
  const [percentileChartDialogOpen, setPercentileChartDialogOpen] = useState(false);
  const [cashFlowChartDialogOpen, setCashFlowChartDialogOpen] = useState(false);

  const activePortfolio = portfolioContext.portfolios[portfolioContext.activePortfolioIndex];
  const debouncedActivePortfolio = useDebounce(activePortfolio, 800);

  const setActivePortfolioOld = (updatedPortfolio: Portfolio) => {
    portfolioContext.setPortfolios(portfolioContext.portfolios.map((p, i) => i === portfolioContext.activePortfolioIndex ? updatedPortfolio : p));
  };

  const setActivePortfolio = (updatedPortfolio: Portfolio) => {
    portfolioContext.setPortfolios2((state) => state.map((p, i) => i === portfolioContext.activePortfolioIndex ? updatedPortfolio : p));
  };

  const setActivePortfolioWithState = (mutator: (portfolio: Portfolio) => void) => {
    portfolioContext.setPortfolios2((state) => state.map((p, i) => i === portfolioContext.activePortfolioIndex ? mutator(p) : p));
  };

  const setAssetsAndDebtsWithState = (mutator: (assetsAndDebts: AssetsAndDebtsRequest) => void) => {
    setActivePortfolioWithState((state) => ({ ...state, assetsAndDebts: mutator(state.assetsAndDebts) }));
  };

  const referencePortfolio = portfolioContext.referencePortfolioIndex === -1 ? undefined : portfolioContext.portfolios[portfolioContext.referencePortfolioIndex];

  const setAssetsAndDebts: (assetsAndDebts: AssetsAndDebtsRequest) => void = (assetsAndDebts: AssetsAndDebtsRequest) => setActivePortfolio({ ...activePortfolio, assetsAndDebts: assetsAndDebts });
  const setGroups: (groups: GroupVM[]) => void = (groups: GroupVM[]) => setActivePortfolio({ ...activePortfolio, assetsAndDebtsItemGroups: groups });
  const setTransactions = (transactions: TransactionVM[]) => setActivePortfolio({ ...activePortfolio, transactions: transactions });
  const setStrategies = (strategies: WaterfallAllocationStrategyVM[]) => setActivePortfolio({ ...activePortfolio, waterfallAllocationStrategies: strategies });

  const accounts = getAccountsWithSetFunction(activePortfolio?.assetsAndDebts, setAssetsAndDebts);
  const groupResults = resultState?.forecast?.financialPlanningResponseModel.groupResults?.map(r => {
    const group = activePortfolio?.assetsAndDebtsItemGroups?.find(g => g.id === r.groupId) as GroupVM;
    const account = accounts?.find(a => a.id === r.groupId);
    return { ...r, name: group ? group?.name : account?.name, color: group ? group?.color : account?.color }
  });

  const [probabilities, setProbabilities] = useState([0]);

  const [chartSettingsDialogOpen, setChartSettingsDialogOpen] = useState(false);

  const [jsonRequest, setJsonRequest] = React.useState(null as FinancialPlanningV3Request);
  const [developerOpen, setDeveloperOpen] = React.useState(false);

  const [goals, setGoals] = useState([]);

  const medianOutcome = resultState.forecast?.financialPlanningResponseModel?.percentileForecast?.find((p: any) => p.percentile === 0.5);
  const outcomesAtHorizon: OutcomesAtHorizon | undefined = resultState.forecast?.outcomesAtHorizon;
  const notificationStatus = goals.length === 0 ? 'nogoal' : medianOutcome === undefined ? 'ok' : Math.min(...medianOutcome.outcomes.map((o: any) => o.value)) < 0 ? 'notify' : 'ok';

  const [addGoalOpen, setAddGoalOpen] = useState(false);

  const handleAddGoalClickOpen = () => {
    setAddGoalOpen(true);
  };

  const handleAddGoalClose = () => {
    setAddGoalOpen(false);
  };

  const handleAddGoalDelete = (id: number) => {
    const handler = () => {
      const newGoals = goals.filter(g => g.id !== id).map(a => { return { ...a } });
      setGoals(newGoals);
    }
    return handler;
  };

  const handleEnable = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const newGoals = goals.map(a => { return { ...a } });
    newGoals.find(g => g.id === id).enabled = event.target.checked;
    setGoals(newGoals);
  };

  const [anchorCompareEl, setAnchorCompareEl] = React.useState<null | HTMLElement>(null);
  const compareOpen = Boolean(anchorCompareEl);
  const handleCompareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorCompareEl(event.currentTarget);
  };
  const handleCompareClose = (index: any) => {
    if (index !== undefined) {
      portfolioContext.setReferencePortfolioIndex(index);
    }
    setAnchorCompareEl(null);
  };

  // const debouncedMonthlyContributions = useDebounce(monthlyContribution, 800);
  // const debouncedContributionHorizon = useDebounce(contributionHorizon, 800);
  const debouncedGoals = useDebounce(goals, 800);

  useEffect(() => {
    if (settings.currentScenarioSet === null
      || settings.currentScenarioSet === undefined
      || settings.currentScenarioSet.id === ''
      || !debouncedActivePortfolio
      || getAccounts((debouncedActivePortfolio as Portfolio).assetsAndDebts).length === 0)
      return;
    const percentiles = settings.percentiles.filter(p => p.enabled).map(p => p.pecentile);
    // setNumberOfRequestInFlight(state => state + 1);
    const request = createPortfolioRequest(
      settings.currentScenarioSet.id,
      debouncedActivePortfolio,
      debouncedGoals,
      percentiles,
      settings.chartSettings.resultAsValueOfToday,
      settings.observationFrequency);
    setJsonRequest(request);
    callFinancialPlanning(settings.chartSettings.horizonInYears, request)
      .then(async result => {
        try {
          let referenceForecast = undefined as InvestmentGoalPlanningResult;
          if (referencePortfolio !== undefined) {
            const refrenceRequest = createPortfolioRequest(
              settings.currentScenarioSet.id,
              referencePortfolio,
              debouncedGoals,
              percentiles,
              settings.chartSettings.resultAsValueOfToday,
              settings.observationFrequency);
            referenceForecast = await callFinancialPlanning(settings.chartSettings.horizonInYears, refrenceRequest) as InvestmentGoalPlanningResult;
          }
          setResultState({ forecast: result as InvestmentGoalPlanningResult, referenceForecast: referenceForecast });
          setHasError(false);

          // const gapRequest = createPortfolioRequest(
          //   settings.currentScenarioSet.id,
          //   debouncedActivePortfolio,
          //   debouncedGoals,
          //   percentiles,
          //   settings.chartSettings.resultAsValueOfToday);

          // callGap(
          //   0,
          //   gapRequest,
          //   accounts[0]?.id) // todo
          //   .then(result => {
          //     try {
          //       setProbabilities(result.map(r => r.probabilityOfReachingTargetUsingExistingFunds));
          //     } finally {
          //     }
          //   });

        } finally {
        }
      }).catch(async e => {
        setHasError(true);
        if (e.json) {
          setError(await e.json());
        } else {
          setError({ message: 'Unknown error' } as ValidationErrorModel);
        }
        // setHasResult(false);
      });
  }, [settings.currentScenarioSet, debouncedActivePortfolio, referencePortfolio, debouncedGoals, settings.percentiles, settings.chartSettings, settings.observationFrequency])

  const updateGoal = (goal: any) => {
    const newGoals = goals.map(a => { return { ...a } });
    const goalIndex = newGoals.findIndex(g => g.id === goal.id);
    newGoals[goalIndex] = goal;
    setGoals(newGoals);
  }

  const renderGoals = goals.map(goal => {
    const probability = probabilities.length > 1 && goal.enabled ? `${Math.round(probabilities[goal.horizonInYears - 1] * 100)}%` : '-';
    return (<div key={goal.id}>
      <Grid container>
        <Grid item xs='auto' sm='auto'>
          <ListItemIcon style={{ paddingRight: theme.spacing(2) }}>
            <Switch color='primary' onChange={(event) => handleEnable(event, goal.id)} checked={goal.enabled} />
          </ListItemIcon>
        </Grid>
        <Grid item xs sm={4}>
          <Typography variant='body1'>{goal.name}</Typography>
          <Typography variant='body2' color={'text.secondary'}>{goal.horizonInYears + " years"}</Typography>
        </Grid>
        <Grid item xs={2} sm>
          <Typography variant='body1'>Amount</Typography>
          <Typography variant='body2' color={'text.secondary'}>{<TextNumberFormat decimals={0} value={goal.amount} />}</Typography>
        </Grid>
        <Grid item xs='auto' sm sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Box>
            <Typography variant='body1'>Probability</Typography>
            <Typography variant='body2' color={'text.secondary'}>{probability}</Typography>
          </Box>
        </Grid>
        <Grid item>
          <div style={{ paddingLeft: theme.spacing(1) }}>
            <IconButton onClick={() => { updateGoal({ ...goal, editorOpen: !goal.editorOpen }) }}>{goal.editorOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
            <IconButton edge="end" aria-label="delete" size="large" onClick={handleAddGoalDelete(goal.id)}>
              <Delete />
            </IconButton>
          </div>
        </Grid>
      </Grid>
      <Collapse in={goal.editorOpen} timeout="auto" unmountOnExit>
        <Grid container spacing={1} style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}>
          <Grid item xs={12}>
            <TextField
              variant='filled'
              style={{ marginBottom: theme.spacing(2) }}
              margin='none'
              size='small'
              fullWidth={true}
              required label={'Name'}
              onChange={(e) => { updateGoal({ ...goal, name: e.target.value }) }}
              value={goal.name} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SliderInput value={goal.horizonInYears} onChange={(e: any) => { updateGoal({ ...goal, horizonInYears: e }) }} minValue={1} maxValue={settings.chartSettings.horizonInYears} title={'Time frame (years)'} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SliderInput value={goal.amount} showCurrency onChange={(e: any) => { updateGoal({ ...goal, amount: e }) }} minValue={0} maxValue={1000000} title={'Amount'} />
          </Grid>
        </Grid>
      </Collapse>
    </div>)
  });

  return (
    <div>
      <AppMenuTop resetResults={() => setResultState({ forecast: getDefaultInvestmentGoalPlanningResult(), referenceForecast: getDefaultInvestmentGoalPlanningResult() })} />
      <Grid container direction="row" spacing={1} alignItems="stretch" justifyContent="flex-start" style={{ minHeight: '100vh' }}>
        {/* <Grid item xs='auto'>
          <Box style={{width:theme.spacing(4)}} sx={{ display: { xs: 'none', md: 'block' } }}>
          </Box>
        </Grid> */}
        <Grid item xs={12} sm={6} md={6} lg={6} xl={4} style={{ display: 'flex', flexDirection: 'column' }}>
          {/* <div style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}>
            <PortfolioSelect />
          </div> */}
          <div style={{ flex: 1, marginTop: theme.spacing(1) }}>
            <Grid container direction="row" alignItems="stretch" style={{ minHeight: '100%' }}>
              <Box
                component={Grid}
                item
                display={{ xs: "none", sm: 'block' }}
                style={{ width: '4px' }}></Box>
              <Grid item xs={12} sm>
                <Paper variant='elevation' elevation={0} sx={{ height: '100%' }} square>
                  <AssetsAndDebtsView
                    assetsAndDebts={activePortfolio?.assetsAndDebts}
                    setAssetsAndDebts={setAssetsAndDebts}
                    groups={activePortfolio?.assetsAndDebtsItemGroups}
                    setGroups={setGroups}
                    isLoaded={portfolioContext.isLoaded} />
                </Paper>
              </Grid>
              <Box
                component={Grid}
                item
                display={{ xs: "none", sm: 'block' }}
                style={{ width: '4px' }}></Box>
              <Grid item xs={12} sm>
                <Paper variant='elevation' elevation={0} sx={{ height: '100%' }} square>
                  <IncomeAndExpensesView
                    transactions={activePortfolio?.transactions}
                    setTransactions={setTransactions}
                    strategies={activePortfolio?.waterfallAllocationStrategies}
                    setStrategies={setStrategies}
                    accounts={accounts} />
                </Paper>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm>
          {/* <Grid container spacing={0} style={{ textAlign: 'left', paddingTop: theme.spacing(2) }} direction="row" alignItems='stretch' justifyContent='center'> */}
          {/* <Grid item xs={12} sm={12}> */}
          <Paper square variant='elevation' elevation={0} style={{ marginTop: theme.spacing(1) }}>
            <Grid container sx={headerStyle} style={{ maxHeight: theme.spacing(5) }}>
              <Grid item xs>
                <Grid container>
                  <Grid item xs='auto'>
                    <Typography variant={'body1'} style={{ paddingLeft: theme.spacing(1), paddingTop: theme.spacing(1) }}>Cash flow and scenario analysis </Typography>
                  </Grid>
                  <Grid item xs='auto'>
                    <Tooltip title={hasError ? (error as any)?.Errors?.map((e: any) => e.Message).join("\n") : undefined}>
                      {/* <Tooltip title={hasError ? JSON.stringify(error): undefined}> */}
                      <Badge color='warning' badgeContent={hasError ? '!' : undefined}>
                        <Typography sx={headerStyle} color={'text.secondary'} variant={'body1'} style={{ paddingLeft: theme.spacing(1), paddingTop: theme.spacing(1) }}>({settings.chartSettings.resultAsValueOfToday ? 'in today\'s value of money' : 'no growth adjustment'})</Typography>
                      </Badge>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs='auto'>
                <IconButton sx={headerStyle} onClick={handleCompareClick} style={{ marginLeft: theme.spacing(1) }}>
                  <CompareArrows />
                </IconButton>
                <Menu
                  id="compare-menu"
                  anchorEl={anchorCompareEl}
                  open={compareOpen}
                  onClose={() => handleCompareClose(undefined)}
                >
                  {portfolioContext.portfolios.map((p, i) => ({ p: p, i: i })).filter((p) => p.i !== portfolioContext.activePortfolioIndex).map((p) => p.i === portfolioContext.referencePortfolioIndex
                    ? (<MenuItem key={p.p.name} onClick={() => handleCompareClose(p.i)} value={p.i}><ListItemIcon><Check /></ListItemIcon>{p.p.name}</MenuItem>)
                    : (<MenuItem key={p.p.name} onClick={() => handleCompareClose(p.i)} value={p.i}><ListItemText inset>{p.p.name}</ListItemText></MenuItem>))}
                  <MenuItem key={'none'} onClick={() => handleCompareClose(-1)} value={-1}>{portfolioContext.referencePortfolioIndex === -1 ? (<ListItemIcon><Check /></ListItemIcon>) : (<></>)}<ListItemText inset={portfolioContext.referencePortfolioIndex !== -1}>No comparison</ListItemText></MenuItem>
                  <Divider />
                  <MenuItem
                    key={'swap'}
                    disabled={portfolioContext.referencePortfolioIndex === -1}
                    onClick={() => {
                      portfolioContext.setActivePortfolioIndex(portfolioContext.referencePortfolioIndex);
                      handleCompareClose(portfolioContext.activePortfolioIndex);
                    }}
                    value={-1}>
                    <ListItemIcon><SwapHoriz /></ListItemIcon>Swap active
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item xs='auto'>
                <IconButton sx={headerStyle} onClick={() => { setChartSettingsDialogOpen(true) }}><Settings /></IconButton>
                <ChartSettingsDialog open={chartSettingsDialogOpen} handleClose={() => { setChartSettingsDialogOpen(false) }} accounts={accounts} />
              </Grid>
            </Grid>
            <Divider style={{ marginTop: theme.spacing(0), marginBottom: theme.spacing(1) }}></Divider>
            {/* <Grid item xs={12} sm={12}> */}
            {/* fix for bug below:  height must be set and the divs must be there to make the responsive container work with recharts */}
            <div style={{ position: 'relative', height: theme.spacing(109) }}>
              <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, paddingLeft: theme.spacing(1) }}>
                <IncomeBarChart height={theme.spacing(34)} cashFlows={cashFlows} isAnnual={settings.observationFrequency === Frequency.Annual ? true : false} horizon={settings.chartSettings.horizonInYears} accountNames={accounts} setCashFlowChartDialogOpen={setCashFlowChartDialogOpen} observationFrequency={settings.observationFrequency}/>
                <Dialog open={cashFlowChartDialogOpen} fullScreen={true}>
                  <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Income and expenses
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={() => setCashFlowChartDialogOpen(false)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <Close />
                  </IconButton>
                  <DialogContent dividers>
                    <IncomeBarChart height={'87vh'} showXAxis={true} cashFlows={cashFlows} isAnnual={settings.observationFrequency === Frequency.Annual ? true : false} horizon={settings.chartSettings.horizonInYears} accountNames={accounts} startDate={activePortfolio?.startDate} observationFrequency={settings.observationFrequency}/>
                    {/* <GroupResultsChart showLedgend={true} showXAxis={true} height={'87vh'} groupResults={groupResults} horizon={settings.chartSettings.horizonInYears} startDate={activePortfolio?.startDate} /> */}
                  </DialogContent>
                </Dialog>
                <GroupResultsChart height={theme.spacing(34)} groupResults={groupResults} horizon={settings.chartSettings.horizonInYears} setGroupChartDialogOpen={setGroupChartDialogOpen} observationFrequency={settings.observationFrequency}/>
                <Dialog open={groupChartDialogOpen} fullScreen={true}>
                  <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Assets and debts over time
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={() => setGroupChartDialogOpen(false)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <Close />
                  </IconButton>
                  <DialogContent dividers>
                    <GroupResultsChart showLedgend={true} showXAxis={true} height={'87vh'} groupResults={groupResults} horizon={settings.chartSettings.horizonInYears} startDate={activePortfolio?.startDate} observationFrequency={settings.observationFrequency}/>
                  </DialogContent>
                </Dialog>
                <PercentileFanChart showToolbar={true} targetAmount={undefined} hideHorizon={true} currentAge={0} height={theme.spacing(34)} showXAxis={true} setGoals={setGoals} goals={goals} horizon={settings.chartSettings.horizonInYears} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} referencePortfolioDevelopmentTimeSeries={referencePortfolioDevelopmentTimeSeries} startDate={activePortfolio?.startDate} setPercentileChartDialogOpen={setPercentileChartDialogOpen} observationFrequency={settings.observationFrequency} />
                <Dialog open={percentileChartDialogOpen} fullScreen={true}>
                  <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Assets and debts percentile outcomes
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={() => setPercentileChartDialogOpen(false)}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <Close />
                  </IconButton>
                  <DialogContent dividers>
                    <PercentileFanChart showToolbar={true} targetAmount={undefined} hideHorizon={true} currentAge={0} showLedgend={true} showXAxis={true} setGoals={setGoals} goals={goals} height={'87vh'} horizon={settings.chartSettings.horizonInYears} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} referencePortfolioDevelopmentTimeSeries={referencePortfolioDevelopmentTimeSeries} startDate={activePortfolio?.startDate} observationFrequency={settings.observationFrequency}/>
                    {/* <GoalChart showLedgend={true} showXAxis={true} height={'87vh'} groupResults={groupResults} horizon={settings.chartSettings.horizonInYears} startDate={activePortfolio?.startDate} /> */}
                  </DialogContent>
                </Dialog>
              </div>
            </div>
            {/* </Grid> */}
            {/*                   
                  <Grid item xs={12}>
                    <Alert style={theme.palette.mode === 'dark' ? { margin: theme.spacing(1), background: theme.palette.success.main + '22', } : { margin: theme.spacing(1) }} variant={'standard'} severity="success"

                      action={
                        <>
                          <Button color="inherit" size="small">
                            UNDO
                          </Button>
                          <Button color="inherit" size="small">
                            UNDO
                          </Button>
                        </>
                      }
                    ><AlertTitle color={'text.primary'}>On track!</AlertTitle>
                      This is a success alert — check it out!</Alert>
                  </Grid> */}
            {/* <Grid container>
              <Grid item xs>
                <Grid container style={{ padding: theme.spacing(2) }}>
                  <Grid item xs={12}>
                    <Typography variant={'body1'} color={'text.secondary'} style={{ marginLeft: theme.spacing(1), marginBottom: theme.spacing(1) }}>Investment goals</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {renderGoals}
                  </Grid>
                </Grid>
                <Grid container style={{ marginBottom: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                  <Grid item xs></Grid>
                  <Grid item xs='auto'>
                    <Button onClick={handleAddGoalClickOpen}>
                      <Add />Add Goal
                    </Button>
                    <AddGoalDialog title='Add goal' open={addGoalOpen} onClose={handleAddGoalClose} goals={goals} setGoals={setGoals} maxHorizon={settings.chartSettings.horizonInYears} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} style={{ padding: theme.spacing(2) }}>
                <TrackingItem
                  style={{ height: theme.spacing(11) }}
                  notificationStatus={notificationStatus} />
                <div>
                  <div><Typography variant='body2' color='text.secondary'>Realistic outcome in {settings.chartSettings.horizonInYears} years</Typography><Typography variant='h4'><TextNumberFormat decimals={0} value={outcomesAtHorizon.median} /></Typography></div>
                  <div><Typography variant='body2' color='text.secondary'>Optimistic outcome</Typography><Typography variant='subtitle1'><TextNumberFormat decimals={0} value={outcomesAtHorizon.good} /></Typography></div>
                  <div><Typography variant='body2' color='text.secondary'>Pessimistic outcome</Typography><TextNumberFormat decimals={0} value={outcomesAtHorizon.bad} /></div>
                </div>
              </Grid>
            </Grid> */}

          </Paper>
          <div>
            <div style={{ marginTop: 'auto', marginLeft: 'auto' }}>
              <Button color="primary" href='/developer'>Developer</Button>
              <IconButton onClick={() => { setDeveloperOpen(!developerOpen) }}>{developerOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
            </div>
            <Collapse in={developerOpen} timeout="auto" unmountOnExit>
              <RequestInput setJsonRequest={() => { }} jsonRequest={JSON.stringify(jsonRequest, null, 2)} token='' setToken={() => { }} baseUri setBaseUri={() => { }} />
              <ResultOutput text={hasError ? error : resultState.forecast} />
            </Collapse>
          </div>
        </Grid>
        <Box
          component={Grid}
          item
          display={{ xs: "none", sm: 'block' }}
          style={{ width: '4px' }}></Box>
        {/* <Snackbar style={{ whiteSpace: 'pre-wrap' }} open={errorMessage !== undefined} autoHideDuration={4000} onClose={() => setErrorMessage(undefined)} message={errorMessage}></Snackbar> */}
      </Grid >
    </div >
  );
}

function ChartSettingsDialog(props: { open: boolean, handleClose: () => void, accounts: { name: string, id: string, enabled: boolean, setEnabled: (enabled: boolean) => void }[] }) {

  const settings = useContext(SettingsContext);
  const theme = useTheme();
  const zoomMarks = [
    {
      value: 1,
      label: '1yr',
    },
    {
      value: 10,
      label: '10yrs',
    },
    {
      value: 20,
      label: '20yrs',
    },
    {
      value: 30,
      label: '30yrs',
    },
    {
      value: 40,
      label: '40yrs',
    }
  ];

  return (<Dialog fullWidth={true} maxWidth={'xs'} onClose={props.handleClose} aria-labelledby="simulation-dialog-title" open={props.open}>
    <DialogTitle id="simulation-dialog-title">Result settings</DialogTitle>
    <Divider></Divider>
    <div style={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3), paddingTop: theme.spacing(1) }}>

      <FormGroup>

        <FormControlLabel
          control={<Checkbox checked={settings.chartSettings.resultAsValueOfToday} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            settings.setChartSettings({ ...settings.chartSettings, resultAsValueOfToday: event.target.checked });
          }} />} label="Results as today's value of money" />
      </FormGroup>
      <Typography variant={'body1'} style={{ paddingTop: theme.spacing(1) }}>Horizon</Typography>
      <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
        <Slider
          min={1}
          max={40}
          step={1}
          value={settings.chartSettings.horizonInYears}
          onChange={(e, v) => settings.setChartSettings({ ...settings.chartSettings, horizonInYears: v })}
          marks={zoomMarks}
          aria-labelledby="risklevel"
        />
      </div>
      <Typography variant={'body1'} style={{ paddingTop: theme.spacing(1) }}>Observation frequency</Typography>
      <FormGroup>
        <FormControl>
          {/* <FormLabel id="demo-radio-buttons-group-label">Observation frequency</FormLabel> */}
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={settings.observationFrequency === Frequency.Annual ? "year" : "month"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              settings.setObservationFrequency((event.target as HTMLInputElement).value === "year" ? Frequency.Annual : Frequency.Monthly);
            }}
          >
            <FormControlLabel value="year" control={<Radio />} label="Year" />
            <FormControlLabel value="month" control={<Radio />} label="Month" />
          </RadioGroup>
        </FormControl>
        {/* <FormControlLabel
          control={<Checkbox checked={settings.observationFrequency} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            settings.setObservationFrequency(event.target.checked);
          }} />} label="Results as today's value of money" /> */}
      </FormGroup>
      <div style={{ paddingBottom: theme.spacing(2), paddingTop: theme.spacing(1) }}>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }} subheader={<ListSubheader>Active items</ListSubheader>}>
          {props.accounts?.map((value, i) => {
            const labelId = `checkbox-list-label-${i}`;
            return (
              <ListItem
                key={i}
                disablePadding
              >
                <ListItemButton
                  role={undefined}
                  // disabled={settings.percentiles.filter(p => p.enabled).length < 2 && value.enabled}
                  onClick={() => { value.setEnabled(!value.enabled) }}
                  dense>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={value.enabled}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
      <Divider />
      <Typography variant={'body1'} style={{ paddingBottom: theme.spacing(1), paddingTop: theme.spacing(1) }}>Fan chart</Typography>
      <FormGroup>
        <FormControlLabel control={<Checkbox checked={settings.chartSettings.fanFilled} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          settings.setChartSettings({ ...settings.chartSettings, fanFilled: event.target.checked });
        }} />} label="Filled" />
      </FormGroup>
    </div>
    <div style={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3), paddingBottom: theme.spacing(2), paddingTop: theme.spacing(1) }}>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }} subheader={<ListSubheader>Percentiles</ListSubheader>}>
        {settings.percentiles.map((value, i) => {
          const labelId = `checkbox-list-label-${i}`;

          return (
            <ListItem
              key={i}
              // secondaryAction={
              //   <IconButton edge="end" aria-label="comments">
              //     <Delete />
              //   </IconButton>
              // }
              disablePadding
            >
              <ListItemButton role={undefined} disabled={settings.percentiles.filter(p => p.enabled).length < 2 && value.enabled} onClick={() => { settings.setPercentiles(settings.percentiles.map((pp, ii) => ii === i ? ({ ...pp, enabled: !pp.enabled }) : pp)) }} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={value.enabled}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={(value.pecentile * 100) + ' %'} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </div>

    <DialogActions>
      {/* <Button onClick={props.handleClose} color="primary">
        Cancel
      </Button> */}
      <Button onClick={props.handleClose} color="primary" variant="contained">
        Ok
      </Button>
    </DialogActions>
  </Dialog>
  );
}

function AddGoalDialog(props: any) {
  const theme = useTheme();

  const { onClose, open, title, goals, setGoals, maxHorizon } = props;

  const [horizonInYears, setHorizonInYears] = useState(10);
  const [name, setName] = useState('');
  const [targetAmount, setTargetAmount] = useState(10000);
  const [monthlyInvestmentAmount, setMonthlyInvestmentAmount] = useState(100);

  const resetState = () => {
    setHorizonInYears(10);
    setName('');
    setTargetAmount(10000);
    setMonthlyInvestmentAmount(100);
  };

  const onAdd = () => {

    const newGoals = goals.map((a: any) => { return { ...a } }).concat([{
      id: goals.length === 0 ? 1 : Math.max(...(goals.map((g: any) => g.id))) + 1,
      name: name,
      type: 'OneTime',
      horizonInYears: horizonInYears,
      durationInYears: 0,
      amount: targetAmount,
      chosenMonthlyContribution: monthlyInvestmentAmount,
      enabled: true,
      editorOpen: false
    }]);
    setGoals(newGoals);
    handleClose();
    resetState();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <Grid style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }} container spacing={2}>
        <Grid item xs={12} >
          <TextField
            variant='filled'
            style={{ marginBottom: theme.spacing(2) }}
            margin='none'
            size='small'
            fullWidth={true}
            required label={'Name'}
            onChange={(e) => { setName(e.target.value) }}
            value={name} />
          <SliderInput value={horizonInYears} onChange={setHorizonInYears} minValue={1} maxValue={maxHorizon} title={'Time frame (years)'} />
          <SliderInput value={targetAmount} showCurrency onChange={setTargetAmount} minValue={0} maxValue={1000000} title={'Amount'} />
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onAdd} disabled={name === ''} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// const RequiredInput = (props: any) => {
//   const theme = useTheme();

//   const { requiredEnabled, title, requiredContribution, contribution, setContribution, maxValue, helperText } = props;
//   return (<div>
//     <div style={{ float: 'left', width: '55%' }}>
//       <SliderInput value={contribution} showCurrency onChange={setContribution} minValue={0} maxValue={maxValue} title={title} helperText={helperText} />
//     </div>
//     <div style={{ float: 'right', width: '40%', marginBottom: theme.spacing(4) }}>
//       <Typography style={{ textAlign: 'left' }} variant='body2' color='text.secondary'>Required Amount</Typography>
//       <Button onClick={() => { setContribution(requiredContribution); }} size='small' style={{ minWidth: theme.spacing(14), textAlign: 'left', justifyContent: 'left' }} variant={requiredEnabled ? "contained" : "outlined"} color='primary' disabled={!requiredEnabled}>
//         <ArrowBack fontSize="small" style={{ visibility: requiredEnabled ? 'visible' : 'hidden', marginRight: theme.spacing(1), marginBottom: 1 }} />
//         {requiredEnabled ? <TextNumberFormat roundToNearest={1} value={requiredContribution} /> : ''}
//       </Button>
//     </div>
//   </div>);
// };

function TrackingItem({ notificationStatus, style }: any) {
  const theme = useTheme();
  const colors = useAppColors();

  const iconType = notificationStatus;
  const bigText = notificationStatus === 'ok' ? 'On track' : notificationStatus === 'nogoal' ? 'Add goals.'
    : 'Caution';
  const smallText = notificationStatus === 'ok' ? 'Your current plan is expected to fund your goals.' :
    notificationStatus === 'nogoal' ? 'Help us guide you by adding investment goals.'
      : 'An increase in monthly contribution is needed to fund your goals.';

  const icon = iconType === 'ok' ? <CheckCircleTwoTone style={{
    fill: colors.status.ok,
    display: "inline-block",
    marginBottom: "-4px",
    marginRight: theme.spacing(1)
  }} fontSize='inherit' /> : iconType === 'notify' ? <NotificationImportantTwoTone style={{
    fill: colors.status.notify,
    display: "inline-block",
    marginBottom: "-4px",
    marginRight: theme.spacing(1)
  }} fontSize='inherit' /> : iconType === 'nogoal' ? <LightbulbTwoTone style={{
    fill: colors.status.notify,
    display: "inline-block",
    marginBottom: "-4px",
    marginRight: theme.spacing(1)
  }} fontSize='inherit' /> :
    <WarningTwoTone color='error' style={{
      display: "inline-block",
      marginBottom: "-4px",
      marginRight: theme.spacing(1)
    }} fontSize='inherit' />;

  return (<div style={style}>
    <Typography variant='h4' style={{
      marginRight: 2
    }}>
      {icon}
      {bigText}
    </Typography>
    <Typography variant='body2'>{smallText}</Typography>
  </div>);

}
