import Dexie from 'dexie';

const localDB: any = new Dexie("outlisticV3");
let isInitializing = false;
let isInitialized = false;

export async function initDB(storeConfigurations: Store[], setIsInitialized: React.Dispatch<React.SetStateAction<boolean>>) {
    if (!isInitialized && !isInitializing) {
        isInitializing = true;
        const stores: any = {};
        storeConfigurations.forEach(conf => stores[conf.storeName] = 'id');
        localDB.version(1).stores(stores);
        localDB.open();

        for (var conf of storeConfigurations) {
            const dbItems = await localDB[conf.storeName].toArray();
            if (dbItems.length === 0) {
                const storedState = noFunctions(conf.initialState);
                await localDB[conf.storeName].add({ id: 1, state: storedState });
            }
            else {
                const loadedState = noFunctions(dbItems[0].state);
                conf.setState((state: any) => {
                    const resultingState = { ...state, ...loadedState };
                    return (resultingState);
                });
            }
        }

        isInitializing = false;
        isInitialized = true;
        setIsInitialized(true);
    } 
}

export function deleteDatabase() {
    localDB.delete();
    isInitialized = false;
    isInitializing = false;
}


function noFunctions(value: object): object {
    return Object.fromEntries(Object.entries(value).filter(([key, value]) => typeof value === "string" || typeof value === "number" || typeof value === "boolean" || Array.isArray(value)))
}

export function updateStore(storeName: string, value: object) {
    if (!isInitialized) return;
    localDB[storeName].update(1, { state: noFunctions(value) });
}

export interface Store {
    storeName: string;
    initialState: object;
    setState: (state: any) => {};
}