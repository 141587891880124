import { ArrowDropDown, ArrowDropUp, Delete, Done, Edit } from "@mui/icons-material";
import { Badge, Button, ButtonGroup, Collapse, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { InflationAdjustmentContract } from "../../api_client";
import { AccountType, DisplayState, WaterfallAllocationStrategyVM } from "../Portfolio";
import { CurrencyFormatCustom } from "../../NumberFormatCustom";

export function WaterfallStrategyView(props: {
    strategy: WaterfallAllocationStrategyVM;
    setStrategy: (newVal: WaterfallAllocationStrategyVM) => void;
    onDelete: () => void;
    accounts: { name: string; id: string; type: AccountType; }[];
}) {
    const { strategy, setStrategy, accounts } = props;
    const [selectedAccount, setSelectedAccount] = useState('' as string);
    const theme = useTheme();

    const accountsNotInStrategy = accounts.filter(a => !strategy.tiers?.map(t => t.assetsAndDebtsItemId).includes(a.id)).map(a => a.id);
    const errorText = '';

    const lowestTier = strategy.tiers === undefined || strategy.tiers.length === 0 ? 0 : Math.max(...strategy.tiers?.map(t => t.priority));

    const acountIds = accounts.map(a => a.id);
    const tiers = strategy.tiers?.sort((a, b) => a.priority < b.priority ? -1 : 1).filter(t => acountIds.includes(t.assetsAndDebtsItemId));

    return (
        <div>
            <Collapse in={strategy.displayState === DisplayState.Edit || strategy.displayState === undefined} timeout='auto' unmountOnExit>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <TextField
                            variant='filled'
                            style={{ marginBottom: theme.spacing(1) }}
                            margin='none'
                            size='small'
                            fullWidth={true}
                            required label={'Name'}
                            onChange={(e) => {
                                setStrategy({ ...strategy, name: e.target.value });
                            }}
                            value={strategy.name} />
                    </Grid>
                    <Grid item xs='auto'>
                        {props.onDelete ? (<IconButton style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={() => setStrategy({ ...strategy, displayState: DisplayState.Display })}>
                            <Done />
                        </IconButton>) : (<></>)}
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs>
                        <FormControl fullWidth={true} size="small">
                            <InputLabel required id="source-select">Account</InputLabel>
                            <Select label="Source account"
                                labelId="source-select"
                                required
                                fullWidth={true}
                                value={selectedAccount}
                                onChange={e => {
                                    setSelectedAccount(e.target.value);
                                }}>
                                {accounts.map(a => (<MenuItem disabled={!accountsNotInStrategy.includes(a.id)} key={a.id} value={a.id}>{a.name}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs='auto'>
                        <Button
                            disabled={selectedAccount === '' || selectedAccount === undefined}
                            onClick={() => {
                                const currentSelectedAccount = selectedAccount;
                                setSelectedAccount(accountsNotInStrategy.length === 1 ? '' : accountsNotInStrategy.filter(a => a !== selectedAccount)?.[0]);
                                setStrategy({
                                    ...strategy, tiers: [...tiers, {
                                        assetsAndDebtsItemId: currentSelectedAccount,
                                        targetValue: 0,
                                        priority: tiers === undefined || tiers.length === 0 ? 1 : Math.max(...tiers.map(t => t.priority)) + 1,
                                        inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate
                                    }]
                                });
                            }}>Add</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                    {tiers?.map((tier, i) => (<>
                        <Grid item xs>
                            <Typography variant="body2" style={{ paddingLeft: theme.spacing(0), paddingTop: theme.spacing(1) }}>{accounts.find(a => a.id === tier.assetsAndDebtsItemId).name}</Typography>
                        </Grid>
                        <Grid item xs='auto'>
                            <IconButton style={{ color: theme.palette.text.secondary }} size="small" onClick={(() => setStrategy({ ...strategy, tiers: tiers.filter((t, ii) => ii !== i) }))}>
                                <Delete fontSize="small" />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item xs='auto' style={{ marginTop: theme.spacing(-1) }}>
                            <ButtonGroup
                                orientation="vertical"
                                aria-label="vertical outlined button group"
                            >
                                <Button disabled={tier.priority === 1} style={{ padding: 0 }} size="small"
                                    onClick={(() => setStrategy({
                                        ...strategy, tiers: tiers.map((t, ii) => {
                                            return { ...t, priority: t.priority === tier.priority - 1 ? t.priority + 1 : t.priority === tier.priority ? t.priority - 1 : t.priority };
                                        })
                                    }))}>
                                    <ArrowDropUp /></Button>
                                <Button disabled={lowestTier === tier.priority} style={{ padding: 0 }} size="small"
                                    onClick={(() => setStrategy({
                                        ...strategy, tiers: tiers.map((t, ii) => {
                                            return { ...t, priority: t.priority === tier.priority + 1 ? t.priority - 1 : t.priority === tier.priority ? t.priority + 1 : t.priority };
                                        })
                                    }))}><ArrowDropDown /></Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ inputComponent: CurrencyFormatCustom }}
                                // variant='filled'
                                margin='none'
                                size="small"
                                fullWidth={true}
                                label={'Amount'}
                                onChange={(e) => {
                                    setStrategy({ ...strategy, tiers: tiers.map(t => t.assetsAndDebtsItemId === tier.assetsAndDebtsItemId ? { ...tier, targetValue: Number(e.target.value) } : t) });
                                }}
                                value={tier.targetValue} />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="inflationAdjustment-select">Inflation</InputLabel>
                                <Select label="Inflation"
                                    labelId="inflationAdjustment-select"
                                    value={tier.inflationAdjustment} onChange={e => {
                                        setStrategy({ ...strategy, tiers: tiers.map(t => t.assetsAndDebtsItemId === tier.assetsAndDebtsItemId ? { ...tier, inflationAdjustment: e.target.value as InflationAdjustmentContract } : t) });
                                    }}>
                                    <MenuItem value={InflationAdjustmentContract.NoAdjustment}>No adjustment</MenuItem>
                                    <MenuItem value={InflationAdjustmentContract.ValueAsOfStartDate}>In today's value</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                    </>))}

                </Grid>
            </Collapse>
            <Collapse in={strategy.displayState === DisplayState.Display} timeout="auto" unmountOnExit>
                <Grid container>
                    <Grid item xs style={{ paddingTop: 6 }}>
                        <Typography variant="body2">{strategy.name}</Typography>
                    </Grid>
                    {/* <Grid item xs='auto' style={{ paddingTop: 6, width: theme.spacing(10) }}>
                        <Typography variant="body2">{!transaction.isPercentage ? <TextNumberFormat value={transaction.amount} /> : <>{transaction.amount * 100}%</>}</Typography>
                    </Grid> */}
                    <Grid item xs='auto'>
                        <Tooltip title={errorText}>
                            <Badge color='warning' badgeContent={errorText === '' ? undefined : '!'}>
                                <IconButton style={{ color: theme.palette.text.secondary }} size="small" onClick={() => setStrategy({ ...strategy, displayState: DisplayState.Edit })}>
                                    <Edit fontSize="small" />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                        {/* <IconButton style={{ color: theme.palette.text.secondary }} size="small" onClick={() => setTransaction({ ...transaction, displayState: DisplayState.Edit })}>
                            <Edit fontSize="small" />
                        </IconButton> */}
                    </Grid>
                    <Grid item xs='auto'>
                        <IconButton style={{ color: theme.palette.text.secondary }} size="small" onClick={props.onDelete}>
                            <Delete fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Collapse>
        </div>
    );
}
