import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Slider, TextField, } from '@mui/material';
import { NumberFormatCustom, CurrencyFormatCustom } from '../NumberFormatCustom';


export default function SliderInput(props) {
  const theme = useTheme();
  const { value, onChange, minValue, maxValue, title, showCurrency, size, helperText, scale, step } = props;
  const actualSize = size === undefined ? 'small' : size;
  return (<>
    <div>
      <TextField InputLabelProps={{ shrink: true }} InputProps={{ inputComponent: showCurrency ? CurrencyFormatCustom : NumberFormatCustom }} helperText={helperText} fullWidth={true} label={title} size={actualSize} onChange={(e) => { onChange(Number(e.target.value)); }} value={value === null ? '' : value} />
    </div>
    <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
      <Slider
        step={step}
        size={'small'}
        min={minValue}
        max={maxValue}
        value={value}
        scale={scale}
        onChange={(e, v) => { onChange(Number(v)); }} />
    </div>
  </>);
}
