import { ConstructionOutlined } from "@mui/icons-material";
import moment, { Moment } from "moment";
import { AssetsAndDebtsRequest, CashFlowObservationsRequest, DividendType, Domicile, FinancialPlanningV3Request, Frequency, InflationAdjustmentContract, InvestmentAccountRequest, InvestmentAccountTaxationType, InvestmentStrategyType, ProductCodeType, ProductCodeContract, RecurringMoneyTransaction, OneTimeMoneyTransaction, OneTimeUnitTransaction, RecurringUnitTransaction, TransactionUnitRequest, CurrencyRequest, OccupationalPayoutSchemeType } from "../api_client/api";
import { OccupationalPensionAccountView } from "../financialPlanning/assetsAndDebts/OccupationalPensionAccountView";
import { getAccounts, InvestmentAccountVM, MortgageVM, NationalPensionVM, OccupationalPensionAccountVM, OccupationalPensionAgreementType, PayoutSchemeVM, Portfolio, ProductCodeVM, RealEstateVM, TransactionAccountVM, TransactionCategory, TransactionType, TransactionVM } from "../financialPlanning/Portfolio";
import { percentiles } from "../theming/appConstants";


export function createPortfolioRequest(
    scenarioSet: string,
    portfolio: Portfolio,
    goals: [{
        id: number,
        name: string,
        type: string,
        horizonInYears: number,
        durationInYears: number,
        amount: number,
        chosenMonthlyContribution: number,
        enabled: boolean
    }],
    chartPercentiles: number[],
    resultAsValueOfToday: boolean = false,
    observationFrequency: Frequency = Frequency.Annual): FinancialPlanningV3Request {

    const startDate = portfolio.startDate !== undefined ? moment(portfolio.startDate) : moment("2023-01-01T00:00:00.000Z");

    const numObservations = observationFrequency === Frequency.Annual ?  41 : 41 * 12;

    const observationTimeSteps = [...Array(numObservations).keys()].map(index => observationFrequency === Frequency.Annual ?  index * 12 : index);

    const accounts = getAccounts(portfolio?.assetsAndDebts).filter(a => a.enabled);
    const taxPaymentAccountId = portfolio.taxPaymentsAccountId === '' || portfolio.taxPaymentsAccountId === undefined || accounts.find(a => a.id === portfolio.taxPaymentsAccountId) === undefined ? accounts[0]?.id : portfolio.taxPaymentsAccountId;

    const assetsAndDebts: AssetsAndDebtsRequest = {
        ...portfolio?.assetsAndDebts,
        investmentAccounts: portfolio?.assetsAndDebts.investmentAccounts.filter(a => (a as InvestmentAccountVM).enabled === undefined || (a as InvestmentAccountVM).enabled),
        transactionAccounts: portfolio?.assetsAndDebts.transactionAccounts.filter(a => (a as TransactionAccountVM).enabled === undefined || (a as TransactionAccountVM).enabled),
        realEstate: portfolio?.assetsAndDebts.realEstate.filter(a => (a as RealEstateVM).enabled === undefined || (a as RealEstateVM).enabled),
        mortgages: portfolio?.assetsAndDebts.mortgages.filter(a => (a as MortgageVM).enabled === undefined || (a as MortgageVM).enabled).map(a => ({ ...a, interestRatePaymentAccountId: taxPaymentAccountId })),
        pensionAccounts: {
            sweOccupationalPensionAccounts: portfolio?.assetsAndDebts.pensionAccounts?.
                sweOccupationalPensionAccounts?.filter(a => (a as OccupationalPensionAccountVM).enabled === undefined || (a as OccupationalPensionAccountVM).enabled)
                .map(a => ({
                    ...a, payoutScheme: {
                        numberOfMonthsUntilPayout: a.payoutScheme.numberOfMonthsUntilPayout, schemeType: a.payoutScheme.schemeType,
                        fixedPeriodInMonths: a.payoutScheme.schemeType === OccupationalPayoutSchemeType.LifeLong ? undefined : a.payoutScheme.fixedPeriodInMonths
                    }
                })),
            sweNationalPension: portfolio?.assetsAndDebts.pensionAccounts?.sweNationalPension?.incomePensionAccount !== undefined && ((portfolio?.assetsAndDebts.pensionAccounts?.sweNationalPension as NationalPensionVM).enabled === undefined || (portfolio?.assetsAndDebts.pensionAccounts?.sweNationalPension as NationalPensionVM).enabled)
                ? {
                    ...portfolio?.assetsAndDebts.pensionAccounts?.sweNationalPension,
                    payoutScheme: portfolio?.assetsAndDebts.pensionAccounts?.sweNationalPension.payoutScheme.map(s => ({ retirementRate: s.retirementRate, timeStep: moment(portfolio.dateOfBirth).add((s as PayoutSchemeVM).ageOfRetirement, 'years').diff(moment(portfolio.startDate), 'months') }))
                }
                : undefined
        }
    };

    const goalWithdrawals = goals.filter(g => g.enabled === true).map(g => (
        {
            value: g.amount,
            timeStep: g.horizonInYears * 12,
            sourceId: taxPaymentAccountId, //TODO
            unit: TransactionUnitRequest.MonetaryAmount,
        } as OneTimeUnitTransaction
    ));

    const accountIds = accounts.map(a => a.id);
    const transactions = portfolio.transactions.filter(t => accountIds.includes(t.sourceId) || accountIds.includes(t.targetId));

    const earned = transactions
        .filter(t => t.category === TransactionCategory.EarnedIncome);
    const occupationalDeposits = earned
        .filter(t => t.occupationalPensionAgreement.type !== OccupationalPensionAgreementType.NoAgreement)
        .map(t => {
            const deposit = t.occupationalPensionAgreement.type === OccupationalPensionAgreementType.ITP1 ? calculateItp1() : t.amount * t.occupationalPensionAgreement.percentage;
            const newTransaction = JSON.parse(JSON.stringify(t)) as TransactionVM;
            newTransaction.category = TransactionCategory.TaxExemptIncome;
            newTransaction.amount = deposit;
            newTransaction.targetId = t.occupationalPensionAgreement.accountId;
            return newTransaction;

            function calculateItp1() {
                const incomeThreshold = t.frequency === Frequency.Monthly && t.type !== TransactionType.OneTime ? 46438 : 46438 * 12;
                const amountAbove = Math.max(t.amount - incomeThreshold, 0);
                const amountBelow = t.amount - amountAbove;
                return amountAbove * 0.3 + amountBelow * 0.05;
            }
        });

    const taxExempt = transactions
        .filter(t => t.category === TransactionCategory.Expense || t.category === TransactionCategory.TaxExemptIncome || t.category === TransactionCategory.IntraAccountTransaction)
        .concat(occupationalDeposits);

    const dividendsK10 = transactions
        .filter(t => t.category === TransactionCategory.DividendsK10);

    const dividendsK12 = transactions
        .filter(t => t.category === TransactionCategory.DividendsK12);

    const dateOfBirth = new Date(portfolio.dateOfBirth);

    const accountsInGroups = portfolio.assetsAndDebtsItemGroups ? portfolio.assetsAndDebtsItemGroups.map(g => g.assetsAndDebtsItemIds).reduce((a, b) => a.concat(b), []) : [];
    const accountsNotInGroups = accounts.map(a => a.id).filter(a => !accountsInGroups.includes(a));

    const groups = (
        portfolio.assetsAndDebtsItemGroups
            ? portfolio.assetsAndDebtsItemGroups.map(a => ({ id: a.id, assetsAndDebtsItemIds: a.assetsAndDebtsItemIds }))
            : []
    )
        .concat(accountsNotInGroups.map(a => ({ id: a, assetsAndDebtsItemIds: [a] })));


    const financialPlanningRequest: FinancialPlanningV3Request = {
        startDate: { year: startDate.year(), month: startDate.month() + 1 },
        simulationSettings: {
            scenarioSetId: scenarioSet,
            assetsAndDebtsItemGroups: groups,
            // assetsAndDebtsItemGroups: accounts.map(a => ({ id: a.id, assetsAndDebtsItemIds: [a.id] })),
            percentiles: chartPercentiles ? chartPercentiles : percentiles,
            cashFlowObservations: observationFrequency === Frequency.Annual ? CashFlowObservationsRequest.Annual : CashFlowObservationsRequest.Monthly,
            inflationAdjustment: resultAsValueOfToday ? InflationAdjustmentContract.ValueAsOfStartDate : InflationAdjustmentContract.NoAdjustment,
            observationTimeSteps: observationTimeSteps
        },
        assetsAndDebts: assetsAndDebts,
        cashFlows: {
            earnedIncome: {
                recurringTransactions: earned.filter(t => t.type === TransactionType.Recurring).map(t => toRecurring(t, accountIds, startDate)),
                oneTimeTransactions: earned.filter(t => t.type === TransactionType.OneTime).map(t => toOneTime(t, accountIds, startDate)),
                transactionArrays: []
            },
            other:
            {
                recurringTransactions: taxExempt.filter(t => t.type === TransactionType.Recurring).map(t => toRecurringUnit(t, accountIds, startDate)),
                oneTimeTransactions: taxExempt.filter(t => t.type === TransactionType.OneTime).map(t => toOneTimeUnit(t, accountIds, startDate)).concat(goalWithdrawals),
                transactionArrays: [],
            },
            dividends: [{
                type: DividendType.SweDividendsK10,
                recurringTransactions: dividendsK10.filter(t => t.type === TransactionType.Recurring).map(t => toRecurringUnit(t, accountIds, startDate)),
                oneTimeTransactions: dividendsK10.filter(t => t.type === TransactionType.OneTime).map(t => toOneTimeUnit(t, accountIds, startDate)),
                transactionArrays: [],
            },
            {
                type: DividendType.SweDividendsK12,
                recurringTransactions: dividendsK12.filter(t => t.type === TransactionType.Recurring).map(t => toRecurringUnit(t, accountIds, startDate)),
                oneTimeTransactions: dividendsK12.filter(t => t.type === TransactionType.OneTime).map(t => toOneTimeUnit(t, accountIds, startDate)),
                transactionArrays: [],
            }
            ]
        },
        taxInformation: {
            sweTax: { municipalityTaxRate: 0.31 },
            taxPaymentAssetsAndDebtsItemId: taxPaymentAccountId,
        },
        domicile: Domicile.Swe,
        baseCurrency: CurrencyRequest.SEK,
        dateOfBirth: { year: dateOfBirth.getFullYear(), month: dateOfBirth.getMonth() + 1 },
        portfolioStrategies: {
            waterfallAllocationStrategies: portfolio.waterfallAllocationStrategies !== undefined
                ? portfolio.waterfallAllocationStrategies.map(s => ({ tiers: s.tiers.filter(t => accountIds.includes(t.assetsAndDebtsItemId)).map(t => ({ assetsAndDebtsItemId: t.assetsAndDebtsItemId, priority: t.priority, targetValue: t.targetValue, inflationAdjustment: t.inflationAdjustment })) }))
                : []
        }
    }
    return financialPlanningRequest;
}

function toRecurringUnit(t: TransactionVM, accountIds: string[], simulationStartDate: Moment): RecurringUnitTransaction {
    return {
        value: t.amount,
        unit: t.isPercentage ? TransactionUnitRequest.ShareOfAssestsAndDebtsItem : t.inflationAdjustment === InflationAdjustmentContract.NoAdjustment ? TransactionUnitRequest.MonetaryAmount : TransactionUnitRequest.MonetaryAmountValueAsOfStartDate,
        startTimeStep: moment(t.startDate).diff(simulationStartDate, 'months'),
        numberOfTransactions: numberOfTransactions(t),
        frequency: t.frequency,
        targetId: accountIds.includes(t.targetId) ? t.targetId : '',
        sourceId: accountIds.includes(t.sourceId) ? t.sourceId : '',
    };
}

function toOneTimeUnit(t: TransactionVM, accountIds: string[], simulationStartDate: Moment): OneTimeUnitTransaction {
    return {
        value: t.amount,
        unit: t.isPercentage ? TransactionUnitRequest.ShareOfAssestsAndDebtsItem : t.inflationAdjustment === InflationAdjustmentContract.NoAdjustment ? TransactionUnitRequest.MonetaryAmount : TransactionUnitRequest.MonetaryAmountValueAsOfStartDate,
        timeStep: moment(t.startDate).diff(simulationStartDate, 'months'),
        targetId: accountIds.includes(t.targetId) ? t.targetId : '',
        sourceId: accountIds.includes(t.sourceId) ? t.sourceId : '',
    };
}

function toRecurring(t: TransactionVM, accountIds: string[], simulationStartDate: Moment): RecurringMoneyTransaction {
    return {
        amount: t.amount,
        startTimeStep: moment(t.startDate).diff(simulationStartDate, 'months'),
        numberOfTransactions: numberOfTransactions(t),
        frequency: t.frequency,
        targetId: accountIds.includes(t.targetId) ? t.targetId : '',
        inflationAdjustment: t.inflationAdjustment
    };
}

function toOneTime(t: TransactionVM, accountIds: string[], simulationStartDate: Moment): OneTimeMoneyTransaction {
    return {
        amount: t.amount,
        timeStep: moment(t.startDate).diff(simulationStartDate, 'months'),
        targetId: accountIds.includes(t.targetId) ? t.targetId : '',
        inflationAdjustment: t.inflationAdjustment
    };
}

function numberOfTransactions(t: TransactionVM) {
    return Math.round(t.duration * (t.durationUnit === Frequency.Annual ? 12 : 1) / (t.frequency === Frequency.Annual ? 12 : 1));
}

export function createRequest(
    scenarioSet: string,
    currentValue: number,
    productCode: ProductCodeVM,
    monthlyContributions: number,
    contributionHorizon: number,
    monthlyContributionAccountId: string,
    goals: {
        id: number,
        name: string,
        type: string,
        horizonInYears: number,
        durationInYears: number,
        amount: number,
        chosenMonthlyContribution: number,
        enabled: boolean
    }[]): FinancialPlanningV3Request {

    const now = moment("2022-01-01T00:00:00.000Z");

    const numYears = 40;

    const observationDates = [...Array(numYears + 1).keys()].map(index => now.clone().add(index, 'years').toDate());

    const withdrawalAccountId = "4fa85f64-5717-4562-b3fc-2c963f66afa6";

    const assetsAndDebts: AssetsAndDebtsRequest = {
        investmentAccounts: [
            createInvestmentAccount('', monthlyContributionAccountId, productCode, currentValue),
            createInvestmentAccount('', withdrawalAccountId, productCode, currentValue),
        ]
    };

    const incomeTransactions = [{
        value: monthlyContributions,
        startTimeStep: 0,
        numberOfTransactions: contributionHorizon * 12,
        frequency: Frequency.Monthly,
        targetId: monthlyContributionAccountId,
        unit: TransactionUnitRequest.MonetaryAmount
    } as RecurringUnitTransaction
    ];

    const goalWithdrawals = goals.filter(g => g.enabled === true).map(g => (
        {
            value: g.amount,
            timeStep: g.horizonInYears * 12,
            sourceId: withdrawalAccountId,
            unit: TransactionUnitRequest.MonetaryAmount,
        } as OneTimeUnitTransaction
    ));

    const financialPlanningRequest: FinancialPlanningV3Request = {
        startDate: { year: now.toDate().getFullYear(), month: now.toDate().getMonth() + 1 },
        simulationSettings: {
            scenarioSetId: scenarioSet,
            assetsAndDebtsItemGroups: [],
            percentiles: percentiles,
            cashFlowObservations: CashFlowObservationsRequest.Annual,
            inflationAdjustment: InflationAdjustmentContract.NoAdjustment,
            observationTimeSteps: observationDates.map((_, i) => i * 12)
        },
        assetsAndDebts: assetsAndDebts,
        cashFlows: {
            earnedIncome: {
                recurringTransactions: [],
                oneTimeTransactions: [],
                transactionArrays: []
            },
            other:
            {
                recurringTransactions: incomeTransactions,
                oneTimeTransactions: goalWithdrawals,
                transactionArrays: [],
            }
        },
        taxInformation: {
            taxPaymentAssetsAndDebtsItemId: monthlyContributionAccountId,
            sweTax: { municipalityTaxRate: 0.31 }

        },
        domicile: Domicile.Swe,
        baseCurrency: CurrencyRequest.SEK
    }
    return financialPlanningRequest;
}


export function createInvestmentAccount(name: string, investmentAccountId: string, productCode: ProductCodeVM, currentValue: number): InvestmentAccountVM {
    return {
        name: name,
        id: investmentAccountId,
        investmentAllocations: [
            {
                spreadOverGrowthRate: 0,
                annualFee: 0,
                strategyWeight: 1,
                productCode: productCode,
                currentValue: currentValue
            }
        ],
        taxationType: InvestmentAccountTaxationType.NoTax,
        strategy: InvestmentStrategyType.RebalanceToPlan
    };
}


export function createTransactionAccount(name: string, transactionAccountId: string, currentValue: number, currency: CurrencyRequest): TransactionAccountVM {
    return {
        name: name,
        id: transactionAccountId,
        currentValue: currentValue,
        currency: currency
    };
}