import { OpenInNew } from "@mui/icons-material";
import { Autocomplete, Grid, IconButton, TextField, useTheme } from "@mui/material";
import { style } from "@mui/system";
import { useContext, useState } from "react";
import { ProductCodeContract, ProductCodeType } from "../../api_client";
import { staticFundData } from "../../historicalApiClient/funds";
import { SettingsContext } from "../../settings/SettingsContext";
import { ProductCodeVM } from "../Portfolio";
import { ProductView } from "./ProductView";

export default function FundSelect(props: { selectedFund: ProductCodeContract, setSelectedFund: (newValue: ProductCodeContract) => void, style?: any, filteredFunds?: ProductCodeContract[] }) {

  const theme = useTheme();
  const settings = useContext(SettingsContext);
  const optionsFilter = (options: any, state: any) => {
    return options.filter((o: any) => o.name.toLowerCase().includes(state.inputValue.toLowerCase()));
  };

  const { filteredFunds } = props;


  const [productViewOpen, setProductViewOpen] = useState(false);
  const [productViewSelectedFund, setProductViewSelectedFund] = useState(props.selectedFund);

  const fundsInStatic = staticFundData.funds.map((f: any) => f.isin);
  const funds = settings.mappedFunds
    .filter(f => f.codeType === ProductCodeType.Custom || fundsInStatic.find(s => s === f?.code) !== undefined)
    .filter(f=> filteredFunds === undefined || !filteredFunds.map(ff=>ff?.code).includes(f?.code));

  return settings.mappedFunds.length === 0 ? <></> :
    <Grid container>
      <Grid item xs>
        <Autocomplete
          style={{ ...props.style }}
          id="combo-box"
          size="small"
          filterOptions={optionsFilter}
          options={funds as any[]}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          getOptionLabel={(option) => option.name}
          onChange={(e, v) => {
            props.setSelectedFund(v);
          }}
          componentsProps={{
            paper: {
              sx: {
                width: 300
              }
            }
          }}
          value={props.selectedFund}
          renderInput={(params) => <TextField {...params} label="Selected fund" variant="outlined" />}
        />
      </Grid>
      <Grid item xs='auto'>
        <IconButton
          style={{ color: theme.palette.text.secondary }}
          size='small' onClick={() => {
            setProductViewSelectedFund(props.selectedFund);
            setProductViewOpen(true);
          }}>
          <OpenInNew />
        </IconButton>
      </Grid>
      <ProductView open={productViewOpen} selectedFund={productViewSelectedFund as ProductCodeVM}
        handleClose={() => {
          props.setSelectedFund(productViewSelectedFund);
          setProductViewOpen(false);
        }}
        setSelectedFund={setProductViewSelectedFund} />
    </Grid>;
}

