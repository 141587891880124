import { Add, Check, CompareArrows, Delete, Edit, OpenInNew } from "@mui/icons-material";
import { Badge, Button, Dialog, DialogActions, DialogTitle, Divider, FormControl, FormHelperText, Grid, IconButton, InputLabel, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Select, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import React, { useContext, useEffect, useRef, useState } from "react";
import { CurrencyRequest, Frequency, InflationAdjustmentContract, InvestmentAccountTaxationType, InvestmentStrategyType, ProductCodeType, RiskLevel } from "../api_client";
import TransactionView from "../financialPlanning/assetsAndDebts/TransactionView";
import { defaultPortfolio, DisplayState, getAccounts, getAccountsWithSetFunction, InvestmentAccountVM, MortgageVM, OccupationalPensionAgreementType, Portfolio, TransactionAccountVM, TransactionCategory, TransactionType, TransactionVM, WeightCalculationType } from "../financialPlanning/Portfolio";
import { PortfolioContext } from "../settings/PortfolioContext";
import { getColor } from "../financialPlanning/assetsAndDebts/AssetsAndDebtsView";
import { rallyBlueArray, rallyGreenArray, rallyOrangeArray } from "../theming/useAppColors";
import { calculateWeights } from "../financialPlanning/assetsAndDebts/InvestmentAllocationsView";
import { getCustomerData, getDefaultInvestmentGoalPlanningResult } from "../api_client/tsClient";
import { SettingsContext } from "../settings/SettingsContext";
import { useLocation, useNavigate } from "react-router-dom";
import { staticFundData } from "../historicalApiClient/funds";


export function PortfolioSelect(props: { resetResults: () => void }) {

    const theme = useTheme();
    const portfolioContext = useContext(PortfolioContext);
    const settings = useContext(SettingsContext);
    const navigate = useNavigate();
    const location = useLocation();


    const portfolios = portfolioContext.portfolios;
    const portfolio = portfolioContext.portfolios[portfolioContext.activePortfolioIndex];

    const accounts = portfolio !== undefined ? getAccounts(portfolio.assetsAndDebts) : [];


    const [anchorAddPortfolioEl, setAnchorAddPortfolioEl] = React.useState<null | HTMLElement>(null);
    const addPortfolioOpen = Boolean(anchorAddPortfolioEl);
    const handleAddPortfolioClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorAddPortfolioEl(event.currentTarget);
    };
    const handleAddPortfolioClose = () => {
        setAnchorAddPortfolioEl(null);
    };

    const [portfolioName, setPortfolioName] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [taxPaymentsAccountId, setPaymentsAccountId] = useState('newaccount');


    const [portfolioNameDialogOpen, setPortfolioNameDialogOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const taxAccountInvalid = accounts.find(a => a.id === portfolio?.taxPaymentsAccountId) === undefined;
    const taxAccountInStateInvalid = accounts.find(a => a.id === taxPaymentsAccountId) === undefined;
    const errorText = taxAccountInvalid ? 'Account for tax payments not set.' : '';

    const handlePortfolioNameDialogOpen = (portfolio: Portfolio) => {
        setPortfolioName(portfolio?.name);
        setStartDate(portfolio?.startDate);
        setDateOfBirth(portfolio?.dateOfBirth);
        setPaymentsAccountId(portfolio?.taxPaymentsAccountId === '' || taxAccountInvalid ? 'newaccount' : portfolio?.taxPaymentsAccountId);
        setPortfolioNameDialogOpen(true);
    };

    const handlePortfolioNameDialogClose = () => {
        setPortfolioNameDialogOpen(false);
    };



    const [linkCode, setLinkCode] = useState('');

    useEffect(() => {


        const params = new URLSearchParams(location.search);
        const code = params.get('code'); // Replace 'myParam' with your actual query parameter name
        if (code && settings.mappedFunds.length > 0 && portfolioContext.isLoaded) {
            if (linkCode === code) return;
            setLinkCode(linkCode);

            getCustomerData(code).then(d => {

                if (d === undefined) return;

                var isins = staticFundData.funds.map(s => s.isin); // settings.mappedFunds.filter(f => f.codeType === ProductCodeType.Isin).map(f => f.code);

                const assetsAndDebts = portfolio.assetsAndDebts;
                var newAccounts = d.cashAccounts.map((a: any) => ({
                    displayState: DisplayState.Display,
                    enabled: true,
                    name: a.description,
                    id: a._id,
                    currentValue: a.balance,
                    currency: CurrencyRequest.SEK,
                    color: getColor(rallyGreenArray, assetsAndDebts.transactionAccounts as any, 3)
                }));

                var newInvestmentAccounts = d.portfolios.map((p: any) => ({
                    displayState: DisplayState.Display,
                    enabled: true,
                    name: p.name,
                    id: p._id,
                    investmentAllocations: calculateWeights(WeightCalculationType.DeriveWeightsFromCurrentValue, d.investments
                        .filter((i: any) => i.portfolio_id === p._id)
                        .map((i: any, index: number) => {
                            return {
                                spreadOverGrowthRate: 0,
                                annualFee: 0,
                                strategyWeight: 0,
                                // productCode: { name: i.name, codeType: ProductCodeType.Isin, code: i.isin },
                                productCode: { name: i.name, codeType: ProductCodeType.Isin, code: isins[index] }, // use any mapped for now
                                currentValue: i.balance
                            };
                        })),
                    strategy: InvestmentStrategyType.RebalanceToPlan,
                    taxationType: InvestmentAccountTaxationType.SweIsk,
                    annualAccountFee: 0.0,
                    weightCalculationType: WeightCalculationType.DeriveWeightsFromCurrentValue,
                    optimizationHorizonInYears: 10,
                    optimizationRiskLevel: RiskLevel.MediumHighRisk,
                    color: getColor(rallyBlueArray, assetsAndDebts.investmentAccounts as any, 3)
                } as InvestmentAccountVM));

                var newMortgages = d.liabilities.map((a: any) => ({
                    displayState: DisplayState.Display,
                    name: a.name,
                    enabled: true,
                    id: a._id,
                    currentValue: -a.balance,
                    annualInterestRate: a.interest.rate / 100,
                    fixedRatePeriodInMonths: 12,
                    interestRatePaymentAccountId: accounts[0]?.id,
                    minimumMonthlyAmortization: 0,
                    durationUnit: Frequency.Annual,
                    color: getColor(rallyOrangeArray, assetsAndDebts.mortgages as MortgageVM[], 2)
                } as MortgageVM));

                var newAssetsAndDebts = {
                    ...assetsAndDebts,
                    transactionAccounts: [...assetsAndDebts.transactionAccounts, ...newAccounts],
                    investmentAccounts: [...assetsAndDebts.investmentAccounts, ...newInvestmentAccounts],
                    mortgages: [...assetsAndDebts.mortgages, ...newMortgages],
                };


                const expenses = d.cashAccountTransactions
                    .filter((t: any) => t.amount < 0)
                    .reduce((accumulator: number, currentObject: any) => accumulator + currentObject.amount, 0);

                const income = d.cashAccountTransactions
                    .filter((t: any) => t.amount > 0)
                    .reduce((accumulator: number, currentObject: any) => accumulator + currentObject.amount, 0);

                var newTransactions = [{
                    id: uuidv4(),
                    amount: expenses,
                    startDate: moment().toDate(),
                    category: TransactionCategory.Expense,
                    name: 'Expenses',
                    type: TransactionType.Recurring,
                    duration: 40,
                    durationUnit: Frequency.Annual,
                    frequency: Frequency.Annual,
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate,
                    isPercentage: false,
                    sourceId: newAssetsAndDebts.transactionAccounts[0]?.id,
                    targetId: '',
                    displayState: DisplayState.Display,
                    occupationalPensionAgreement: { accountId: '', percentage: 0.1, type: OccupationalPensionAgreementType.NoAgreement }
                } as TransactionVM,
                {
                    id: uuidv4(),
                    amount: income,
                    startDate: moment().toDate(),
                    category: TransactionCategory.TaxExemptIncome,
                    name: 'Income',
                    type: TransactionType.Recurring,
                    duration: 25,
                    durationUnit: Frequency.Annual,
                    frequency: Frequency.Annual,
                    inflationAdjustment: InflationAdjustmentContract.ValueAsOfStartDate,
                    isPercentage: false,
                    sourceId: '',
                    targetId: newAssetsAndDebts.transactionAccounts[0]?.id,
                    displayState: DisplayState.Display,
                    occupationalPensionAgreement: { accountId: '', percentage: 0.1, type: OccupationalPensionAgreementType.NoAgreement }
                } as TransactionVM];




                const newPortfolios = portfolioContext.portfolios.map((p: any, i: number) =>
                    i === portfolioContext.activePortfolioIndex
                        ? { ...p, assetsAndDebts: newAssetsAndDebts, transactions: [...p.transactions, ...newTransactions] }
                        : p);
                portfolioContext.setPortfolios(newPortfolios)

                navigate('/');
            });

        }
    }, [settings.mappedFunds, portfolios, portfolioContext.setPortfolios, portfolioContext.isLoaded]);

    const showDialog = portfolios.length === 0 || portfolioNameDialogOpen;

    const importFromBank = () => {
        if (process.env.NODE_ENV === 'development' || window.location.hostname.startsWith("localhost")) {
            window.location.replace('https://platform.flanks.io/link?token=ac70e7c1-76cb-4e74-ae89-2d2b67f92f17&language=sv');
        }
        else {
            window.location.replace('https://platform.flanks.io/link?token=6c09312b-0749-4e59-81ce-1a0768caadb9&language=sv');
        }

    };

    return (
        <Grid container>
            <Grid item xs>
                <FormControl fullWidth={true} size="small">
                    <InputLabel id="portfolio-select">Portfolio</InputLabel>
                    <Select label="Portfolio"
                        labelId="portfolio-select"
                        fullWidth={true}

                        value={portfolioContext.activePortfolioIndex} onChange={(e: any) => {
                            portfolioContext.setActivePortfolioIndex(e.target.value as number);
                            props.resetResults();
                        }}>
                        {portfolioContext.portfolios.map((p, i) => (<MenuItem key={p.name} value={i}>{p.name}</MenuItem>))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs='auto'>
                {/* <IconButton style={{ color: theme.palette.text.secondary, marginLeft: theme.spacing(1) }} onClick={handlePortfolioNameDialogOpen}> */}
                <Tooltip title={errorText}>
                    <Badge color='warning' badgeContent={errorText === '' ? undefined : '!'}>
                        {/* <IconButton disabled={errorText !== ''} style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={() => setInvestmentAccount({ ...investmentAccount, displayState: DisplayState.Display })}> */}
                        <IconButton style={{ color: theme.palette.text.secondary, marginLeft: theme.spacing(1) }}
                            onClick={() => { setIsEditMode(true); handlePortfolioNameDialogOpen(portfolio); }}>
                            <Edit />
                        </IconButton>
                    </Badge>
                </Tooltip>
                {/* <Edit /> */}
                {/* </IconButton> */}
                <Dialog fullWidth={true} maxWidth={"xs"} onClose={handlePortfolioNameDialogClose} aria-labelledby="edit-dialog-title" open={showDialog}>
                    <DialogTitle id="edit-dialog-title">{isEditMode && portfolios.length !== 0 ? 'Edit portfolio' : 'Add portfolio'}</DialogTitle>
                    <div style={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3), paddingBottom: theme.spacing(2) }}>
                     {portfolios.length === 0 ?<Typography variant="body1" color='text.secondary' style={{paddingBottom:theme.spacing(2)}}>Create your first portfolio to simulate your financial situation and gather insights!</Typography>:<></>}

                        <TextField
                            variant='outlined'
                            style={{ marginBottom: theme.spacing(1) }}
                            margin='none'
                            size='small'
                            fullWidth={true}
                            required label={'Name'}
                            onChange={(e: any) => { setPortfolioName(e.target.value) }}
                            value={portfolioName} />
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label={"Start date"}
                                value={startDate}
                                onChange={(newValue: any) => {
                                    setStartDate(newValue.toDate());
                                }}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                            <div style={{ paddingTop: theme.spacing(1) }}></div>
                            <DatePicker
                                label={"Date of birth"}
                                value={dateOfBirth}
                                onChange={(newValue: any) => {
                                    setDateOfBirth(newValue.toDate());
                                }}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>
                        <div style={{ paddingBottom: theme.spacing(1) }}></div>
                        <FormControl fullWidth={true} size="small">
                            <InputLabel required id="tax-select">Main account (tax/pension/interest)</InputLabel>
                            <Select label="Main account (tax/pension/interest)"
                                labelId="tax-select"
                                required
                                fullWidth={true}
                                value={taxAccountInStateInvalid ? 'newaccount' : taxPaymentsAccountId}
                                onChange={e => {
                                    setPaymentsAccountId(e.target.value);
                                }}>
                                {accounts.map(a => (<MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>))}
                                <MenuItem key={'newaccount'} value={'newaccount'}>Create new account</MenuItem>
                            </Select>
                            <FormHelperText>This account will be used for transactions related to tax/pension and interest payments. You can choose to create a new account here or update this later.</FormHelperText>
                        </FormControl>
                    </div>
                    <DialogActions>
                    {portfolios.length !== 0 ? <Button onClick={handlePortfolioNameDialogClose} color="primary">
                            Cancel
                        </Button> : <></>}
                        <Button
                            onClick={() => {

                                const newId = uuidv4();
                                const editedPortfolio = portfolios.length === 0 ? defaultPortfolio() : portfolio
                                const assetsAndDebts = taxPaymentsAccountId === 'newaccount'
                                    ? {
                                        ...editedPortfolio.assetsAndDebts, transactionAccounts: [...editedPortfolio.assetsAndDebts.transactionAccounts, {
                                            displayState: DisplayState.Edit,
                                            enabled: true,
                                            name: 'Account ' + (editedPortfolio.assetsAndDebts.transactionAccounts.length + 1),
                                            id: newId,
                                            currentValue: 0,
                                            currency: CurrencyRequest.SEK,
                                            color: getColor(rallyGreenArray, editedPortfolio.assetsAndDebts.transactionAccounts as any, 3)
                                        } as TransactionAccountVM]
                                    }
                                    : editedPortfolio.assetsAndDebts;
                                const updatedPortfolio = {
                                    ...editedPortfolio, assetsAndDebts: assetsAndDebts, name: portfolioName, startDate: startDate, dateOfBirth: dateOfBirth,
                                    taxPaymentsAccountId: taxPaymentsAccountId === 'newaccount' ?
                                        newId
                                        : taxPaymentsAccountId
                                };
                                const result = portfolios.length === 0 ? [updatedPortfolio] : portfolioContext.portfolios.map((p, i) =>
                                    i === portfolioContext.activePortfolioIndex ? updatedPortfolio : p);
                                handlePortfolioNameDialogClose();
                                portfolioContext.setPortfolios(result);
                                if (portfolios.length === 0) // onboarding
                                {
                                    portfolioContext.setActivePortfolioIndex(0);
                                }
                                props.resetResults();
                            }}
                            disabled={portfolioName === ''}
                            color="primary"
                            variant="contained">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
            <Grid item xs='auto'>
                <IconButton style={{ color: theme.palette.text.secondary }} onClick={() => {
                    portfolioContext.setPortfolios(portfolioContext.portfolios.length === 1 ? [defaultPortfolio()] : portfolioContext.portfolios.filter((p, i) => i !== portfolioContext.activePortfolioIndex));
                    portfolioContext.setActivePortfolioIndex(0);
                }
                }>
                    <Delete />
                </IconButton>
            </Grid>
            <Grid item xs='auto'>
                <IconButton style={{ color: theme.palette.text.secondary }} onClick={handleAddPortfolioClick}>
                    <Add />
                </IconButton>
                <Menu
                    id="addportfolio-menu"
                    anchorEl={anchorAddPortfolioEl}
                    open={addPortfolioOpen}
                    onClose={handleAddPortfolioClose}
                >
                    <MenuItem key='addnew' onClick={() => {
                        const addedPortfolio = defaultPortfolio();
                        portfolioContext.setPortfolios([...portfolioContext.portfolios, addedPortfolio]);
                        portfolioContext.setActivePortfolioIndex(portfolioContext.portfolios.length);
                        setIsEditMode(false);
                        handleAddPortfolioClose();
                        // open dialog
                        handlePortfolioNameDialogOpen(addedPortfolio);
                    }}
                    >Add new</MenuItem>
                    <MenuItem key='addcopy' onClick={() => {
                        const newPortfolio = JSON.parse(JSON.stringify(portfolioContext.portfolios[portfolioContext.activePortfolioIndex]))
                        newPortfolio.name = portfolioContext.portfolios[portfolioContext.activePortfolioIndex].name + ' (copy)';
                        portfolioContext.setPortfolios([...portfolioContext.portfolios, newPortfolio]);
                        portfolioContext.setActivePortfolioIndex(portfolioContext.portfolios.length);
                        handleAddPortfolioClose();
                    }}>Add copy</MenuItem>
                    <Divider></Divider>
                    <MenuItem onClick={importFromBank}>Import from bank <OpenInNew style={{ marginLeft: 5, marginBottom: 2 }} fontSize="small" /></MenuItem>
                </Menu>

            </Grid>
        </Grid>
    );
}

