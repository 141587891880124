import React, { Fragment, useRef, useState } from 'react';
import { Bar, CartesianGrid, Area, ResponsiveContainer, XAxis, YAxis, ComposedChart, Tooltip } from 'recharts';
// import { wideOutcomePercentiles, mediumOutcomePercentiles, narrowOutcomePercentiles } from './appConstants';
// import { useAppColors } from './useAppColors';
// import { TextNumberFormat } from './NumberFormatCustom'
import moment from 'moment';
import { rallyBlue, useAppColors } from '../theming/useAppColors';
import { TextNumberFormat } from '../NumberFormatCustom';
import { CashFlowResponse, Frequency, PointInTimeCashFlowResponse } from '../api_client';
import { Alert, Checkbox, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Snackbar, Typography, useTheme } from '@mui/material';
import { Check, FilterAlt, OpenInFull, Screenshot } from '@mui/icons-material';
import html2canvas from 'html2canvas';

function chunk(a: any, l: number): any {
    if (a.length === 0) return [];
    else return [a.slice(0, l)].concat(chunk(a.slice(l), l));
}

function IncomeBarChart(props: {
    cashFlows: CashFlowResponse[],
    isAnnual: boolean,
    horizon: number,
    height: any,
    accountNames: { name: string, id: string, color?: string }[],
    showXAxis?: boolean,
    aggregateAccounts?: boolean,
    startDate?: Date,
    setCashFlowChartDialogOpen?: (val: any) => void,
    observationFrequency: Frequency
},) {

    const { horizon, isAnnual, setCashFlowChartDialogOpen, showXAxis, startDate, observationFrequency } = props;
    const colors = useAppColors();
    const theme = useTheme();

    const [filter, setFilter] = useState('all');
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const canvasRef = useRef();


    const isFullScreen = setCashFlowChartDialogOpen === undefined;
    const fontSize = isFullScreen ? 12 : 10;
    const startYear = startDate ? moment(startDate).toDate().getFullYear() : 0;

    const [anchorCompareEl, setAnchorCompareEl] = React.useState<null | HTMLElement>(null);
    const compareOpen = Boolean(anchorCompareEl);
    const handleCompareClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorCompareEl(event.currentTarget);
    };
    const handleCompareClose = (id: string) => {
        if (id)
            setFilter(id);
        setAnchorCompareEl(null);
    };

    if (!props.cashFlows || props.cashFlows.length === 0)
        return (<></>);

    const annualDisplayResolution = isAnnual ? 1 : 12;
    const annualDataResolution = observationFrequency === Frequency.Annual ? 1 : 12;

    let accountCashFlows = props.cashFlows
        .map(cf => {
            const nameAndColor = generateNameAndColor(cf.assetsAndDebtsItemId, props.accountNames);
            return {
                assetsAndDebtsItemId: cf.assetsAndDebtsItemId,
                averageCashFlows: (!(isAnnual && observationFrequency === Frequency.Monthly) ? cf.averageCashFlows : chunk(cf.averageCashFlows, 12)
                    .map((a: any) => a.reduce((tot: any, current: any) => {
                        return {
                            in: tot.in + current.in,
                            out: tot.out + current.out,
                            net: tot.net + current.net
                        }
                    }))).slice(0, isAnnual === false && observationFrequency === Frequency.Monthly ? horizon * 12 : horizon).map((o: any, i: any) => {
                        return {
                            // timeStep: '' + (i),
                            [cf.assetsAndDebtsItemId + 'in']: o.in,
                            [cf.assetsAndDebtsItemId + 'out']: -o.out,
                            net: o.net
                        }
                    }),
                name: nameAndColor.name,
                color: nameAndColor.color,
                isInternal: nameAndColor.isInternal
            }
        });


    accountCashFlows = accountCashFlows.filter(cf => !cf.isInternal).concat(accountCashFlows.filter(cf => cf.isInternal))

    let chartData = accountCashFlows[0].averageCashFlows.map((_: any, i: number) => ({ timeStep: '' + i, year: !isAnnual && observationFrequency === Frequency.Monthly ? (startYear + i / 12) : (startYear + i), net: 0 }));
    const filteredAccountCashFlows = accountCashFlows
        .filter(cf => filter === 'all'
            ? !cf.assetsAndDebtsItemId.includes('_')
            : filter.includes(cf.assetsAndDebtsItemId + ','));
    filteredAccountCashFlows.forEach((a) => {
        a.averageCashFlows.forEach((c: any, i: any) => {
            chartData[i] = { ...chartData[i], ...c, net: chartData[i].net + c.net };
        }
        )
    });


    const CustomizedAxisTick = (props: any) => {
        const {
            x, y, stroke, payload,
        } = props;

        return (
            <>
                <TextNumberFormat hidePrefix roundToNearest={1} value={payload.value} style={{ fontSize: 10 }}
                    renderText={(value: any) =>
                        <g transform={`translate(${x},${y})`}>
                            <text x={0} y={0} dy={2} textAnchor="end" style={{ fontSize: 10 }} fill={colors.chart.axisLines} >{value}</text>
                        </g>
                    }
                />

            </>
        );
    }

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <Paper style={{ padding: 4, opacity: 0.9 }} square>
                        <Typography variant='body2'>

                            {timeStepToHorizon(payload, isAnnual)}<br />
                            {/* Inflow: <TextNumberFormat hidePrefix roundToNearest={1} value={payload[0].payload.in} /><br />
                            Outflow: <TextNumberFormat hidePrefix roundToNearest={1} value={-1 * payload[0].payload.out} /><br /> */}
                            {filteredAccountCashFlows.map(cf =>
                                <Fragment key={cf.assetsAndDebtsItemId}>
                                    <span style={{ color: cf.color }}>&#9679;</span> {cf.name}, in: <TextNumberFormat hidePrefix roundToNearest={1} value={payload[0].payload[cf.assetsAndDebtsItemId + 'in']} />, out: <TextNumberFormat hidePrefix roundToNearest={1} value={-1 * payload[0].payload[cf.assetsAndDebtsItemId + 'out']} /><br />
                                </Fragment>
                            )}
                            Net: <TextNumberFormat hidePrefix roundToNearest={1} value={payload[0].payload.net} /><br />
                            {/* {bounds.map((b: any, i: any) => {
                                return (<><TextNumberFormat key={b.lower.percentile} hidePrefix roundToNearest={1} value={payload[0].payload[i + ''][0]} /> - <TextNumberFormat customInput={Typography} hidePrefix roundToNearest={1} value={payload[0].payload[i + ''][1]} /> ({Math.round(b.lower.percentile * 100)}% - {Math.round(b.upper.percentile * 100)}%) <br /></>);
                            })} */}
                        </Typography>
                    </Paper>
                </div>
            );
        }

        return null;
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    return (
        <div ref={canvasRef} style={{ height: props.height === undefined ? '10rem' : props.height }}>
            <Paper style={{ boxShadow: "none", display: 'inline-block', marginTop: theme.spacing(1), marginLeft: theme.spacing(1), padding: '2px' }} >
                {!isFullScreen ?
                    <IconButton onClick={() => setCashFlowChartDialogOpen(true)} size='small' style={{ marginRight: 2 }}>
                        <OpenInFull fontSize='small' />
                    </IconButton> : <></>}
                {isFullScreen ?
                    <IconButton style={{ marginLeft: 2 }} onClick={() => {
                        html2canvas(canvasRef.current, { backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#eee' }).then(canvas => {
                            try {
                                canvas.toBlob(blob => navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]));
                                setSnackbarOpen(true);
                            } catch (error) {
                                console.log(error);
                            }
                        });
                    }}>
                        <Screenshot fontSize='small' />
                    </IconButton>
                    : <></>}
                <IconButton onClick={handleCompareClick} size='small'>
                    <FilterAlt fontSize='small' />
                </IconButton>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert severity="success">Image saved to clipboard!</Alert>
                </Snackbar>
            </Paper>
            <Menu
                id="compare-menu"
                anchorEl={anchorCompareEl}
                open={compareOpen}
                onClose={() => handleCompareClose(undefined)}
            >
                <MenuItem onClick={() => {
                    // const all = accountCashFlows.filter(cf => !cf.isInternal).map((value) => value.assetsAndDebtsItemId + ',').join();
                    const all = 'all';
                    const newFilter = all === filter ? '' : all;
                    setFilter(newFilter)
                }} value={-1}><ListItemText>Toggle accounts</ListItemText></MenuItem>
                <Divider />
                <List>
                    {accountCashFlows.filter(cf => !cf.isInternal).map((value, i) => {
                        const labelId = `checkbox-list-labelr-${i}`;
                        const enabled = filter.includes(value.assetsAndDebtsItemId + ',') || filter === 'all'
                        return (
                            <ListItem
                                key={i}
                                disablePadding
                            >
                                <ListItemButton role={undefined} onClick={() => { setFilter(enabled ? filter.replace('all', '').replace(value.assetsAndDebtsItemId + ',', '') : filter.replace('all', '') + value.assetsAndDebtsItemId + ',') }} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={enabled}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={value.name} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
                <Divider />
                <List>
                    {accountCashFlows.filter(cf => cf.isInternal).map((value, i) => {
                        const labelId = `checkbox-list-labelr-${i}`;
                        const enabled = filter.includes(value.assetsAndDebtsItemId)
                        return (
                            <ListItem
                                key={i}
                                disablePadding
                            >
                                <ListItemButton role={undefined} onClick={() => { setFilter(enabled ? filter.replace('all', '').replace(value.assetsAndDebtsItemId + ',', '') : filter.replace('all', '') + value.assetsAndDebtsItemId + ',') }} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={enabled}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={value.name} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Menu>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart syncId={1} data={chartData}
                    stackOffset="sign"
                    barCategoryGap={2}
                    margin={{
                        top: 15, right: 25, left: 23, bottom: 20
                    }}>


                    <defs>
                        <pattern id="pattern-stripe"
                            width="8" height="8"
                            patternUnits="userSpaceOnUse"
                            patternTransform="rotate(45)">
                            <rect width="4" height="8" transform="translate(0,0)" fill="black"></rect>
                        </pattern>
                        <linearGradient id="lineColorShortfall" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="5%" stopColor={"#000000"} stopOpacity={0.8} />
                            <stop offset="95%" stopColor={"#000000"} stopOpacity={0.8} />
                        </linearGradient>
                        <linearGradient id="fanChartMedium" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colors.fanChart.medium} stopOpacity={1} />
                            <stop offset="95%" stopColor={colors.fanChart.medium} stopOpacity={0.6} />
                        </linearGradient>
                        <linearGradient id="colorShortfall" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={colors.spendingItems.interestPayment} stopOpacity={0.6} />
                            <stop offset="95%" stopColor={colors.spendingItems.interestPayment} stopOpacity={1} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeOpacity={0.1} vertical={false} />
                    {showXAxis ? <XAxis type="number" allowDecimals={false} tick={{ fontSize: fontSize, strokeWidth: 0, fill: colors.chart.axisLines }} tickCount={40} domain={[startYear, startYear + horizon]} interval={'preserveStartEnd'} dataKey="year" /> : <XAxis tick={{ fontSize: 10 }} ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90]} dataKey="timeStep" />}
                    {/* <YAxis width={30} type="number" ticks={[0, investedCapital]} domain={[0, investedCapital]} tick={<CustomizedAxisTick />} /> */}
                    <YAxis width={35} type="number" tick={<CustomizedAxisTick />} />
                    {/* <Bar animationDuration={500} barSize={6} type="monotone" dataKey="in" strokeOpacity={0} fillOpacity={0} /> */}
                    {filteredAccountCashFlows.map(cf =>
                        <>
                            <Bar animationDuration={500} stackId="a" type="monotone" dataKey={cf.assetsAndDebtsItemId + 'in'} strokeWidth={2} strokeOpacity={0.8} stroke={cf.color} fillOpacity={0.5} fill={cf.color} />
                            <Bar animationDuration={500} stackId="a" type="monotone" dataKey={cf.assetsAndDebtsItemId + 'out'} strokeWidth={2} strokeOpacity={0.8} stroke={colors.spendingItems.interestPayment} fillOpacity={0.5} fill={cf.color} />
                        </>
                    )}
                    {/* <Bar animationDuration={500} stackId="a" type="monotone" dataKey="in" strokeWidth={2} strokeOpacity={0.8} stroke={colors.fanChart.medium} fillOpacity={0.5} fill={"url(#fanChartMedium)"} />
                    <Bar animationDuration={500} stackId="a" type="monotone" dataKey="out" strokeWidth={2} strokeOpacity={0.8} stroke={colors.spendingItems.interestPayment} fillOpacity={0.5} fill={"url(#colorShortfall)"} /> */}
                    <Area animationDuration={500} type="linear" dataKey="net" strokeOpacity={0.8} strokeDasharray="3 3" stroke={colors.fanChart.wide} strokeWidth={2} fillOpacity={0} fill="#00000000" />
                    <Tooltip content={<CustomTooltip />} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>);
}

function timeStepToHorizon(payload: any, isAnnual: boolean) {
    const numYears = isAnnual ? payload?.[0].payload.timeStep : Math.floor(payload?.[0].payload.timeStep / 12);
    const numMonths = isAnnual ? 0 : payload?.[0].payload.timeStep - numYears * 12;
    return 'Horizon: ' + numYears + ' years' + (numMonths > 0 ? ' ' + numMonths + ' months' : '');
}

const nameMap: any = {
    "SweInterestPayments": "Interest payments",
    "SweEarnedIncome": "Earned income",
    "SweDividendsK10": "Dividends K10",
    "SweDividendsK12": "Dividends K12",
    "SwePensionIncome": "Pension income",
    "SweTaxesPaid": "Taxes",
}

function generateNameAndColor(id: string, accountNames: { name: string, id: string, color?: string }[]): { name: string, isInternal: boolean, color: string } {
    if (id.includes('_')) {
        const parts = id.split('_');
        const name = accountNames.find(a => a.id === parts[0]);
        return name ? { name: name.name + ' (Fees)', isInternal: true, color: name.color !== undefined ? name.color : rallyBlue[500] } : { name: nameMap[parts[1]], isInternal: true, color: rallyBlue[500] }
    }
    var item = accountNames?.find(a => a.id === id);
    return { name: item?.name, isInternal: false, color: item?.color !== undefined ? item?.color : rallyBlue[500] };
}


export function areEqual(prevProps: any, nextProps: any) {
    var prevProps2 = Object.assign({}, prevProps);
    delete prevProps2.targetLabel;

    var nextProps2 = Object.assign({}, nextProps);
    delete nextProps2.targetLabel;

    if (JSON.stringify(prevProps2) === JSON.stringify(nextProps2)) {
        return true;
    }
    return false;
}

export default React.memo(IncomeBarChart, areEqual);
