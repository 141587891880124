// import { getActiveScenarioSet } from './forecastClient'
import { EfficientFrontierV3Response, GapFactorByTimeStepResponse, InvestmentAccountRequest, InvestmentAllocationRequest, OutRankFinancialPlanningV3Api, OutRankPortfolioOptimizationV3Api, RiskLevel, V3FinancialplanninggapBody } from './index'
import { percentiles, wideOutcomePercentiles } from '../theming/appConstants'
import {
    RecurringMoneyTransaction,
    // AdviceRequestModelInvestmentRiskLevel,
    // AdviceRequestModelLossRiskLevel,
    // AdviceRequestModelReturnRiskLevel,
    // ApplicationSpecificRiskLevel,
    AssetsAndDebtsRequest,
    FinancialPlanningV3Request,
    FinancialPlanningV3Response,
    Frequency,
    InvestmentAccountTaxationType,
    InvestmentStrategyType,
    // IsaContributionStrategy,
    ProductCodeType
} from './index';
import moment from 'moment';
import { request } from 'http';
import { Configuration, HistoricalDataController2Api, ITimeSeriesPoint } from '../historicalApiClient';
import { ClusterContextStatus, ClusterFundContract, ClusteringApi, FundClusterContract, FundClusterSetResponse } from '../clusteringApiClient';
import { staticFundData } from '../historicalApiClient/funds';
import { PortfolioAnalysisApi, PortfolioStressScenarioResponse, PortfolioStressScenarioSetRequest, ProductCodeCodeType } from '../portfolioAnalysisApiClient';

export let baseUri = "https://outrankproxyapp.azurewebsites.net";
// export let historicalUri = "https://kdbrk-hds-dev-ap.azurewebsites.net";
export let historicalUri = "https://kdbrk-hds-prod-ap.azurewebsites.net";

if (process.env.NODE_ENV === 'development' || window.location.hostname.startsWith("localhost")) {
    baseUri = "https://localhost:5001";
    historicalUri = "https://localhost:5011"
    // baseUri = "https://mt-prod.outrank.app";

}
else if (window.location.hostname.startsWith("demoortestsa") || window.location.hostname.startsWith("demo.outrank.cloud") || window.location.hostname.startsWith("kdbrkoutlisticsa") || window.location.hostname.startsWith("kdbrkoutlisticdevsa")) {
    // baseUri = "https://kdbrk-or-demo-tm.trafficmanager.net";
    // baseUri = "https://mt-prod.outrank.app";
    baseUri = "https://outrankproxyapp.azurewebsites.net";
}

export function getClient(): OutRankFinancialPlanningV3Api {
    const client = new OutRankFinancialPlanningV3Api(undefined, baseUri);
    return client;
}
export const namedFunds: any = {
    0: { id: 'SE0000810863', name: "Skandia Kapitalmarknadsfond" },
    1: { id: 'SE0000810863', name: "Skandia Kapitalmarknadsfond" },
    2: { id: 'LU1980295213', name: "Global Corporate Bond Fund" },
    3: { id: 'SE0000810731', name: "Skandia SMART Försiktig" },
    4: { id: 'SE0000810905', name: "Skandia SMART Balanserad" },
    5: { id: 'SE0005281847', name: "Skandia Global Exponering" },
};

export const namedFundsArray: any = [
    { id: 'SE0000810863', name: "Skandia Kapitalmarknadsfond" },
    { id: 'SE0000810863', name: "Skandia Kapitalmarknadsfond" },
    { id: 'LU1980295213', name: "Global Corporate Bond Fund" },
    { id: 'SE0000810731', name: "Skandia SMART Försiktig" },
    { id: 'SE0000810905', name: "Skandia SMART Balanserad" },
    { id: 'SE0005281847', name: "Skandia Global Exponering" },
];

const filteredContracts = [{
    "name": "DKK",
    "codeType": "Custom",
    "code": "Deposit/DKK"
},
{
    "name": "EUR",
    "codeType": "Custom",
    "code": "Deposit/EUR"
},
{
    "name": "GBP",
    "codeType": "Custom",
    "code": "Deposit/GBP"
},
{
    "name": "JPY",
    "codeType": "Custom",
    "code": "Deposit/JPY"
},
{
    "name": "NOK",
    "codeType": "Custom",
    "code": "Deposit/NOK"
},
{
    "name": "SEK",
    "codeType": "Custom",
    "code": "Deposit/SEK"
},
{
    "name": "USD",
    "codeType": "Custom",
    "code": "Deposit/USD"
},
{
    "name": "K-trad",
    "codeType": "Custom",
    "code": "K-trad"
}]

export async function getScenarioSets() {
    // const client = new OutRankFinancialPlanningV3Api(undefined, baseUri);
    // var sets = await client.apiV3SimulationScenarioSetsGet();
    // return sets;
    const response = await fetch(baseUri + "/config/v1/balance-sheet-predict-context");
    const data = await response.json();
    const sets = data
        .filter((s: any) => s.status === "Published" || s.status === "Active")
        .sort((a:any, b:any) => a.status > b.status ? -1 : 1) 
        .map((s: any) => ({scenarioSetId: s.id, name: s.name }));
    return sets;
}


export async function getStressScenarioSets() {
    
    const client = new OutRankFinancialPlanningV3Api(undefined, baseUri);
    var sets = await client.apiV3SimulationHistoricalStressScenarioSetsGet();

    return sets;
}


export async function getMappedFunds(scenarioSet: string) {
    const client = new OutRankFinancialPlanningV3Api(undefined, baseUri);
    var sets = await client.apiV3SimulationProductMappedProductsGet(scenarioSet);
    sets = [...new Map(sets.map(item =>
        [item['name'], item])).values()];
    const filteredContractsCodes = filteredContracts.map(c => c.code);
    return sets.filter(p => !filteredContractsCodes.includes(p.code));
}

export async function getStressScenarios(
    scenarioSet: string,
    investmentPortfolio: { code: string, codeType: string, name: string, weight: number }[])
    : Promise<PortfolioStressScenarioResponse[] | Response> {

    const client = new PortfolioAnalysisApi(undefined, baseUri);
    const request: PortfolioStressScenarioSetRequest = {
        balanceSheetPredictContextId: scenarioSet,
        portfolio: {
            id: "567223b0-afef-4707-9be5-2047673ad839",
            strategy: InvestmentStrategyType.BuyAndHold,
            taxationType: InvestmentAccountTaxationType.NoTax,
            annualAccountFee: 0,
            investmentAllocations: investmentPortfolio.map(a => ({
                currencyCode: "SEK",
                acquisitionValue: 0,
                currentValue: 0,
                fees: { annualFee: 0, performanceFee: 0, purchaseFee: 0, salesFee: 0, transactionCost: 0 },
                productCode: { code: a.code, codeType: (a.codeType === 'Isin' ? 'Isin' : 'Insurance') as any},
                outperformanceAssumption: 0,
                weight: a.weight
            }))
        }
    };
    const result = await client.apiUsecasePortfolioAnalysisPortfolioStressScenarioSetPost(request);
    return result;
}
const filteredCurrencies = ['SEK', 'EUR', 'GBP', 'NOK', 'DKK', 'USD'];

let currencyExchangRates: [{ currency: string, rateRelativeEuro: number, rateRelativeSek: number }] = undefined;

export async function getCurrencies() {
    await downloadCurrencyExchangeRates();

    const rates = currencyExchangRates.filter(r => filteredCurrencies.includes(r.currency)).map(r => r.currency);
    rates.sort(function (a, b) {
        return filteredCurrencies.indexOf(a) - filteredCurrencies.indexOf(b);
    });
    return rates;
}

async function downloadCurrencyExchangeRates() {
    if (currencyExchangRates !== undefined) return;

    const response = await fetch(baseUri + "/api/usecase/MarketData/CurrencyExchangRates");
    const data = await response.json();
    if (data.errors !== undefined) {
        currencyExchangRates = [{
            currency: "SEK",
            rateRelativeEuro: 10.8723,
            rateRelativeSek: 1
        }];
    }
    currencyExchangRates = data;
}

export async function getCustomerData(code: string): Promise<any> {
    const response = await fetch(baseUri + "/api/v3/customer-data/assets-liabilities?code=" + code);
    if (response.status === 200) {
        const data = await response.json();
        return data;
    }
    return undefined;
}

export async function callFinancialPlanning(
    horizonInYears: number,
    financialPlanningRequest: FinancialPlanningV3Request
)
    : Promise<InvestmentGoalPlanningResult | Response> {

    const client = new OutRankFinancialPlanningV3Api(undefined, baseUri);

    const planningResponse = await client.apiV3FinancialPlanningPost(financialPlanningRequest);

    const outcomesAtHorizon = planningResponse.percentileForecast?.length === getDefaultInvestmentGoalPlanningResult().financialPlanningResponseModel.percentileForecast?.length
        ? getDefaultInvestmentGoalPlanningResult().outcomesAtHorizon
        : {
            bad: Math.round(planningResponse.percentileForecast?.find(o => o.percentile === wideOutcomePercentiles[0])?.outcomes[horizonInYears].value * 10) / 10 ?? 0,
            median: Math.round(planningResponse.percentileForecast?.find(o => o.percentile === 0.5)?.outcomes[horizonInYears].value * 10) / 10 ?? 0,
            good: Math.round(planningResponse.percentileForecast?.find(o => o.percentile === wideOutcomePercentiles[1])?.outcomes[horizonInYears].value * 10) / 10 ?? 0
        }

    const planningResult = {
        financialPlanningResponseModel: planningResponse,
        requiredMonthlyContribution: 0,
        requiredInitialContribution: 0,
        outcomesAtHorizon: outcomesAtHorizon
    };

    return planningResult;
}

const priceIndexCache = {} as any;

// export async function priceIndex(isin: string): Promise<ITimeSeriesPoint[]> {
//     if (priceIndexCache[isin] !== undefined) {
//         return Promise.resolve(priceIndexCache[isin]);
//     }
//     else {
//         const client = new HistoricalDataController2Api(undefined, historicalUri);
//         const result = (await client.apiV2TimeSeriesMarketContractGet(isin, moment().add(-10, 'years').toDate(), moment().toDate()))?.[0];
//         priceIndexCache[isin] = result;
//         return result;
//     }
// }

export async function optimizePortfolio(allocations: InvestmentAllocationRequest[], horizonInYears: number, riskLevel: RiskLevel, scenarioSetId: string)
    : Promise<{
        allocations: InvestmentAllocationRequest[],
        statisticsOptimized: { cagr: number, volatility: number },
        statisticsCurrent: { cagr: number, volatility: number }
    }> {
    const client = new OutRankPortfolioOptimizationV3Api(undefined, baseUri);

    try {
        const investmentAllocations = normalizeWeights(allocations);
        const result = await client.apiV3PortfolioOptimizationMaxReturnPost({
            horizonInMonths: horizonInYears * 12,
            riskLevel: riskLevel,
            investmentAllocations: investmentAllocations,
            scenarioSetId: scenarioSetId
        });
        const roundingError = 1 - (result as any).optimalPortfolio.weights.map((r: any) => Math.max(r.weight, 0)).reduce((a: any, b: any) => a + b);
        const newAllocations = allocations.map(a => ({ ...a, strategyWeight: Math.max((result as any).optimalPortfolio.weights.find((r: any) => r.productCode.code === a.productCode.code).weight, 0) }));
        newAllocations[0].strategyWeight += Math.max(roundingError, 0);
        return {
            allocations: newAllocations,
            statisticsOptimized: { cagr: result.optimalPortfolio.empiricalArithmeticReturn, volatility: result.optimalPortfolio.parametricVolatilty },
            statisticsCurrent: { cagr: result.originalPortfolio.empiricalArithmeticReturn, volatility: result.originalPortfolio.parametricVolatilty }
        };

    } catch (error) {
        console.log("optimize failed");
        return {
            allocations: allocations,
            statisticsOptimized: undefined,
            statisticsCurrent: undefined
        };
    }
}


function normalizeWeights(allocations: InvestmentAllocationRequest[]) {
    const totalWeight = allocations.map(a => a.strategyWeight).reduce((a, b) => a + b);
    const investmentAllocations = allocations.map(a => ({ currentWeight: a.strategyWeight, fixedWeight: 0, productCode: a.productCode }));
    investmentAllocations[0].currentWeight += 1 - totalWeight;
    return investmentAllocations;
}

export async function getEfficientFrontier(allocations: InvestmentAllocationRequest[], horizonInYears: number, scenarioSetId: string): Promise<EfficientFrontierV3Response> {
    const client = new OutRankPortfolioOptimizationV3Api(undefined, baseUri);

    try {
        const investmentAllocations = normalizeWeights(allocations);
        const result = await client.apiV3PortfolioOptimizationEfficientFrontierPost({
            horizonInMonths: horizonInYears * 12,
            allowedInvestments: investmentAllocations.map(a => a.productCode),
            scenarioSetId: scenarioSetId
        });
        const min = result.optimalPortfolios.reduce(function(prev, curr) {
            return prev.maxReturn < curr.maxReturn ? prev : curr;
        });
        const max = result.optimalPortfolios.reduce(function(prev, curr) {
            return prev.maxReturn > curr.maxReturn ? prev : curr;
        });

        const maxVolatility = result.optimalPortfolios.find(p=>p.maxReturn === max.maxReturn).maxVolatility;
        const filteredResults = result.optimalPortfolios.filter(p=>p.maxVolatility >= min.maxVolatility && p.maxVolatility <= maxVolatility);
        return {optimalPortfolios: filteredResults};

    } catch (error) {
        console.log("getEffectiveFrontier failed");
        return undefined;
    }

}


let fundClusters = undefined as FundClusterContract[];

export async function getCluster(isin: string): Promise<ClusterFundContract[]> {
    await populateClusters();
    const funds = fundClusters
        .find(c => c.funds.find(f => f.isin === isin) !== undefined)?.funds;
    if (!funds) return;
    return funds.map(f => f)
        .filter(f => staticFundData.funds.map(s => s.isin).includes(f.isin))
        .sort((a: any, b: any) => a.rank > b.rank ? 1 : 1);
}

export const curatedClusterIsins = {
    sweSmallCap: "SE0009495633",
    global: "LU0348926527",
    emergingMarkets: "LU0345361124",
    tech: "LU1046421795",
    // usSmallCap: "LU0133096635",
    // eu: "LU2050860480",
    // japan: "LU1060955314"
};

export async function getComplementingFunds(isins: string[]): Promise<ClusterFundContract[]> {
    await populateClusters();
    const curatedClusters = fundClusters
        .filter(c => Object.values(curatedClusterIsins).some(f => c.funds.map(f => f.isin).includes(f)));
    const funds = curatedClusters
        .filter(c => c.funds.map(f => f.isin).some(isin => isins.includes(isin)) === false)
        .map(c => c.funds)
        .reduce((a, b) => a.concat(b), [])
        .sort((a, b) => a.rank > b.rank ? 1 : -1)
        .filter(f => staticFundData.funds.map(s => s.isin).includes(f.isin));
    return funds.slice(0, 5);
}

async function populateClusters() {
    if (fundClusters === undefined) {
        const client = new ClusteringApi(undefined, baseUri);
        const contexts = await client.clusteringV1ClusteringContextGet();
        const latest = contexts.filter(c => c.status === ClusterContextStatus.Published).reduce(function (p, v) {
            return (p.dateCreated > v.dateCreated ? p : v);
        });
        const clusters = await client.clusteringV1FundClusterSetGet();
        fundClusters = clusters.find(c => c.id === latest.fundClusterSetId).fundClusters;
    }
}


export async function callGap(
    targetAmount: number,
    financialPlanningRequestModel: FinancialPlanningV3Request,
    gapAccountId: string)
    : Promise<Array<GapFactorByTimeStepResponse>> {

    // const now = moment(financialPlanningRequestModel.startDate);

    // const observationDates = [...Array(31).keys()].map(index => now.clone().add(index + 1, 'years').toDate());

    const client = new OutRankFinancialPlanningV3Api(undefined, baseUri);

    const initialRequiredFundsRequest: V3FinancialplanninggapBody = {
        simulationSettings: financialPlanningRequestModel.simulationSettings,
        startDate: financialPlanningRequestModel.startDate,
        assetsAndDebts: financialPlanningRequestModel.assetsAndDebts,
        cashFlows: financialPlanningRequestModel.cashFlows,
        targetPercentile: 0.5,
        itemIdForFactorInAssetsAndDebts: gapAccountId,
        targetValue: targetAmount,
        baseCurrency: financialPlanningRequestModel.baseCurrency,
        domicile: financialPlanningRequestModel.domicile,
        dateOfBirth: financialPlanningRequestModel.dateOfBirth,
        portfolioStrategies: financialPlanningRequestModel.portfolioStrategies,
        taxInformation: financialPlanningRequestModel.taxInformation
    }

    const initialRequiredResponse = await client.apiV3FinancialPlanningGapPost(initialRequiredFundsRequest);
    return initialRequiredResponse.gapFactors;

}


export function getDefaultInvestmentGoalPlanningResult(): InvestmentGoalPlanningResult {
    const response: InvestmentGoalPlanningResult =
    {
        financialPlanningResponseModel: {
            percentileForecast: [{ percentile: wideOutcomePercentiles[1], outcomes: [{ timeStep: 0, value: 0 }] }],
            // utilityOutcomes: [{ pointInTime: moment().toDate(), utility: 0 }]
            groupResults: [],
            cashFlows: []

        },
        requiredInitialContribution: 0,
        requiredMonthlyContribution: 0,
        outcomesAtHorizon: {
            bad: 0,
            median: 0,
            good: 0
        }
    }
    return response;
}

export interface InvestmentGoalPlanningResult {
    financialPlanningResponseModel: FinancialPlanningV3Response;
    requiredInitialContribution: number;
    requiredMonthlyContribution: number;
    outcomesAtHorizon: OutcomesAtHorizon;
}

export interface OutcomesAtHorizon {
    bad: number;
    median: number;
    good: number;
}

export interface SweIncomePayment {
    dateOfPayment: Date;
    grossMonthlySalary: number;
    grossMonthlySickLeaveIncome: number;
    netMonthlySickLeaveIncome: number;
    netTotalIncome: number;
}

export interface OneTimeContribution {
    dateOfPayment: Date;
    amount: number;
}


