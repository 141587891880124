/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Clustering API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ClusterContextStatus {
    Created = <any> 'Created',
    Published = <any> 'Published',
    Deleted = <any> 'Deleted',
    Archived = <any> 'Archived'
}
/**
 * 
 * @export
 * @interface ClusterFundContract
 */
export interface ClusterFundContract {
    /**
     * 
     * @type {string}
     * @memberof ClusterFundContract
     */
    isin?: string;
    /**
     * 
     * @type {number}
     * @memberof ClusterFundContract
     */
    rank?: number;
}
/**
 * 
 * @export
 * @interface ClusteringContextRequest
 */
export interface ClusteringContextRequest {
    /**
     * 
     * @type {string}
     * @memberof ClusteringContextRequest
     */
    contractUniverseId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusteringContextRequest
     */
    fundClusterSetId?: string;
}
/**
 * 
 * @export
 * @interface ClusteringContextResponse
 */
export interface ClusteringContextResponse {
    /**
     * 
     * @type {string}
     * @memberof ClusteringContextResponse
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ClusteringContextResponse
     */
    dateCreated?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClusteringContextResponse
     */
    contractUniverseId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClusteringContextResponse
     */
    fundClusterSetId?: string;
    /**
     * 
     * @type {ClusterContextStatus}
     * @memberof ClusteringContextResponse
     */
    status?: ClusterContextStatus;
}
/**
 * 
 * @export
 * @interface ClusteringContextSetStatusRequest
 */
export interface ClusteringContextSetStatusRequest {
    /**
     * 
     * @type {ClusterContextStatus}
     * @memberof ClusteringContextSetStatusRequest
     */
    status?: ClusterContextStatus;
}
/**
 * 
 * @export
 * @interface ClusteringcontextSetstatusBody
 */
export interface ClusteringcontextSetstatusBody extends ClusteringContextSetStatusRequest {
}
/**
 * 
 * @export
 * @interface ClusteringcontextSetstatusBody1
 */
export interface ClusteringcontextSetstatusBody1 extends ClusteringContextSetStatusRequest {
}
/**
 * 
 * @export
 * @interface ClusteringcontextSetstatusBody2
 */
export interface ClusteringcontextSetstatusBody2 extends ClusteringContextSetStatusRequest {
}
/**
 * 
 * @export
 * @interface ClusteringcontextSetstatusBody3
 */
export interface ClusteringcontextSetstatusBody3 extends ClusteringContextSetStatusRequest {
}
/**
 * 
 * @export
 * @interface FundClusterContract
 */
export interface FundClusterContract {
    /**
     * 
     * @type {Array<ClusterFundContract>}
     * @memberof FundClusterContract
     */
    funds?: Array<ClusterFundContract>;
}
/**
 * 
 * @export
 * @interface FundClusterSetRequest
 */
export interface FundClusterSetRequest {
    /**
     * 
     * @type {string}
     * @memberof FundClusterSetRequest
     */
    clusterRunId?: string;
    /**
     * 
     * @type {Array<FundClusterContract>}
     * @memberof FundClusterSetRequest
     */
    fundClusters?: Array<FundClusterContract>;
    /**
     * 
     * @type {Date}
     * @memberof FundClusterSetRequest
     */
    dateCreated?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof FundClusterSetRequest
     */
    isinsNotClustered?: Array<string>;
}
/**
 * 
 * @export
 * @interface FundClusterSetResponse
 */
export interface FundClusterSetResponse {
    /**
     * 
     * @type {string}
     * @memberof FundClusterSetResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FundClusterSetResponse
     */
    clusterRunId?: string;
    /**
     * 
     * @type {Date}
     * @memberof FundClusterSetResponse
     */
    dateCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FundClusterSetResponse
     */
    dateUploaded?: Date;
    /**
     * 
     * @type {Array<FundClusterContract>}
     * @memberof FundClusterSetResponse
     */
    fundClusters?: Array<FundClusterContract>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FundClusterSetResponse
     */
    isinsNotClustered?: Array<string>;
}
/**
 * 
 * @export
 * @interface V1ClusteringcontextBody
 */
export interface V1ClusteringcontextBody extends ClusteringContextRequest {
}
/**
 * 
 * @export
 * @interface V1ClusteringcontextBody1
 */
export interface V1ClusteringcontextBody1 extends ClusteringContextRequest {
}
/**
 * 
 * @export
 * @interface V1ClusteringcontextBody2
 */
export interface V1ClusteringcontextBody2 extends ClusteringContextRequest {
}
/**
 * 
 * @export
 * @interface V1ClusteringcontextBody3
 */
export interface V1ClusteringcontextBody3 extends ClusteringContextRequest {
}
/**
 * 
 * @export
 * @interface V1FundclustersetBody
 */
export interface V1FundclustersetBody extends FundClusterSetRequest {
}
/**
 * 
 * @export
 * @interface V1FundclustersetBody1
 */
export interface V1FundclustersetBody1 extends FundClusterSetRequest {
}
/**
 * 
 * @export
 * @interface V1FundclustersetBody2
 */
export interface V1FundclustersetBody2 extends FundClusterSetRequest {
}
/**
 * 
 * @export
 * @interface V1FundclustersetBody3
 */
export interface V1FundclustersetBody3 extends FundClusterSetRequest {
}
/**
 * ClusteringApi - fetch parameter creator
 * @export
 */
export const ClusteringApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1ClusteringContextGet(options: any = {}): FetchArgs {
            const localVarPath = `/clustering/v1/clustering-context`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {V1ClusteringcontextBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1ClusteringContextPost(body?: V1ClusteringcontextBody, options: any = {}): FetchArgs {
            const localVarPath = `/clustering/v1/clustering-context`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1ClusteringcontextBody3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClusteringcontextSetstatusBody} [body] 
         * @param {string} [clusteringContextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1ClusteringContextSetStatusPatch(body?: ClusteringcontextSetstatusBody, clusteringContextId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/clustering/v1/clustering-context/set-status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (clusteringContextId !== undefined) {
                localVarQueryParameter['clusteringContextId'] = clusteringContextId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ClusteringcontextSetstatusBody3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1FundClusterSetGet(options: any = {}): FetchArgs {
            const localVarPath = `/clustering/v1/fund-cluster-set`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {V1FundclustersetBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1FundClusterSetPost(body?: V1FundclustersetBody, options: any = {}): FetchArgs {
            const localVarPath = `/clustering/v1/fund-cluster-set`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V1FundclustersetBody3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClusteringApi - functional programming interface
 * @export
 */
export const ClusteringApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1ClusteringContextGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ClusteringContextResponse>> {
            const localVarFetchArgs = ClusteringApiFetchParamCreator(configuration).clusteringV1ClusteringContextGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {V1ClusteringcontextBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1ClusteringContextPost(body?: V1ClusteringcontextBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = ClusteringApiFetchParamCreator(configuration).clusteringV1ClusteringContextPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ClusteringcontextSetstatusBody} [body] 
         * @param {string} [clusteringContextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1ClusteringContextSetStatusPatch(body?: ClusteringcontextSetstatusBody, clusteringContextId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ClusteringApiFetchParamCreator(configuration).clusteringV1ClusteringContextSetStatusPatch(body, clusteringContextId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1FundClusterSetGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FundClusterSetResponse>> {
            const localVarFetchArgs = ClusteringApiFetchParamCreator(configuration).clusteringV1FundClusterSetGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {V1FundclustersetBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1FundClusterSetPost(body?: V1FundclustersetBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = ClusteringApiFetchParamCreator(configuration).clusteringV1FundClusterSetPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ClusteringApi - factory interface
 * @export
 */
export const ClusteringApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1ClusteringContextGet(options?: any) {
            return ClusteringApiFp(configuration).clusteringV1ClusteringContextGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {V1ClusteringcontextBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1ClusteringContextPost(body?: V1ClusteringcontextBody, options?: any) {
            return ClusteringApiFp(configuration).clusteringV1ClusteringContextPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ClusteringcontextSetstatusBody} [body] 
         * @param {string} [clusteringContextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1ClusteringContextSetStatusPatch(body?: ClusteringcontextSetstatusBody, clusteringContextId?: string, options?: any) {
            return ClusteringApiFp(configuration).clusteringV1ClusteringContextSetStatusPatch(body, clusteringContextId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1FundClusterSetGet(options?: any) {
            return ClusteringApiFp(configuration).clusteringV1FundClusterSetGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {V1FundclustersetBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusteringV1FundClusterSetPost(body?: V1FundclustersetBody, options?: any) {
            return ClusteringApiFp(configuration).clusteringV1FundClusterSetPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * ClusteringApi - object-oriented interface
 * @export
 * @class ClusteringApi
 * @extends {BaseAPI}
 */
export class ClusteringApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringApi
     */
    public clusteringV1ClusteringContextGet(options?: any) {
        return ClusteringApiFp(this.configuration).clusteringV1ClusteringContextGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {V1ClusteringcontextBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringApi
     */
    public clusteringV1ClusteringContextPost(body?: V1ClusteringcontextBody, options?: any) {
        return ClusteringApiFp(this.configuration).clusteringV1ClusteringContextPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ClusteringcontextSetstatusBody} [body] 
     * @param {string} [clusteringContextId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringApi
     */
    public clusteringV1ClusteringContextSetStatusPatch(body?: ClusteringcontextSetstatusBody, clusteringContextId?: string, options?: any) {
        return ClusteringApiFp(this.configuration).clusteringV1ClusteringContextSetStatusPatch(body, clusteringContextId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringApi
     */
    public clusteringV1FundClusterSetGet(options?: any) {
        return ClusteringApiFp(this.configuration).clusteringV1FundClusterSetGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {V1FundclustersetBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusteringApi
     */
    public clusteringV1FundClusterSetPost(body?: V1FundclustersetBody, options?: any) {
        return ClusteringApiFp(this.configuration).clusteringV1FundClusterSetPost(body, options)(this.fetch, this.basePath);
    }

}
