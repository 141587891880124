import moment from "moment";
import { AssetsAndDebtsRequest, AssetsAnddebtsItemGroupRequest, Frequency, CashFlowsRequest, InflationAdjustmentContract, InvestmentAccountRequest, MortgageRequest, ProductCodeContract, RealEstateRequest, TransactionAccountRequest, SweOccupationalPensionAccountRequest, SweNationalPensionRequest, PayoutSchemeRequest, RiskLevel, CashFlowResponse, WaterfallAllocationStrategyRequest } from "../api_client/index";
import { rallyPurple } from "../theming/useAppColors";

export interface Portfolio {
    name: string;
    startDate?: Date;
    dateOfBirth?: Date;
    /**
     * 
     * @type {AssetsAndDebts}
     */
    assetsAndDebts: AssetsAndDebtsRequest;

    assetsAndDebtsItemGroups?: Array<GroupVM>;
    /**
     * 
     * @type {IncomesAndExpensesRequest}
     */
    transactions: TransactionVM[];
    taxPaymentsAccountId: string;
    waterfallAllocationStrategies?: WaterfallAllocationStrategyVM[];
}

export interface WaterfallAllocationStrategyVM extends WaterfallAllocationStrategyRequest  {
    name: string;
    displayState: DisplayState;
}

export interface InvestmentAccountVM extends InvestmentAccountRequest, AssetsAndDebtsItemVM, ItemWithAllocationsVM {
}

export interface OccupationalPensionAccountVM extends SweOccupationalPensionAccountRequest, AssetsAndDebtsItemVM, ItemWithAllocationsVM {
    payoutStartUnit: Frequency;
    payoutPeriodUnit: Frequency;
}


export interface NationalPensionVM extends SweNationalPensionRequest, AssetsAndDebtsItemVM, ItemWithAllocationsVM {

}


export interface TransactionAccountVM extends TransactionAccountRequest, AssetsAndDebtsItemVM {
}

export interface MortgageVM extends MortgageRequest, AssetsAndDebtsItemVM {
    durationUnit: Frequency;
}

export interface RealEstateVM extends RealEstateRequest, AssetsAndDebtsItemVM {
}

export interface ProductCodeVM extends ProductCodeContract {
    name: string;
}

export interface AssetsAndDebtsItemVM {
    name: string;
    displayState?: DisplayState;
    enabled?: boolean;
    color?: string;
}

export interface ItemWithAllocationsVM {
    // deriveWeightsFromCurrentValue?: boolean;
    weightCalculationType?: WeightCalculationType;
    optimizationHorizonInYears?: number;
    optimizationRiskLevel?: RiskLevel;
}

export enum WeightCalculationType {
    DeriveWeightsFromCurrentValue,
    Optimized,
    NoCalculation
}


export enum DisplayState {
    Display,
    Edit,
    AddNew
}

export interface PayoutSchemeVM extends PayoutSchemeRequest {
    ageOfRetirement: number;
}

export interface GroupVM extends AssetsAnddebtsItemGroupRequest {
    name: string;
    color?: string;
    isExpanded: boolean;
}

export interface TransactionVM {
    id: string;
    name: string;
    category: TransactionCategory;
    type: TransactionType;
    amount: number;
    startDate: Date;
    frequency: Frequency;
    duration: number;
    durationUnit: Frequency;
    isPercentage: boolean;
    inflationAdjustment?: InflationAdjustmentContract;
    sourceId: string;
    targetId: string;
    displayState?: DisplayState;
    occupationalPensionAgreement: OccupationalPensionAgreement;
}

export interface OccupationalPensionAgreement {
    type: OccupationalPensionAgreementType,
    accountId: string,
    percentage: number
}

export enum OccupationalPensionAgreementType {
    NoAgreement,
    ITP1,
    PercentageOfSalary
}

export enum TransactionType {
    OneTime,
    Recurring
}

export enum TransactionCategory {
    Expense = 1, // only source
    TaxExemptIncome = 2, // only target
    IntraAccountTransaction = 3, // target/source
    EarnedIncome = 4, // only target
    DividendsK10 = 5, // only target
    DividendsK12 = 6, // only target
}

export function getAccounts(assetsAndDebts: AssetsAndDebtsRequest): { name: string, id: string, enabled: boolean }[] {
    const accounts = assetsAndDebts?.transactionAccounts.map((a) => ({ name: (a as TransactionAccountVM).name, id: a.id, enabled: (a as TransactionAccountVM).enabled === undefined ? true : (a as TransactionAccountVM).enabled }))
        .concat(assetsAndDebts?.investmentAccounts.map((a) => ({ name: (a as InvestmentAccountVM).name, id: a.id, enabled: (a as InvestmentAccountVM).enabled === undefined ? true : (a as InvestmentAccountVM).enabled })))
        .concat(assetsAndDebts?.realEstate.map((a) => ({ name: (a as RealEstateVM).name, id: a.id, enabled: (a as RealEstateVM).enabled === undefined ? true : (a as RealEstateVM).enabled })))
        .concat(assetsAndDebts?.pensionAccounts?.sweOccupationalPensionAccounts?.map((a) => ({ name: (a as OccupationalPensionAccountVM).name, id: a.id, enabled: (a as OccupationalPensionAccountVM).enabled === undefined ? true : (a as OccupationalPensionAccountVM).enabled })))
        .concat(assetsAndDebts?.pensionAccounts?.sweNationalPension?.incomePensionAccount !== undefined ? [
            {
                name: 'National income pension',
                id: (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).incomePensionAccount.id,
                enabled: (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).enabled === undefined ? true : (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).enabled
            },
            {
                name: 'National premium pension',
                id: (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).premiumPensionAccount.id,
                enabled: (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).enabled === undefined ? true : (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).enabled
            }
        ] : [])
        .concat(assetsAndDebts?.mortgages.map((a) => ({ name: (a as MortgageVM).name, id: a.id, enabled: (a as MortgageVM).enabled === undefined ? true : (a as MortgageVM).enabled })));

    return accounts;

}

export enum AccountType {
    InvestementAccount,
    TransactionAccount,
    OccupationalPensionAccount,
    IncomePensionAccount,
    PremiumPensionAccount,
    Mortgage,
    RealEstate
}

export function getAccountsWithSetFunction(
    assetsAndDebts: AssetsAndDebtsRequest,
    setAssetsAndDebts: (assetsAndDebts: AssetsAndDebtsRequest) => void
): { name: string, id: string, type: AccountType, color?: string, enabled: boolean, setEnabled: (enabled: boolean) => void }[] {
    const accounts = assetsAndDebts?.transactionAccounts.map((a, i) => ({
        name: (a as TransactionAccountVM).name,
        id: a.id,
        type: AccountType.TransactionAccount,
        color: (a as TransactionAccountVM).color,
        enabled: (a as TransactionAccountVM).enabled === undefined ? true : (a as TransactionAccountVM).enabled,
        setEnabled: (enabled: boolean) => setAssetsAndDebts({ ...assetsAndDebts, transactionAccounts: assetsAndDebts.transactionAccounts.map((aa, ii) => i === ii ? { ...a, enabled: enabled } : aa) })
    }))
        .concat(assetsAndDebts?.investmentAccounts.map((a, i) => ({
            name: (a as InvestmentAccountVM).name,
            id: a.id,
            type: AccountType.InvestementAccount,
            color: (a as InvestmentAccountVM).color,
            enabled: (a as InvestmentAccountVM).enabled === undefined ? true : (a as InvestmentAccountVM).enabled,
            setEnabled: (enabled: boolean) => setAssetsAndDebts({ ...assetsAndDebts, investmentAccounts: assetsAndDebts.investmentAccounts.map((aa, ii) => i === ii ? { ...a, enabled: enabled } : aa) })
        })))
        .concat(assetsAndDebts?.realEstate.map((a, i) => ({
            name: (a as RealEstateVM).name,
            id: a.id,
            type: AccountType.RealEstate,
            color: (a as RealEstateVM).color,
            enabled: (a as RealEstateVM).enabled === undefined ? true : (a as RealEstateVM).enabled,
            setEnabled: (enabled: boolean) => setAssetsAndDebts({ ...assetsAndDebts, realEstate: assetsAndDebts.realEstate.map((aa, ii) => i === ii ? { ...a, enabled: enabled } : aa) })
        })))
        .concat(assetsAndDebts?.pensionAccounts?.sweNationalPension?.incomePensionAccount !== undefined ? [
            {
                name: 'National income pension',
                id: (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).incomePensionAccount.id,
                type: AccountType.IncomePensionAccount,
                color: (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).color,
                enabled: (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).enabled === undefined ? true : (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).enabled,
                setEnabled: (enabled: boolean) => setAssetsAndDebts({ ...assetsAndDebts, pensionAccounts: { ...assetsAndDebts.pensionAccounts, sweNationalPension: { ...assetsAndDebts?.pensionAccounts?.sweNationalPension, enabled: enabled } as NationalPensionVM } })
            },
            {
                name: 'National premium pension',
                id: (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).premiumPensionAccount.id,
                type: AccountType.PremiumPensionAccount,
                color: rallyPurple[100],
                enabled: (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).enabled === undefined ? true : (assetsAndDebts?.pensionAccounts?.sweNationalPension as NationalPensionVM).enabled,
                setEnabled: (enabled: boolean) => setAssetsAndDebts({ ...assetsAndDebts, pensionAccounts: { ...assetsAndDebts.pensionAccounts, sweNationalPension: { ...assetsAndDebts?.pensionAccounts?.sweNationalPension, enabled: enabled } as NationalPensionVM } })
            }
        ] : [])
        .concat(assetsAndDebts?.pensionAccounts?.sweOccupationalPensionAccounts.map((a, i) => ({
            name: (a as OccupationalPensionAccountVM).name,
            id: a.id,
            type: AccountType.OccupationalPensionAccount,
            color: (a as OccupationalPensionAccountVM).color,
            enabled: (a as OccupationalPensionAccountVM).enabled === undefined ? true : (a as OccupationalPensionAccountVM).enabled,
            setEnabled: (enabled: boolean) => setAssetsAndDebts({ ...assetsAndDebts, pensionAccounts: { ...assetsAndDebts.pensionAccounts, sweOccupationalPensionAccounts: assetsAndDebts.pensionAccounts.sweOccupationalPensionAccounts.map((aa, ii) => i === ii ? { ...a, enabled: enabled } : aa) } })
        })))
        .concat(assetsAndDebts?.mortgages.map((a, i) => ({
            name: (a as MortgageVM).name,
            id: a.id,
            type: AccountType.Mortgage,
            color: (a as MortgageVM).color,
            enabled: (a as MortgageVM).enabled === undefined ? true : (a as MortgageVM).enabled,
            setEnabled: (enabled: boolean) => setAssetsAndDebts({ ...assetsAndDebts, mortgages: assetsAndDebts.mortgages.map((aa, ii) => i === ii ? { ...a, enabled: enabled } : aa) })
        })));
    return accounts === undefined ? [] : accounts.filter(a=> a !== undefined);

}

export function defaultPortfolio(): Portfolio {

    const startDate = new Date(moment().year(), moment().month());
    const dateOfBirth = new Date(moment().add(-40, 'years').year(), moment().month());
    return {
        name: 'New Portfolio',
        startDate: startDate,
        dateOfBirth: dateOfBirth,
        assetsAndDebts: {
            investmentAccounts: [],
            transactionAccounts: [],
            realEstate: [],
            mortgages: [],
            pensionAccounts: {
                // sweNationalPension: {}, 
                sweOccupationalPensionAccounts: []
            },
            unlistedShares: []
        },
        transactions: [],
        taxPaymentsAccountId: ''
    };
}
