import React from 'react';
import { Portfolio } from '../financialPlanning/Portfolio';

export const PortfolioContext = React.createContext({
    portfolios: [] as Portfolio[], 
    setPortfolios: (newVal: Portfolio[]) => {},
    setPortfolios2: (mutator: (currentPortfolios:Portfolio[]) => void) => {},
    activePortfolioIndex: 0,
    setActivePortfolioIndex: (newVal: number) => {},
    referencePortfolioIndex: 1,
    setReferencePortfolioIndex: (newVal: number) => {},
    isLoaded: false
});

