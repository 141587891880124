import React, { Fragment, useRef, useState } from 'react';
import { ReferenceLine, Area, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend, Tooltip, ComposedChart, Bar, Scatter, ScatterChart } from 'recharts';
// import { wideOutcomePercentiles, mediumOutcomePercentiles, narrowOutcomePercentiles } from './appConstants';
import { useAppColors } from '../theming/useAppColors';
import { TextNumberFormat } from '../NumberFormatCustom'
import { Alert, IconButton, Paper, Snackbar, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { EfficientFrontierV3Response, GroupResultForecastResponse } from '../api_client';
import moment from 'moment';
import { StackedLineChart, BarChart, OpenInFull, Screenshot } from '@mui/icons-material';

function EfficientFrontierChart(props: {
    height: any,
    efficientFrontier: EfficientFrontierV3Response,
    currentPortfolio: { cagr: number, volatility: number } | undefined,
    showLedgend?: boolean,
    showXAxis?: boolean,
    setEffectiveFrontierChartDialogOpen?: (val: any) => void
}) {

    const { height, showLedgend, showXAxis, setEffectiveFrontierChartDialogOpen, efficientFrontier, currentPortfolio } = props;
    const colors = useAppColors();
    const theme = useTheme();
    const isFullScreen = setEffectiveFrontierChartDialogOpen === undefined;
    // const fontSize = isFullScreen ? 12 : 10;
    const fontSize = 10;


    const CustomizedAxisTick = (props: any) => {
        const {
            x, y, payload,
        } = props;

        return (
            <>
                <TextNumberFormat hidePrefix roundToNearest={0.001} value={payload.value} style={{ fontSize: fontSize }}
                    renderText={(value: any) =>
                        <g transform={`translate(${x},${y})`}>
                            <text x={0} y={0} dy={2} textAnchor="end" style={{ fontSize: fontSize }} fill={colors.chart.axisLines} >{value}</text>
                        </g>
                    }
                />

            </>
        );
    }

    const CustomTooltip = ({ active, payload, label }: any) => {

        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <Paper style={{ padding: 4, opacity: 0.9 }} square>
                        <Typography variant='body2'>
                            <>
                                {payload?.[0].name}: {payload?.[0].value}<br />
                                {payload?.[1].name}: {payload?.[1].value}<br />
                            </>
                        </Typography>
                    </Paper>
                </div>
            );
        }

        return null;
    };


    const optimalPortfolios = efficientFrontier?.optimalPortfolios.map(p => ({ x: Math.round(p.maxVolatility * 10000) / 10000, y: Math.round(p.maxReturn * 10000) / 10000 }))
    // optimalPortfolios?.sort((a, b) => a.y < b.y ? -1 : a.y > b.y ? 1 : 0);

    // const optimalPortfolios = [
    //     {
    //       "x": 70,
    //       "y": 20,
    //     },
    //     {
    //       "x": 50,
    //       "y": 30,
    //     },
    //     {
    //       "x": 40,
    //       "y": 40,
    //     },
    //     {
    //       "x": 50,
    //       "y": 50,
    //     },
    //     {
    //       "x": 100,
    //       "y": 60,
    //     },
    //     {
    //       "x": 150,
    //       "y": 70,
    //     }
    //   ];
    const currentPortfolioData = [
        {
            "x": currentPortfolio?.volatility,
            "y": currentPortfolio?.cagr,
        }
    ];
    return (
        <div style={{ height: height !== undefined ? height : '14rem' }}>
            {efficientFrontier ?
                <><Paper style={{ boxShadow: "none", display: 'inline-block', marginTop: theme.spacing(0), marginLeft: theme.spacing(1), padding: '2px' }} >
                    {!isFullScreen ?
                        <IconButton onClick={() => setEffectiveFrontierChartDialogOpen(true)} size='small' style={{ marginRight: 2 }}>
                            <OpenInFull fontSize='small' />
                        </IconButton> : <></>}
                </Paper>
                    <ResponsiveContainer width="100%" height="100%">
                        <ScatterChart margin={{
                            top: 10, right: 20, left: 23, bottom: 30,
                        }}>
                            <CartesianGrid strokeOpacity={0.3} vertical={false} />
                            <Tooltip content={<CustomTooltip />} />
                            {showXAxis ? <XAxis name='Standard deviation' tick={{ fontSize: fontSize, strokeWidth: 0, fill: colors.chart.axisLines }} interval={'preserveStartEnd'} dataKey="x" type="number" /> : <></>}
                            <YAxis name='Expected return' width={10} dataKey="y" tick={{ fontSize: fontSize, strokeWidth: 0, fill: colors.chart.axisLines }} interval={'preserveStartEnd'} type="number" />
                            <Scatter name="A school" lineType='joint' line={{ stroke: colors.fanChart.narrow, strokeWidth: 2, strokeOpacity: 0.5 }} lineJointType='natural' data={optimalPortfolios} stroke={'#fff'} strokeOpacity={0.7} fill={colors.effectiveFrontier.optimalPortfolio} />
                            <Scatter name="B school" data={currentPortfolioData} fill={colors.effectiveFrontier.currentPortfolio} stroke={'#fff'} strokeOpacity={0.7} />
                            {/* <Area animationDuration={500} type="monotone" dataKey="firstGroup" stackId="1" strokeOpacity={0.3} stroke="#333333" fillOpacity={1} fill="#1a6b9d" />
                <Area animationDuration={500} type="monotone" dataKey="secondGroup" stackId="1" strokeOpacity={0.3} stroke="#333333" fillOpacity={1} fill="#8fc1d9" /> */}
                            {/* <ReferenceLine stroke="#333333" strokeDasharray="3 3" y={0} /> */}
                            {/* {showLedgend ? <Legend iconType='square' align='center' layout='vertical' verticalAlign='bottom'></Legend> : <></>} */}
                            {/* {showLedgend ? <Legend layout='vertical' iconType='square' content={renderLegend} align='center' verticalAlign='bottom'></Legend> : <></>} */}
                            {/* <Tooltip /> */}
                        </ScatterChart>
                    </ResponsiveContainer></>
                : <></>}
        </div>);
}
export function areEqual(prevProps: any, nextProps: any) {
    var prevProps2 = Object.assign({}, prevProps);
    delete prevProps2.targetLabel;

    var nextProps2 = Object.assign({}, nextProps);
    delete nextProps2.targetLabel;

    if (JSON.stringify(prevProps2) === JSON.stringify(nextProps2)) {

        return true;
    }
    return false;
}

export default React.memo(EfficientFrontierChart, areEqual);
