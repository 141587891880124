import React, { useContext, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
// import withStyles from '@mui/styles/withStyles';
// import makeStyles from '@mui/styles/makeStyles';
import { Snackbar, Grid, TextField, Button, List, ListItem, ListItemText, Typography, ListItemSecondaryAction, Switch, ListItemIcon, Collapse, IconButton, ListSubheader, Paper, Slider, Tooltip, Dialog, DialogTitle, DialogActions, Fade, Fab, Box, Link, Alert, AlertTitle, AppBar, Toolbar, Drawer, Menu, MenuItem } from '@mui/material';
import { CheckCircleTwoTone, NotificationImportantTwoTone, WarningTwoTone, ExpandLess, ExpandMore, Delete, Add, ArrowBack, Lightbulb, LightbulbTwoTone, Timeline } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import PercentileFanChart from '../visualization/PercentileFanChart';
import { createInvestmentAccount, createRequest } from './MultiGoalRequestFactory';
import {
    getMappedFunds, callFinancialPlanning, getDefaultInvestmentGoalPlanningResult, InvestmentGoalPlanningResult, OutcomesAtHorizon,
    // , investmentGoalPlanning, 
    callGap
} from '../api_client/tsClient';
import { useAppColors } from '../theming/useAppColors';
import { InflationAdjustmentContract, InvestmentAccountTaxationType, ProductCodeContract } from '../api_client';
import { TextNumberFormat } from '../NumberFormatCustom'
// import PensionAdviceAppBar from './PensionAdviceAppBar';
// import { styleDelegate } from './useStyles';
import { SettingsContext } from '../settings/SettingsContext';
import { useDebounce } from '../hooks/useDebounce';
import SliderInput from './SliderInput';
import AppMenu from '../AppMenu';
import GoalChart from './GoalChart';
import { ProductCodeVM } from '../financialPlanning/Portfolio';

export default function HayahDemo(props: any) {

    const theme = useTheme();
    const settings = useContext(SettingsContext);

    const [monthlyContribution, setMonthlyContribution] = useState(100);
    const [contributionHorizon, setContributionHorizon] = useState(30);
    const [selectedFund, setSelectedFund] = useState<ProductCodeVM | null>(null);
    const [mappedFunds, setMappedFunds] = useState<ProductCodeContract[] | null>([]);
    const [executeButtonText, setExecuteButtonText] = useState('Start saving');
    const [resultState, setResultState] = useState<{ forecast: InvestmentGoalPlanningResult | undefined, referenceForecast: InvestmentGoalPlanningResult | undefined }>({ forecast: getDefaultInvestmentGoalPlanningResult(), referenceForecast: getDefaultInvestmentGoalPlanningResult() });
    const portfolioDevelopmentTimeSeries = resultState?.forecast?.financialPlanningResponseModel.percentileForecast;

    const [probabilities, setProbabilities] = useState([0]);


    const [monthlyContributionTooltipOpen, setMonthlyContributionTooltipOpen] = useState(false);
    const [fundTooltipOpen, setFundTooltipOpen] = useState(false);
    const [onboardingOpen, setOnboardingOpen] = React.useState(true);

    const [goals, setGoals] = useState([
        // {
        //   id: 1,
        //   name: 'Save for business',
        //   type: 'OneTime',
        //   horizonInYears: 10,
        //   durationInYears: 0,
        //   amount: 120000,
        //   chosenMonthlyContribution: 0,
        //   enabled: true,
        //   editorOpen: false
        // },
        // {
        //   id: 2,
        //   name: 'Retirement',
        //   type: 'Recurring',
        //   horizonInYears: 20,
        //   durationInYears: 20,
        //   amount: 45000,
        //   chosenMonthlyContribution: 0,
        //   enabled: true
        //   editorOpen: false
        // }
    ]);

    const medianOutcome = resultState.forecast?.financialPlanningResponseModel?.percentileForecast?.find((p: any) => p.percentile === 0.5);
    const outcomesAtHorizon: OutcomesAtHorizon | undefined = resultState.forecast?.outcomesAtHorizon;
    const notificationStatus = goals.length === 0 ? 'nogoal' : medianOutcome === undefined ? 'ok' : Math.min(...medianOutcome.outcomes.map((o: any) => o.value)) < 0 ? 'notify' : 'ok';

    const [addGoalOpen, setAddGoalOpen] = useState(false);

    const handleAddGoalClickOpen = () => {
        setAddGoalOpen(true);
    };

    const handleAddGoalClose = () => {
        setAddGoalOpen(false);
    };

    const handleAddGoalDelete = (id: number) => {
        const handler = () => {
            const newGoals = goals.filter(g => g.id !== id).map(a => { return { ...a } });
            setGoals(newGoals);
        }
        return handler;
    };


    const handleOnboardingClose = () => {
        setTimeout(() => {
            setFundTooltipOpen(true);
        }, 500);
        setTimeout(() => {
            setFundTooltipOpen(false);
            setMonthlyContributionTooltipOpen(true);
        }, 4000);
        setTimeout(() => {
            setMonthlyContributionTooltipOpen(false);
        }, 8000);
        setOnboardingOpen(false);
    };

    const handleEnable = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        const newGoals = goals.map(a => { return { ...a } });
        newGoals.find(g => g.id === id).enabled = event.target.checked;
        setGoals(newGoals);
    };

    const debouncedMonthlyContributions = useDebounce(monthlyContribution, 800);
    const debouncedContributionHorizon = useDebounce(contributionHorizon, 800);
    const debouncedGoals = useDebounce(goals, 800);

    useEffect(() => {
        if (settings.currentScenarioSet == null || selectedFund == null)
            return;
        // setNumberOfRequestInFlight(state => state + 1);
        const request = createRequest(
            settings.currentScenarioSet.id,
            0,
            selectedFund,
            debouncedMonthlyContributions,
            debouncedContributionHorizon,
            "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            debouncedGoals);
        callFinancialPlanning(
            30,
            request)
            .then(result => {
                try {
                    setResultState({ forecast: result as InvestmentGoalPlanningResult, referenceForecast: undefined });

                    const gapRequest = createRequest(
                        settings.currentScenarioSet.id,
                        0,
                        selectedFund,
                        debouncedMonthlyContributions,
                        debouncedContributionHorizon,
                        "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        debouncedGoals);

                    callGap(
                        0,
                        gapRequest,
                        "3fa85f64-5717-4562-b3fc-2c963f66afa6")
                        .then(result => {
                            try {
                                setProbabilities(result.map(r => r.probabilityOfReachingTargetUsingExistingFunds));
                            } finally {

                                // setNumberOfRequestInFlight(state => state - 1);
                            }
                        });

                } finally {
                    // setNumberOfRequestInFlight(state => state - 1);
                }
            });
    }, [settings.currentScenarioSet, selectedFund, debouncedGoals, debouncedMonthlyContributions, debouncedContributionHorizon])

    const adjustGap = () => {
        if (settings.currentScenarioSet == null || selectedFund == null)
            return;
        // setNumberOfRequestInFlight(state => state + 1);
        const fixedAmount = 10000;
        const gapAccountId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
        const request = createRequest(
            settings.currentScenarioSet.id,
            0,
            selectedFund,
            fixedAmount,
            contributionHorizon,
            gapAccountId,
            goals as any);

        callGap(
            0,
            request,
            gapAccountId)
            .then(result => {
                try {
                    setMonthlyContribution(Math.ceil(Math.ceil(Math.max(...(result.map(r => r.factor))) * 100000) / 100000 * fixedAmount));
                } finally {
                    // setNumberOfRequestInFlight(state => state - 1);
                }
            });
    }

    useEffect(() => {
        // setNumberOfRequestInFlight(state => state + 1);
        if (settings.currentScenarioSet.id === '')
            return;

        getMappedFunds(settings.currentScenarioSet.id)
            .then(result => {
                try {
                    setMappedFunds(result);
                    setSelectedFund(result[0]);
                } finally {
                    // setNumberOfRequestInFlight(state => state - 1);
                }
            });
    }, [settings])

    const optionsFilter = (options: any, state: any) => {
        return options.filter((o: any) => o.name.toLowerCase().includes(state.inputValue.toLowerCase()));
    };

    const updateGoal = (goal: any) => {
        const newGoals = goals.map(a => { return { ...a } });
        const goalIndex = newGoals.findIndex(g => g.id === goal.id);
        newGoals[goalIndex] = goal;
        setGoals(newGoals);
    }

    const renderGoals = goals.map(goal => {
        
        const probability = probabilities.length > 1 && goal.enabled ? `${Math.round(probabilities[goal.horizonInYears] * 100)}%` : '-';
        return (<div key={goal.id}>
            {/* <ListItem> */}
            <Grid container>
                <Grid item xs='auto' sm='auto'>
                    <ListItemIcon style={{ paddingRight: theme.spacing(2) }}>
                        <Switch color='primary' onChange={(event) => handleEnable(event, goal.id)} checked={goal.enabled} />
                    </ListItemIcon>
                </Grid>
                <Grid item xs sm={4}>
                    <Typography variant='body1'>{goal.name}</Typography>
                    <Typography variant='body2' color={'text.secondary'}>{goal.horizonInYears + " years"}</Typography>
                </Grid>
                <Grid item xs={2} sm>
                    <Typography variant='body1'>Amount</Typography>
                    <Typography variant='body2' color={'text.secondary'}>{<TextNumberFormat decimals={0} value={goal.amount} />}</Typography>
                </Grid>
                <Grid item xs='auto' sm sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Box>
                        <Typography variant='body1'>Probability</Typography>
                        <Typography variant='body2' color={'text.secondary'}>{probability}</Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <div style={{ paddingLeft: theme.spacing(1) }}>
                        <IconButton onClick={() => { updateGoal({ ...goal, editorOpen: !goal.editorOpen }) }}>{goal.editorOpen ? <ExpandLess /> : <ExpandMore />}</IconButton>
                        <IconButton edge="end" aria-label="delete" size="large" onClick={handleAddGoalDelete(goal.id)}>
                            <Delete />
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            <Collapse in={goal.editorOpen} timeout="auto" unmountOnExit>
                <Grid container spacing={1} style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}>
                    <Grid item xs={12}>
                        <TextField
                            variant='filled'
                            style={{ marginBottom: theme.spacing(2) }}
                            margin='none'
                            size='small'
                            fullWidth={true}
                            required label={'Name'}
                            onChange={(e) => { updateGoal({ ...goal, name: e.target.value }) }}
                            value={goal.name} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SliderInput value={goal.horizonInYears} onChange={(e: any) => { updateGoal({ ...goal, horizonInYears: e }) }} minValue={1} maxValue={contributionHorizon} title={'Time frame (years)'} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SliderInput value={goal.amount} showCurrency onChange={(e: any) => { updateGoal({ ...goal, amount: e }) }} minValue={0} maxValue={1000000} title={'Amount'} />
                    </Grid>
                </Grid>
            </Collapse>
        </div>)
    });


    return (
        <div>
            {/* <AppMenu /> */}
            <Grid container direction="row" justifyContent="flex-start" alignItems='stretch' style={{ minHeight: '100vh' }}>
                {/* <Grid item xs={12} sm='auto'>
                    <AppMenu />
                </Grid> */}
                <Grid item xs={12} sm>
                    {/* TODO <Grid container spacing={0} className={classes.paperContainer} style={{ textAlign: 'left', paddingTop: theme.spacing(2) }} direction="row" alignItems='stretch' justifyContent='center'> */}
                    <Grid container spacing={0} style={{ textAlign: 'left', paddingTop: theme.spacing(2), maxWidth: 1000, margin: 'auto' }} direction="row" alignItems='stretch' justifyContent='center'>
                        <Grid item xs={12} sm={6}>
                            <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                                <Tooltip open={fundTooltipOpen} title={<div style={{ fontSize: 14 }} >We've chosen a fund to match your preference. You can change it here.</div>} arrow>
                                    <Autocomplete
                                        style={{ width: '100%' }}
                                        id="combo-box"
                                        filterOptions={optionsFilter}
                                        options={mappedFunds as any[]}
                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                        getOptionLabel={(option) => option.name.replace('Sweden/', '')}
                                        onChange={(e, v) => {
                                            setSelectedFund(v);
                                        }}
                                        value={selectedFund}
                                        renderInput={(params) => <TextField {...params} label="Selected fund" variant="outlined" />}
                                    />
                                </Tooltip>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(0) }}>
                                <Tooltip open={monthlyContributionTooltipOpen} title={<div style={{ fontSize: 14 }} >Change the amount you deposit every month here.</div>} arrow>
                                    <div>
                                        <SliderInput value={monthlyContribution} showCurrency onChange={setMonthlyContribution} minValue={0} maxValue={10000} title={'Monthly contribution'} />
                                    </div>
                                </Tooltip>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <div style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(0) }}>
                                <SliderInput value={contributionHorizon} onChange={setContributionHorizon} minValue={1} maxValue={30} title={'Contribution horizon (years)'} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Paper square elevation={1}>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <GoalChart height={theme.spacing(30)} setGoals={setGoals} horizon={30} goals={goals} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} />
                                    </Grid>
                                    {/*                   
                  <Grid item xs={12}>
                    <Alert style={theme.palette.mode === 'dark' ? { margin: theme.spacing(1), background: theme.palette.success.main + '22', } : { margin: theme.spacing(1) }} variant={'standard'} severity="success"

                      action={
                        <>
                          <Button color="inherit" size="small">
                            UNDO
                          </Button>
                          <Button color="inherit" size="small">
                            UNDO
                          </Button>
                        </>
                      }
                    ><AlertTitle color={'text.primary'}>On track!</AlertTitle>
                      This is a success alert — check it out!</Alert>
                  </Grid> */}
                                    <Grid item xs>
                                        <Grid container style={{ padding: theme.spacing(2) }}>
                                            <Grid item xs={12}>
                                                <Typography variant={'body1'} color={'text.secondary'} style={{ marginLeft: theme.spacing(1), marginBottom: theme.spacing(1) }}>Investment goals</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {renderGoals}
                                            </Grid>
                                        </Grid>
                                        <Grid container style={{ marginBottom: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                                            <Grid item xs></Grid>
                                            <Grid item xs='auto'>
                                                <Button onClick={handleAddGoalClickOpen}>
                                                    <Add />Add Goal
                                                </Button>
                                                <AddGoalDialog title='Add goal' open={addGoalOpen} onClose={handleAddGoalClose} goals={goals} setGoals={setGoals} maxHorizon={contributionHorizon} productCode={selectedFund} currentScenarioSetId={settings.currentScenarioSet.id} monthlyContribution={monthlyContribution} setMonthlyContribution={setMonthlyContribution} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ padding: theme.spacing(2) }}>
                                        <TrackingItem
                                            style={{ height: theme.spacing(11) }}
                                            notificationStatus={notificationStatus} />
                                        <Box display="flex" justifyContent="flex-end" style={{ paddingBottom: theme.spacing(1) }}>
                                            <Button onClick={adjustGap} sx={{ visibility: notificationStatus === 'notify' ? 'visible' : 'hidden' }}>Recommend contribution</Button>
                                        </Box>
                                        <div>
                                            <div><Typography variant='body2' color='text.secondary'>Realistic outcome in 30 years</Typography><Typography variant='h4'><TextNumberFormat decimals={0} value={outcomesAtHorizon.median} /></Typography></div>
                                            <div><Typography variant='body2' color='text.secondary'>Optimistic outcome</Typography><Typography variant='subtitle1'><TextNumberFormat decimals={0} value={outcomesAtHorizon.good} /></Typography></div>
                                            <div><Typography variant='body2' color='text.secondary'>Pessimistic outcome</Typography><TextNumberFormat decimals={0} value={outcomesAtHorizon.bad} /></div>
                                        </div>
                                    </Grid>
                                </Grid>

                            </Paper>
                        </Grid>
                        {/* <Grid item xs={12} sm={12}>
                            <div style={{ marginTop: theme.spacing(4), paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingBottom: theme.spacing(2) }}>
                                <Grid container>
                                    <Grid item xs>
                                    </Grid>
                                    <Grid item xs='auto'>
                                        <Button size="large" style={{ marginRight: theme.spacing(2) }}>Discard changes</Button>
                                        <Tooltip title={<div style={{ fontSize: 18 }} >In the pre-sales journey, this will lead to the login and execution. In post-sale, this action is used to update the plan.</div>} arrow>
                                            <Button onClick={() => setExecuteButtonText('Update savings')} variant="contained" color="primary" size="large">
                                                {executeButtonText}
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid> */}
                    </Grid>
                </Grid>
                {/* <div style={{ marginTop: 'auto', marginLeft: 'auto' }}>
                    <Button color="primary" href='/developer'>Developer</Button>
                </div> */}
                {/* <Snackbar style={{ whiteSpace: 'pre-wrap' }} open={errorMessage !== undefined} autoHideDuration={4000} onClose={() => setErrorMessage(undefined)} message={errorMessage}></Snackbar> */}
            </Grid >
            <OnboardingDialog open={onboardingOpen} setSelectedFund={setSelectedFund} onClose={handleOnboardingClose} />
        </div >
    );
}


function OnboardingDialog(props: any) {

    const { onClose, setSelectedFund, open } = props;
    const [riskLevel, setRiskLevel] = useState(3);
    const theme = useTheme();



    const marks = [
        {
            value: 0,
            label: 'No risk',
        },
        {
            value: 1,
            label: 'Low',
        },
        {
            value: 2,
            label: 'Low-medium',
        },
        {
            value: 3,
            label: 'Medium',
        },
        {
            value: 4,
            label: 'Medium-high',
        },
        {
            value: 5,
            label: 'High',
        },
    ];

    const handleClose = () => {

        const namedFundsArray: any = [
            { name: "Sweden/NoRiskPortfolio", codeType: "Custom", code: "Sweden/NoRiskPortfolio" },
            { name: "Sweden/VeryLowRiskPortfolio", codeType: "Custom", code: "Sweden/VeryLowRiskPortfolio" },
            { name: "Sweden/LowRiskPortfolio", codeType: "Custom", code: "Sweden/LowRiskPortfolio" },
            { name: "Sweden/MediumLowRiskPortfolio", codeType: "Custom", code: "Sweden/MediumLowRiskPortfolio" },
            { name: "Sweden/MediumHighRiskPortfolio", codeType: "Custom", code: "Sweden/MediumHighRiskPortfolio" },
            { name: "Sweden/HighRiskPortfolio", codeType: "Custom", code: "Sweden/HighRiskPortfolio" },
            { name: "Sweden/VeryHighRiskPortfolio", codeType: "Custom", code: "Sweden/VeryHighRiskPortfolio" },
        ];
        setSelectedFund(namedFundsArray[riskLevel]);
        onClose();
    };

    return (
        <Dialog fullWidth={true} fullScreen={true} maxWidth={'md'} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <div style={{ maxWidth: 650, marginLeft: 'auto', marginRight: 'auto', marginTop: theme.spacing(12), padding: theme.spacing(2) }}>
                <div>
                    <Typography variant='h2'>Welcome! Tell us about your risk preference to get started.</Typography>
                </div>
                <div style={{ textAlign: 'center' }} >
                    <div style={{ paddingTop: theme.spacing(20), paddingBottom: theme.spacing(20), paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
                        <Typography id="risklevel">
                            Risk Level
                        </Typography>
                        <Slider
                            // classes={{ markLabel: classes.smallText }}
                            style={{ width: theme.spacing(40) }}
                            min={0}
                            max={5}
                            value={riskLevel}
                            onChange={(e, v) => { setRiskLevel(Number(v)); }}
                            marks={marks}
                            aria-labelledby="risklevel"
                        />
                    </div>
                    <Button size='large' onClick={handleClose} color="primary" variant='contained'>
                        Next
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}


function AddGoalDialog(props: any) {
    const theme = useTheme();

    const { onClose, open, title, productCode, currentScenarioSetId, goals, setGoals, monthlyContribution, setMonthlyContribution, maxHorizon } = props;

    const [resultState, setResultState] = useState(getDefaultInvestmentGoalPlanningResult());

    const portfolioDevelopmentTimeSeries = resultState.financialPlanningResponseModel.percentileForecast;


    const [horizonInYears, setHorizonInYears] = useState(10);
    const [name, setName] = useState('');
    const [targetAmount, setTargetAmount] = useState(10000);
    const [monthlyInvestmentAmount, setMonthlyInvestmentAmount] = useState(100);

    const resetState = () => {
        setHorizonInYears(10);
        setName('');
        setTargetAmount(10000);
        setMonthlyInvestmentAmount(100);
    };

    const debouncedHorizonInYears = useDebounce(horizonInYears, 800);
    const debouncedTargetAmount = useDebounce(targetAmount, 800);
    const debouncedMonthlyContributions = useDebounce(monthlyInvestmentAmount, 800);

    const onAdd = () => {

        const newGoals = goals.map((a: any) => { return { ...a } }).concat([{
            id: goals.length === 0 ? 1 : Math.max(...(goals.map((g: any) => g.id))) + 1,
            name: name,
            type: 'OneTime',
            horizonInYears: horizonInYears,
            durationInYears: 0,
            amount: targetAmount,
            chosenMonthlyContribution: monthlyInvestmentAmount,
            enabled: true,
            editorOpen: false
        }]);
        setGoals(newGoals);
        handleClose();
        setMonthlyContribution(goals.length === 0 ? monthlyInvestmentAmount : monthlyContribution + monthlyInvestmentAmount);
        resetState();
    };

    useEffect(() => {
        if (currentScenarioSetId === '' || productCode === null || isNaN(debouncedTargetAmount) || isNaN(debouncedHorizonInYears) || isNaN(debouncedMonthlyContributions)) return;
        // // if (prevGrossSalary !== debouncedBasicCustomerInformation.grossSalary || prevDateOfBirth !== debouncedBasicCustomerInformation.dateOfBirth) {
        // // setNumberOfRequestInFlight(state => state + 1);
        // const initialAccount = createInvestmentAccount('', "3fa85f64-5717-4562-b3fc-2c963f66afa6", productCode, 0);
        // const monthlyAccount = createInvestmentAccount('', "4fa85f64-5717-4562-b3fc-2c963f66afa6", productCode, 0);

        // investmentGoalPlanning(currentScenarioSetId,
        //   debouncedHorizonInYears,
        //   debouncedTargetAmount,
        //   0,
        //   debouncedMonthlyContributions,
        //   InvestmentAccountTaxationType.NoTax,
        //   initialAccount,
        //   monthlyAccount,
        //   IsaContributionStrategy.OnlyBondsAndSharesIsa, 0)
        //   .then(result => {
        //     try {
        //       setResultState(result as InvestmentGoalPlanningResult);
        //     } finally {
        //       // setNumberOfRequestInFlight(state => state - 1);
        //     }
        //   });
        // // }

        // const goals = [{ amount: debouncedTargetAmount, horizonInYears: debouncedHorizonInYears, enabled: true, name: '', id: 1, chosenMonthlyContribution: 0, durationInYears: 0, type: 'OneTime' }];
        const goals = [] as {
            id: number,
            name: string,
            type: string,
            horizonInYears: number,
            durationInYears: number,
            amount: number,
            chosenMonthlyContribution: number,
            enabled: boolean
        }[];


        const request = createRequest(
            currentScenarioSetId,
            0,
            productCode,
            debouncedMonthlyContributions,
            30,
            "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            goals);
        callFinancialPlanning(
            30,
            request)
            .then(result => {
                try {
                    callGap(
                        debouncedTargetAmount,
                        request,
                        "3fa85f64-5717-4562-b3fc-2c963f66afa6")
                        .then(gapResult => {
                            try {
                                (result as InvestmentGoalPlanningResult).requiredMonthlyContribution = Math.ceil(gapResult[debouncedHorizonInYears].factor * debouncedMonthlyContributions);
                                setResultState(result as InvestmentGoalPlanningResult);
                            } finally {

                                // setNumberOfRequestInFlight(state => state - 1);
                            }
                        });

                } finally {
                    // setNumberOfRequestInFlight(state => state - 1);
                }
            });

    }, [
        productCode,
        debouncedHorizonInYears,
        debouncedTargetAmount,
        debouncedMonthlyContributions,
        currentScenarioSetId
    ]);


    const handleClose = () => {
        onClose();
    };


    return (
        <Dialog fullWidth={true} maxWidth={'md'} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
            <Grid style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }} container spacing={2}>
                <Grid item xs={12} md={5}  >
                    <TextField
                        variant='filled'
                        style={{ marginBottom: theme.spacing(2) }}
                        margin='none'
                        size='small'
                        fullWidth={true}
                        required label={'Name'}
                        onChange={(e) => { setName(e.target.value) }}
                        value={name} />
                    <SliderInput value={horizonInYears} onChange={setHorizonInYears} minValue={1} maxValue={maxHorizon} title={'Time frame (years)'} />
                    <SliderInput value={targetAmount} showCurrency onChange={setTargetAmount} minValue={0} maxValue={1000000} title={'Amount'} />
                    <RequiredInput
                        title={'Monthly contribution'}
                        requiredEnabled={resultState.requiredMonthlyContribution !== monthlyInvestmentAmount}
                        requiredContribution={resultState.requiredMonthlyContribution}
                        contribution={monthlyInvestmentAmount}
                        setContribution={setMonthlyInvestmentAmount}
                        maxValue={9999} />
                    <Typography style={{ float: 'left', width: '100%' }} variant='caption'>Your monthly contribution will be updated with this additional amount</Typography>
                </Grid>
                <Grid item xs={12} md={7} >
                    <Fade in={portfolioDevelopmentTimeSeries.length > 1}>
                        <Paper square>
                            <Typography style={{ paddingLeft: theme.spacing(1), paddingTop: theme.spacing(1) }} color='text.secondary' variant='body2'>Value Over Time</Typography>
                            <PercentileFanChart height={theme.spacing(34)} targetAmount={targetAmount} horizon={isNaN(horizonInYears) ? 0 : horizonInYears} hideHorizon={false} currentAge={0} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} />
                        </Paper>
                    </Fade>
                </Grid>
            </Grid>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onAdd} disabled={name === ''} color="primary" variant="contained">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const RequiredInput = (props: any) => {
    const theme = useTheme();

    const { requiredEnabled, title, requiredContribution, contribution, setContribution, maxValue, helperText } = props;
    return (<div>
        <div style={{ float: 'left', width: '55%' }}>
            <SliderInput value={contribution} showCurrency onChange={setContribution} minValue={0} maxValue={maxValue} title={title} helperText={helperText} />
        </div>
        <div style={{ float: 'right', width: '40%', marginBottom: theme.spacing(4) }}>
            <Typography style={{ textAlign: 'left' }} variant='body2' color='text.secondary'>Required Amount</Typography>
            <Button onClick={() => { setContribution(requiredContribution); }} size='small' style={{ minWidth: theme.spacing(14), textAlign: 'left', justifyContent: 'left' }} variant={requiredEnabled ? "contained" : "outlined"} color='primary' disabled={!requiredEnabled}>
                <ArrowBack fontSize="small" style={{ visibility: requiredEnabled ? 'visible' : 'hidden', marginRight: theme.spacing(1), marginBottom: 1 }} />
                {requiredEnabled ? <TextNumberFormat roundToNearest={1} value={requiredContribution} /> : ''}
            </Button>
        </div>
    </div>);
};

function TrackingItem({ notificationStatus, style }: any) {
    const theme = useTheme();
    const colors = useAppColors();

    const iconType = notificationStatus;
    const bigText = notificationStatus === 'ok' ? 'On track' : notificationStatus === 'nogoal' ? 'Add goals.'
        : 'Caution';
    const smallText = notificationStatus === 'ok' ? 'Your current plan is expected to fund your goals.' :
        notificationStatus === 'nogoal' ? 'Help us guide you by adding investment goals.'
            : 'An increase in monthly contribution is needed to fund your goals.';

    const icon = iconType === 'ok' ? <CheckCircleTwoTone style={{
        fill: colors.status.ok,
        display: "inline-block",
        marginBottom: "-4px",
        marginRight: theme.spacing(1)
    }} fontSize='inherit' /> : iconType === 'notify' ? <NotificationImportantTwoTone style={{
        fill: colors.status.notify,
        display: "inline-block",
        marginBottom: "-4px",
        marginRight: theme.spacing(1)
    }} fontSize='inherit' /> : iconType === 'nogoal' ? <LightbulbTwoTone style={{
        fill: colors.status.notify,
        display: "inline-block",
        marginBottom: "-4px",
        marginRight: theme.spacing(1)
    }} fontSize='inherit' /> :
        <WarningTwoTone color='error' style={{
            display: "inline-block",
            marginBottom: "-4px",
            marginRight: theme.spacing(1)
        }} fontSize='inherit' />;

    return (<div style={style}>
        <Typography variant='h4' style={{
            marginRight: 2
        }}>
            {icon}
            {bigText}
        </Typography>
        <Typography variant='body2'>{smallText}</Typography>
    </div>);


}
