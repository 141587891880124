import { Add } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogTitle, Divider, Grid, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import React, { Fragment, useState } from "react";
import { AssetsAndDebtsRequest, Frequency, CashFlowsRequest, InflationAdjustmentContract, InvestmentAccountTaxationType, InvestmentStrategyType } from "../../api_client";
import { InvestmentAccountVM, TransactionVM, TransactionAccountVM, TransactionCategory, TransactionType, DisplayState, AccountType, OccupationalPensionAgreementType, WaterfallAllocationStrategyVM } from "../Portfolio";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import TransactionView from "./TransactionView";
import { useHeaderStyle } from "../../theming/useAppColors";
import { WaterfallStrategyView } from "./WaterfallStrategyView";


export function WrappedIncomeAndExpensesView(props: {
    accounts: { name: string, id: string, type: AccountType }[],
    transactions: TransactionVM[],
    setTransactions: (incomeAndExpenses: TransactionVM[]) => void,
    strategies: WaterfallAllocationStrategyVM[],
    setStrategies: (newVal: WaterfallAllocationStrategyVM[]) => void,
}) {

    const { accounts, transactions, strategies, setStrategies } = props;


    const [anchorAddEl, setAnchorAddEl] = React.useState<null | HTMLElement>(null);
    const addOpen = Boolean(anchorAddEl);
    const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorAddEl(event.currentTarget);
    };
    const handleAddClose = () => {
        setAnchorAddEl(null);
    };
    const theme = useTheme();

    const [newTransaction, setNewTransaction] = useState(emptyTransaction(TransactionCategory.TaxExemptIncome));

    const [addDialogOpen, setAddDialogOpen] = useState(false);

    const handleAddDialogOpen = (transactionCategory: TransactionCategory) => {
        setNewTransaction(emptyTransaction(transactionCategory));
        handleAddClose();
        setAddDialogOpen(true);
    };

    const handleAddDialogClose = () => {
        setAddDialogOpen(false);
    };


    const [newStrategy, setNewStrategy] = useState({ displayState: DisplayState.Edit, name: '', tiers: [] } as WaterfallAllocationStrategyVM);

    const [addStrategyDialogOpen, setAddStrategyDialogOpen] = useState(false);

    const handleAddStrategyDialogOpen = () => {
        setNewStrategy({ displayState: DisplayState.Edit, name: '', tiers: [] });
        handleAddClose();
        setAddStrategyDialogOpen(true);
    };

    const handleAddStrategyDialogClose = () => {
        setAddStrategyDialogOpen(false);
    };

    const headerStyle = useHeaderStyle();


    return (
        <Grid container>
            <Grid item xs color='primary' sx={headerStyle}>
                <Typography style={{ paddingTop: theme.spacing(1), paddingLeft: theme.spacing(1), maxHeight: theme.spacing(5) }} variant='body1'>Income and expenses</Typography>
            </Grid>
            <Grid item xs='auto' sx={headerStyle}>
                <Button sx={headerStyle} style={{ padding: theme.spacing(1) }} onClick={handleAddClick}>
                    <Add />add
                </Button>
                <Menu
                    id="addportfolio-menu"
                    anchorEl={anchorAddEl}
                    open={addOpen}
                    onClose={handleAddClose}
                >
                    <MenuItem onClick={() => handleAddDialogOpen(TransactionCategory.TaxExemptIncome)}>Income/inflow (tax exempt)</MenuItem>
                    <MenuItem onClick={() => handleAddDialogOpen(TransactionCategory.Expense)}>Expense/cost/gift</MenuItem>
                    <MenuItem onClick={() => handleAddDialogOpen(TransactionCategory.EarnedIncome)}>Salary</MenuItem>
                    <MenuItem onClick={() => handleAddDialogOpen(TransactionCategory.DividendsK10)}>Dividend K10</MenuItem>
                    <MenuItem onClick={() => handleAddDialogOpen(TransactionCategory.DividendsK12)}>Dividend K12</MenuItem>
                    <MenuItem onClick={() => handleAddDialogOpen(TransactionCategory.IntraAccountTransaction)}>Intra-account transaction</MenuItem>
                    <Divider></Divider>
                    <MenuItem onClick={() => handleAddStrategyDialogOpen()}>Waterfall strategy</MenuItem>
                </Menu>
                <Dialog fullWidth={true} maxWidth={'sm'} onClose={handleAddDialogClose} aria-labelledby="simple-dialog-title" open={addDialogOpen}>
                    <DialogTitle id="simple-dialog-title">Add transaction</DialogTitle>
                    <div style={{ padding: theme.spacing(1), }}>
                        <TransactionView
                            limitedOptions={false}
                            transaction={newTransaction}
                            setTransaction={setNewTransaction}
                            onDelete={undefined}
                            accounts={accounts}
                        />
                    </div>
                    <DialogActions>
                        <Button onClick={handleAddDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                props.setTransactions([
                                    ...transactions, { ...newTransaction, displayState: DisplayState.Display }
                                ]);
                                handleAddDialogClose();
                            }}
                            disabled={newTransaction.name === ''}
                            color="primary"
                            variant="contained">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog fullWidth={true} maxWidth={'sm'} onClose={handleAddStrategyDialogClose} aria-labelledby="simple-dialog-title" open={addStrategyDialogOpen}>
                    <DialogTitle id="simple-dialog-title-Strategy">Add strategy</DialogTitle>
                    <div style={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3), paddingTop: theme.spacing(0), paddingBottom: theme.spacing(1) }}>
                        <WaterfallStrategyView
                            // limitedOptions={false}
                            strategy={newStrategy}
                            setStrategy={setNewStrategy}
                            onDelete={undefined}
                            accounts={accounts}
                        />
                    </div>
                    <DialogActions>
                        <Button onClick={handleAddStrategyDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                props.setStrategies(strategies ? [
                                    ...strategies, { ...newStrategy, displayState: DisplayState.Display }
                                ] : [{ ...newStrategy, displayState: DisplayState.Display }]);
                                handleAddStrategyDialogClose();
                            }}
                            disabled={newStrategy.name === ''}
                            color="primary"
                            variant="contained">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                {transactions?.map((a, i) =>
                    <Fragment key={a.id}>
                        <div style={{ padding: theme.spacing(1) }}>
                            <TransactionView
                                key={i}
                                limitedOptions={false}
                                transaction={a as TransactionVM}
                                setTransaction={(transaction) => props.setTransactions(transactions.map((aa, ii) => ii === i ? transaction : aa))}
                                onDelete={() => {
                                    props.setTransactions(transactions.filter((aa, ii) => ii !== i))
                                }}
                                accounts={accounts}
                            />
                        </div>
                        <Divider />
                    </Fragment>
                )
                }
            </Grid>
            <Grid item xs={12}>
                {transactions?.length > 0 ? <Divider /> : <></>}
            </Grid>
            <Grid item xs={12}>
                {strategies?.map((a, i) =>
                    <Fragment key={a.name + 'i'}>
                        <div style={{ padding: theme.spacing(1) }}>
                            <WaterfallStrategyView
                                key={i}
                                strategy={a as WaterfallAllocationStrategyVM}
                                setStrategy={(transaction) => setStrategies(strategies.map((aa, ii) => ii === i ? transaction : aa))}
                                onDelete={() => {
                                    setStrategies(strategies.filter((aa, ii) => ii !== i))
                                }}
                                accounts={accounts}
                            />
                        </div>
                        <Divider />
                    </Fragment>
                )
                }
            </Grid>
        </Grid>
    )
}

function emptyTransaction(transactionCategory: TransactionCategory): TransactionVM {
    return {
        id: uuidv4(),
        amount: 0,
        startDate: moment().toDate(),
        category: transactionCategory,
        name: '',
        type: TransactionType.Recurring,
        duration: 1,
        durationUnit: Frequency.Annual,
        frequency: Frequency.Annual,
        inflationAdjustment: InflationAdjustmentContract.NoAdjustment,
        isPercentage: false,
        sourceId: '',
        targetId: '',
        displayState: DisplayState.Edit,
        occupationalPensionAgreement: { accountId: '', percentage: 0.1, type: OccupationalPensionAgreementType.NoAgreement }
    };
}

export function areEqual(prevProps: any, nextProps: any) {

    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
        return true;
    }
    return false;
}


export default React.memo(WrappedIncomeAndExpensesView);