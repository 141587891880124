import { AccountCircle, Add, BusinessCenter, Delete, Done, Edit, FeedSharp, Home, HomeOutlined, Percent, Warning } from "@mui/icons-material";
import { Badge, Button, Collapse, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, SvgIcon, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useContext } from "react";
import { Frequency, InvestmentAccountRequest, InvestmentAccountTaxationType, InvestmentStrategyType, OccupationalPayoutSchemeType, RiskLevel } from "../../api_client";
import { DisplayState, OccupationalPensionAccountVM, ProductCodeVM, WeightCalculationType } from "../Portfolio";
import { CurrencyFormatCustom, PercentFormatCustom, TextNumberFormat } from "../../NumberFormatCustom";
import SliderInput from "../../MultiGoal/SliderInput";
import { InvestmentAllocationsView } from "./InvestmentAllocationsView";
import { AssetsAndDebtsDisplayHeader } from "./AssetsAndDebtsDisplayHeader";

export function OccupationalPensionAccountView(props: { occupationalPensionAccount: OccupationalPensionAccountVM, setOccupationalPensionAccount: (newValue: OccupationalPensionAccountVM) => void, onDelete: () => void }) {

    const account = props.occupationalPensionAccount as OccupationalPensionAccountVM;
    const setInvestmentAccount = props.setOccupationalPensionAccount;
    const theme = useTheme();

    const hasDuplicate = Object.values(account.investmentAllocations.map(a => a.productCode?.code).reduce((total: any, value) => {
        total[value] = (total[value] || 0) + 1;
        return total;
    }, {})).find(count => count > 1) !== undefined;

    const hasInvalidWeight = Math.abs(1 - account.investmentAllocations.reduce((a, b) => a + b.strategyWeight, 0)) > 0.01;

    const errorText = (hasInvalidWeight ? 'Total weight of allocations must be 100%. ' : '') + (hasDuplicate ? 'Product must only appear once per account.' : '');
    return (
        <div>
            <Collapse in={account.displayState === DisplayState.Edit || account.displayState === undefined} timeout='auto' unmountOnExit>
                <Grid container>
                    <Grid item xs>
                        <TextField
                            variant='filled'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <BusinessCenter />
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginBottom: theme.spacing(2) }}
                            margin='none'
                            size='small'
                            fullWidth={true}
                            required label={'Name'}
                            onChange={(e) => {
                                setInvestmentAccount({ ...account, name: e.target.value });
                            }}
                            value={account.name} />
                    </Grid>
                    <Grid item xs='auto'>
                        <Tooltip title={errorText}>
                            <Badge color='warning' badgeContent={errorText === '' ? undefined : '!'}>
                                <IconButton disabled={errorText !== ''} style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={() => setInvestmentAccount({ ...account, displayState: DisplayState.Display })}>
                                    <Done />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs>
                        <SliderInput
                            value={account.payoutStartUnit === Frequency.Annual ? account.payoutScheme.numberOfMonthsUntilPayout / 12 : account.payoutScheme.numberOfMonthsUntilPayout}
                            onChange={(newValue: any) => {
                                setInvestmentAccount({ ...account, payoutScheme: { ...account.payoutScheme, numberOfMonthsUntilPayout: account.payoutStartUnit === Frequency.Annual ? newValue * 12 : newValue } });
                            }}
                            minValue={1}
                            maxValue={account.payoutStartUnit === Frequency.Annual ? 40 : 480} title={'Payout start in'} />
                    </Grid>
                    <Grid item xs='auto'>
                        <FormControl size="small">
                            {/* <InputLabel id="durationUnit-select">Unit</InputLabel> */}
                            <Select
                                // label="Frequency"
                                labelId="startUnit-select"
                                style={{ width: theme.spacing(12) }}

                                value={account.payoutStartUnit} onChange={e => {
                                    setInvestmentAccount({ ...account, payoutStartUnit: e.target.value as Frequency });
                                }}>
                                <MenuItem value={Frequency.Annual}>Years</MenuItem>
                                <MenuItem value={Frequency.Monthly}>Months</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <FormControl fullWidth={true} size="small">
                    <InputLabel id="payoutscheme-select">Payout Scheme</InputLabel>
                    <Select label="Payout Scheme"
                        labelId="payoutscheme-select"
                        fullWidth={true} value={account.payoutScheme.schemeType} onChange={e => {
                            setInvestmentAccount({ ...account, payoutScheme: { ...account.payoutScheme, schemeType: e.target.value as OccupationalPayoutSchemeType } });
                        }}>
                        <MenuItem value={OccupationalPayoutSchemeType.LifeLong}>Lifelong</MenuItem>
                        <MenuItem value={OccupationalPayoutSchemeType.FixedPeriod}>Fixed period</MenuItem>
                    </Select>
                </FormControl>
                <div style={{ paddingTop: theme.spacing(1) }}></div>
                {account.payoutScheme.schemeType === OccupationalPayoutSchemeType.FixedPeriod
                    ? (<Grid container spacing={1}>
                        <Grid item xs>
                            <SliderInput
                                value={account.payoutPeriodUnit === Frequency.Annual ? account.payoutScheme.fixedPeriodInMonths / 12 : account.payoutScheme.fixedPeriodInMonths}
                                onChange={(newValue: any) => {
                                    setInvestmentAccount({ ...account, payoutScheme: { ...account.payoutScheme, fixedPeriodInMonths: account.payoutPeriodUnit === Frequency.Annual ? newValue * 12 : newValue } });
                                }}
                                minValue={1}
                                maxValue={account.payoutPeriodUnit === Frequency.Annual ? 40 : 480} title={'Fixed period length'} />
                        </Grid>
                        <Grid item xs='auto'>
                            <FormControl size="small">
                                {/* <InputLabel id="durationUnit-select">Unit</InputLabel> */}
                                <Select
                                    // label="Frequency"
                                    labelId="durationUnit-select"
                                    style={{ width: theme.spacing(12) }}

                                    value={account.payoutPeriodUnit} onChange={e => {
                                        setInvestmentAccount({ ...account, payoutPeriodUnit: e.target.value as Frequency });
                                    }}>
                                    <MenuItem value={Frequency.Annual}>Years</MenuItem>
                                    <MenuItem value={Frequency.Monthly}>Months</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>) : (<></>)}
                <div style={{ paddingTop: theme.spacing(1) }}></div>
                {/* <FormControl fullWidth={true} size="small">
                    <InputLabel id="strategy-select">Strategy</InputLabel>
                    <Select label="Strategy"
                        labelId="strategy-select"
                        fullWidth={true} value={account.strategy} onChange={e => {
                            setInvestmentAccount({ ...account, strategy: e.target.value as InvestmentStrategyType });
                        }}>
                        <MenuItem value={InvestmentStrategyType.BuyAndHold}>Buy and hold</MenuItem>
                        <MenuItem value={InvestmentStrategyType.RebalanceToPlan}>Rebalance to plan</MenuItem>
                    </Select>
                </FormControl> */}
                <InvestmentAllocationsView
                    title="Allocations"
                    investmentAllocations={account.investmentAllocations}
                    setInvestmentAllocations={(e, s) => {
                        setInvestmentAccount({
                            ...account,
                            investmentAllocations: e,
                            // deriveWeightsFromCurrentValue: s.deriveWeightsFromCurrentValue,
                            weightCalculationType: s.weightCalculationType,
                            optimizationHorizonInYears: s.optimizationHorizonInYears,
                            optimizationRiskLevel: s.optimizationRiskLevel,
                            strategy: s.investmentStrategy
                        })
                    }
                    }
                    strategySettings={{
                        // deriveWeightsFromCurrentValue: account.deriveWeightsFromCurrentValue ? account.deriveWeightsFromCurrentValue : false,
                        weightCalculationType: account.weightCalculationType !== undefined ? account.weightCalculationType : WeightCalculationType.NoCalculation,
                        optimizationHorizonInYears: account.optimizationHorizonInYears !== undefined ? account.optimizationHorizonInYears : 10,
                        optimizationRiskLevel: account.optimizationRiskLevel !== undefined ? account.optimizationRiskLevel : RiskLevel.MediumHighRisk,
                        investmentStrategy: account.strategy
                    }}
                // setStrategySettings={(newValue: {deriveWeightsFromCurrentValue: boolean, investmentStrategy: InvestmentStrategyType}) => {
                //     setInvestmentAccount({
                //         ...account,
                //         deriveWeightsFromCurrentValue: newValue.deriveWeightsFromCurrentValue,
                //         strategy: newValue.investmentStrategy
                //     })
                // }}
                />
            </Collapse>
            <Collapse in={account.displayState === DisplayState.Display} timeout="auto" unmountOnExit>
                <AssetsAndDebtsDisplayHeader
                    name={account.name}
                    currentValue={account.investmentAllocations?.reduce((accumulator, currentValue) => accumulator + currentValue.currentValue, 0)}
                    delete={props.onDelete}
                    color={account.color}
                    edit={() => setInvestmentAccount({ ...account, displayState: DisplayState.Edit })} />
                <Grid container>
                    {/* <Grid item xs style={{ paddingTop: 6 }}>
                        {account.name}
                    </Grid>
                    <Grid item xs='auto' style={{ paddingTop: 6, width: theme.spacing(12) }}>
                        <Typography variant="body2"><TextNumberFormat value={account.investmentAllocations?.reduce((accumulator, currentValue) => accumulator + currentValue.currentValue, 0)} /></Typography>
                    </Grid>
                    <Grid item xs='auto'>
                        <IconButton style={{ color: theme.palette.text.secondary }} size="small" onClick={() => setInvestmentAccount({ ...account, displayState: DisplayState.Edit })}>
                            <Edit fontSize="small" />
                        </IconButton>
                    </Grid>
                    <Grid item xs='auto'>
                        <IconButton style={{ color: theme.palette.text.secondary }} size="small" onClick={props.onDelete}>
                            <Delete fontSize="small" />
                        </IconButton>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Typography style={{ color: theme.palette.text.secondary }} variant="caption">{account.payoutScheme.schemeType === OccupationalPayoutSchemeType.FixedPeriod ? 'Fixed period' : 'Lifelong'}, starting in {account.payoutScheme.numberOfMonthsUntilPayout} mo.{account.payoutScheme.schemeType === OccupationalPayoutSchemeType.FixedPeriod ? ', length ' + account.payoutScheme.fixedPeriodInMonths + ' mo.' : ''}</Typography>
                    </Grid>
                    {account.investmentAllocations.map((a, i) => {
                        return (<Grid item xs={12}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography style={{ marginLeft: theme.spacing(1) }} variant="caption">{(a.productCode as ProductCodeVM)?.name}</Typography>
                                </Grid>
                                <Grid item xs='auto'>
                                    <Typography style={{ paddingRight: theme.spacing(1) }} variant="caption">{Math.round(a.strategyWeight * 10000) / 100}%</Typography>
                                </Grid>
                            </Grid>
                        </Grid>)
                    })}

                </Grid>
            </Collapse>
        </div>
    );

}

export function areEqual(prevProps: any, nextProps: any) {

    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
        return true;
    }
    return false;
}

function mapTaxation(type: InvestmentAccountTaxationType): string {
    switch (type) {
        case InvestmentAccountTaxationType.SweIsk:
            return "ISK";
        case InvestmentAccountTaxationType.SweEndowmentInsurance:
            return "Endowment insurance";
        case InvestmentAccountTaxationType.SweCapitalGains:
            return "Capital gains tax";
        case InvestmentAccountTaxationType.NoTax:
            return "No tax";
    }
    return "Not mapped";
}

export default React.memo(OccupationalPensionAccountView);