import { Add, Delete, Done, Edit } from "@mui/icons-material";
import { Badge, Button, Checkbox, Collapse, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { Frequency, TransactionAccountRequest, InflationAdjustmentContract } from "../../api_client/api";
import { AccountType, DisplayState, OccupationalPensionAgreementType, TransactionCategory, TransactionType, TransactionVM } from "../../financialPlanning/Portfolio";
import { CurrencyFormatCustom, PercentFormatCustom, TextNumberFormat } from "../../NumberFormatCustom";
import { SettingsContext } from "../../settings/SettingsContext";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SliderInput from "../../MultiGoal/SliderInput";
import moment from "moment";

export default function TransactionView(props: {
    transaction: TransactionVM,
    setTransaction: (newValue: TransactionVM) => void,
    onDelete: () => void,
    accounts: { name: string, id: string, type: AccountType }[],
    limitedOptions: boolean
}) {

    const transaction = props.transaction as TransactionVM;
    const setTransaction = props.setTransaction;

    const errorText = (hasSource(transaction.category) && transaction.sourceId === '' ? 'Must define source. ' : '').concat(hasTarget(transaction.category) && transaction.targetId === '' ? 'Must define target.' : '');

    const theme = useTheme();
    return (
        <div>
            <Collapse in={transaction.displayState === DisplayState.Edit || transaction.displayState === undefined} timeout='auto' unmountOnExit>
                <Grid container spacing={1} direction='column'>
                    {props.limitedOptions
                        ? (<></>)
                        : (<Grid item xs={12}>
                            <FormControl fullWidth={true} size="small">
                                <InputLabel id="category-select">Category</InputLabel>
                                <Select label="Category"
                                    labelId="category-select"
                                    fullWidth={true} value={transaction.category} onChange={e => {
                                        setTransaction({
                                            ...transaction,
                                            category: e.target.value as TransactionCategory,
                                            sourceId: hasSource(e.target.value as TransactionCategory) ? transaction.sourceId : '',
                                            targetId: hasTarget(e.target.value as TransactionCategory) ? transaction.targetId : '',
                                            isPercentage: false
                                        });
                                    }}>
                                    <MenuItem value={TransactionCategory.TaxExemptIncome}>Income/inflow (tax exempt)</MenuItem>
                                    <MenuItem value={TransactionCategory.Expense}>Expense/cost/gift</MenuItem>
                                    <MenuItem value={TransactionCategory.EarnedIncome}>Salary</MenuItem>
                                    <MenuItem value={TransactionCategory.DividendsK10}>Dividend K10</MenuItem>
                                    <MenuItem value={TransactionCategory.DividendsK12}>Dividend K12</MenuItem>
                                    <MenuItem value={TransactionCategory.IntraAccountTransaction}>Intra-account transaction</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        )}

                    <Grid item xs={12}>

                        <Grid container>
                            <Grid item xs>
                                <TextField
                                    variant='filled'
                                    style={{ marginBottom: theme.spacing(1) }}
                                    margin='none'
                                    size='small'
                                    fullWidth={true}
                                    required label={'Name'}
                                    onChange={(e) => {
                                        setTransaction({ ...transaction, name: e.target.value });
                                    }}
                                    value={transaction.name}
                                />
                            </Grid>
                            {/* <Grid item xs='auto'>
                                {props.onDelete ? (<IconButton style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={props.onDelete}>
                                    <Delete />
                                </IconButton>) : (<></>)}
                            </Grid> */}
                            <Grid item xs='auto'>
                                {props.onDelete ? (<IconButton style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={() => setTransaction({ ...transaction, displayState: DisplayState.Display })}>
                                    <Done />
                                </IconButton>) : (<></>)}
                            </Grid>
                        </Grid>
                    </Grid>

                    {hasSource(transaction.category)
                        ? (<Grid item xs={12}>
                            <FormControl fullWidth={true} size="small" error={!props.accounts.find(a => a.id === transaction.sourceId) && transaction.sourceId !== ''}>
                                <InputLabel required id="source-select">Source account</InputLabel>
                                <Select label="Source account"
                                    labelId="source-select"
                                    required
                                    fullWidth={true}
                                    value={transaction.sourceId}
                                    onChange={e => {
                                        setTransaction({ ...transaction, sourceId: e.target.value });
                                    }}>
                                    {props.accounts.map(a => (<MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>) : (<></>)}

                    {hasTarget(transaction.category)
                        ? (<Grid item xs={12}>
                            <FormControl fullWidth={true} size="small" error={!props.accounts.find(a => a.id === transaction.targetId) && transaction.targetId !== ''}>
                                <InputLabel required id="target-select">Target account</InputLabel>
                                <Select label="Target account"
                                    labelId="target-select"
                                    required
                                    fullWidth={true}
                                    value={transaction.targetId}
                                    onChange={e => {
                                        setTransaction({ ...transaction, targetId: e.target.value });
                                    }}>
                                    {props.accounts.map(a => (<MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>) : (<></>)}

                    <Grid item xs={12}>
                        {transaction.isPercentage !== true ?
                            <Grid container spacing={1}>
                                <Grid item xs={props.limitedOptions ? 12 : true}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ inputComponent: CurrencyFormatCustom }}
                                        // variant='filled'
                                        margin='none'
                                        size="small"
                                        fullWidth={true}
                                        // fullWidth={true}
                                        label={props.limitedOptions ? transaction.inflationAdjustment === InflationAdjustmentContract.NoAdjustment ? 'Amount' : 'Amount (in today\'s value)' : 'Amount'}
                                        onChange={(e) => {
                                            setTransaction({ ...transaction, amount: Number(e.target.value) });
                                        }}
                                        value={transaction.amount} />
                                </Grid>
                                <Grid item xs={props.limitedOptions ? 12 : 'auto'}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="inflationAdjustment-select">Inflation</InputLabel>
                                        <Select label="Inflation"
                                            labelId="inflationAdjustment-select"

                                            value={transaction.inflationAdjustment} onChange={e => {
                                                setTransaction({ ...transaction, inflationAdjustment: e.target.value as InflationAdjustmentContract });
                                            }}>
                                            <MenuItem value={InflationAdjustmentContract.NoAdjustment}>No adjustment</MenuItem>
                                            <MenuItem value={InflationAdjustmentContract.ValueAsOfStartDate}>In today's value</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid> :
                            <TextField
                                error={transaction.amount < 0 || transaction.amount > 1}
                                InputLabelProps={{ shrink: true }}
                                fullWidth={true}
                                InputProps={{ inputComponent: PercentFormatCustom }}
                                margin='none'
                                size="small"
                                required label='Percentage'
                                onChange={(e) => {
                                    setTransaction({ ...transaction, amount: Number(e.target.value) / 100 });
                                }}
                                value={transaction.amount * 100} />}
                        {transaction.category === TransactionCategory.IntraAccountTransaction
                            || transaction.category === TransactionCategory.Expense
                            || transaction.category === TransactionCategory.DividendsK10
                            || transaction.category === TransactionCategory.DividendsK12 ?
                            <Grid item xs={12}>
                                <FormControl fullWidth={true} size="small">
                                    <FormControlLabel
                                        label={<Typography variant="caption">Expressed as percentage</Typography>}
                                        control={
                                            <Checkbox
                                                size='small'
                                                checked={transaction.isPercentage ? transaction.isPercentage : false}
                                                onChange={(e) => setTransaction({ ...transaction, isPercentage: e.target.checked })}
                                            />
                                        }

                                    /></FormControl>
                            </Grid> : <></>}
                    </Grid>

                    {props.limitedOptions
                        ? (<></>)
                        : (
                            <Grid item xs={12}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="type-select">Type</InputLabel>
                                    <Select label="Type"
                                        labelId="type-select"

                                        value={transaction.type} onChange={e => {
                                            setTransaction({ ...transaction, type: e.target.value as TransactionType });
                                        }}>
                                        <MenuItem value={TransactionType.OneTime}>One-time</MenuItem>
                                        <MenuItem value={TransactionType.Recurring}>Recurring</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        label={transaction.type === TransactionType.OneTime ? "Date" : "Start date"}
                                        value={transaction.startDate}
                                        onChange={(newValue: any) => {
                                            setTransaction({ ...transaction, startDate: newValue.toDate() });
                                        }}
                                        renderInput={(params) => <TextField fullWidth size="small"  {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            {transaction.type === TransactionType.OneTime
                                ? (<></>)
                                : (
                                    <Grid item xs='auto'>


                                        <FormControl size="small">
                                            <InputLabel id="frequency-select">Frequency</InputLabel>
                                            <Select
                                                style={{ width: theme.spacing(12) }}
                                                label="Frequency"
                                                labelId="frequency-select"
                                                value={transaction.frequency} onChange={e => {
                                                    setTransaction({ ...transaction, frequency: e.target.value as Frequency });
                                                }}>
                                                <MenuItem value={Frequency.Annual}>Annual</MenuItem>
                                                <MenuItem value={Frequency.Monthly}>Month</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>)}
                        </Grid>
                    </Grid>
                    {transaction.type === TransactionType.Recurring ?
                        (<Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>

                                        <SliderInput value={transaction.duration} onChange={(newValue: any) => {
                                            setTransaction({ ...transaction, duration: newValue });
                                        }} minValue={1} maxValue={transaction.durationUnit === Frequency.Annual ? 40 : 480} title={'Duration (until ' + moment(transaction.startDate).add(transaction.duration, transaction.durationUnit === Frequency.Annual ? 'years' : 'months').format('l') + ')'} />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs='auto'>
                                    <FormControl size="small">
                                        {/* <InputLabel id="durationUnit-select">Unit</InputLabel> */}
                                        <Select
                                            // label="Frequency"
                                            labelId="durationUnit-select"
                                            style={{ width: theme.spacing(12) }}

                                            value={transaction.durationUnit} onChange={e => {
                                                setTransaction({ ...transaction, durationUnit: e.target.value as Frequency });
                                            }}>
                                            <MenuItem value={Frequency.Annual}>Years</MenuItem>
                                            <MenuItem value={Frequency.Monthly}>Months</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>) : (<></>)}
                    {transaction.category === TransactionCategory.EarnedIncome ?
                        (<><Grid item xs={12}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="type-select">Occupational pension agreement</InputLabel>
                                <Select label="Occupational pension agreement"
                                    labelId="type-select"

                                    value={transaction.occupationalPensionAgreement.type} onChange={e => {
                                        setTransaction({ ...transaction, occupationalPensionAgreement: { ...transaction.occupationalPensionAgreement, type: e.target.value as OccupationalPensionAgreementType } });
                                    }}>
                                    <MenuItem value={OccupationalPensionAgreementType.NoAgreement}>No agreement</MenuItem>
                                    <MenuItem value={OccupationalPensionAgreementType.ITP1}>ITP1</MenuItem>
                                    <MenuItem value={OccupationalPensionAgreementType.PercentageOfSalary}>Percentage of salary</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                            {transaction.occupationalPensionAgreement.type !== OccupationalPensionAgreementType.NoAgreement
                                ? (<Grid item xs={12}>
                                    <FormControl fullWidth={true} size="small" error={!props.accounts.find(a => a.id === transaction.occupationalPensionAgreement.accountId) && transaction.occupationalPensionAgreement.accountId !== ''}>
                                        <InputLabel required id="target-select">Occupational pension account</InputLabel>
                                        <Select label="Occupational pension account"
                                            labelId="target-select"
                                            required
                                            fullWidth={true}
                                            value={transaction.occupationalPensionAgreement.accountId}
                                            onChange={e => {
                                                setTransaction({ ...transaction, occupationalPensionAgreement: { ...transaction.occupationalPensionAgreement, accountId: e.target.value } });
                                            }}>
                                            {props.accounts.filter(a => a.type === AccountType.OccupationalPensionAccount).map(a => (<MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>)
                                : (<></>)}
                            {transaction.occupationalPensionAgreement.type === OccupationalPensionAgreementType.PercentageOfSalary
                                ? (<Grid item xs={12}>
                                    <TextField
                                        error={transaction.occupationalPensionAgreement.percentage < 0}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ inputComponent: PercentFormatCustom }}
                                        // style={{ width: theme.spacing(12) }}
                                        // variant='filled'
                                        margin='none'
                                        size="small"
                                        fullWidth={true}
                                        required label='Percentage of salary'
                                        onChange={(e) => {
                                            setTransaction({ ...transaction, occupationalPensionAgreement: { ...transaction.occupationalPensionAgreement, percentage: Number(e.target.value) / 100 } })
                                        }}
                                        value={transaction.occupationalPensionAgreement.percentage * 100} />
                                </Grid>)
                                : (<></>)}
                        </>
                        ) : (<></>)}
                </Grid>
            </Collapse>
            <Collapse in={transaction.displayState === DisplayState.Display} timeout="auto" unmountOnExit>
                <Grid container>
                    <Grid item xs style={{ paddingTop: 6 }}>
                        <Typography variant="body2">{transaction.name}</Typography>
                    </Grid>
                    <Grid item xs='auto' style={{ paddingTop: 6, width: theme.spacing(10) }}>
                        <Typography variant="body2">{!transaction.isPercentage ? <TextNumberFormat value={transaction.amount} /> : <>{transaction.amount * 100}%</>}</Typography>
                    </Grid>
                    <Grid item xs='auto'>
                        <Tooltip title={errorText}>
                            <Badge color='warning' badgeContent={errorText === '' ? undefined : '!'}>
                                <IconButton style={{ color: theme.palette.text.secondary }} size="small" onClick={() => setTransaction({ ...transaction, displayState: DisplayState.Edit })}>
                                    <Edit fontSize="small" />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                        {/* <IconButton style={{ color: theme.palette.text.secondary }} size="small" onClick={() => setTransaction({ ...transaction, displayState: DisplayState.Edit })}>
                            <Edit fontSize="small" />
                        </IconButton> */}
                    </Grid>
                    <Grid item xs='auto'>
                        <IconButton style={{ color: theme.palette.text.secondary }} size="small" onClick={props.onDelete}>
                            <Delete fontSize="small" />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Typography variant="caption" color={'text.secondary'}>
                                {hasSource(transaction.category) ? (<>From: {props.accounts.find(a => a.id === transaction.sourceId)?.name}<br /></>) : ''}
                                {hasTarget(transaction.category) ? (<>To: {props.accounts.find(a => a.id === transaction.targetId)?.name}<br /></>) : ''}
                                {(transaction.inflationAdjustment === InflationAdjustmentContract.NoAdjustment ? 'No growth adjustment, ' : 'In today\'s value, ')
                                    + (transaction.type === TransactionType.OneTime
                                        ? 'on ' + moment(transaction.startDate).format('l')
                                        : (transaction.frequency === Frequency.Annual ? 'annually' : 'monthly') + ' from ' + moment(transaction.startDate).format('l') + ' for ' + transaction.duration + ' ' + (transaction.durationUnit === Frequency.Annual ? 'years' : 'months'))}
                            </Typography>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Collapse>
        </div>
    );
}

function hasTarget(transactionCategory: TransactionCategory) {
    return transactionCategory === TransactionCategory.EarnedIncome
        || transactionCategory === TransactionCategory.TaxExemptIncome
        || transactionCategory === TransactionCategory.IntraAccountTransaction
        || transactionCategory === TransactionCategory.DividendsK10
        || transactionCategory === TransactionCategory.DividendsK12;
}

function hasSource(transactionCategory: TransactionCategory) {
    return transactionCategory === TransactionCategory.Expense
        || transactionCategory === TransactionCategory.IntraAccountTransaction
        || transactionCategory === TransactionCategory.DividendsK10
        || transactionCategory === TransactionCategory.DividendsK12;
}