/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Historical Data API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v2
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AnnualReturnResponse
 */
export interface AnnualReturnResponse {
    /**
     * 
     * @type {Unit}
     * @memberof AnnualReturnResponse
     */
    quoteCurrencyCode?: Unit;
    /**
     * 
     * @type {string}
     * @memberof AnnualReturnResponse
     */
    isin?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnualReturnResponse
     */
    annualReturn?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum BenchmarkStatus {
    NotSet = <any> 'NotSet',
    Ok = <any> 'Ok',
    OkWithAdjustments = <any> 'OkWithAdjustments',
    OkWithWarnings = <any> 'OkWithWarnings',
    NotOk = <any> 'NotOk'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ExternalSource {
    NotSet = <any> 'NotSet',
    Refinitiv = <any> 'Refinitiv',
    CentralBankOfSweden = <any> 'CentralBankOfSweden',
    ECB = <any> 'ECB'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FundCategory {
    NotSet = <any> 'NotSet',
    Technology = <any> 'Technology',
    Sustainability = <any> 'Sustainability'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FundRegulatoryType {
    NotSet = <any> 'NotSet',
    UCITS = <any> 'UCITS',
    AIF = <any> 'AIF'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FundStrategy {
    NotSet = <any> 'NotSet',
    Index = <any> 'Index'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Geography {
    NotSet = <any> 'NotSet',
    GLOBAL = <any> 'GLOBAL',
    NORDIC = <any> 'NORDIC',
    EMEA = <any> 'EMEA',
    GBL = <any> 'GBL',
    AF = <any> 'AF',
    AN = <any> 'AN',
    AS = <any> 'AS',
    EU = <any> 'EU',
    NA = <any> 'NA',
    OC = <any> 'OC',
    SA = <any> 'SA',
    AFG = <any> 'AFG',
    ALB = <any> 'ALB',
    DZA = <any> 'DZA',
    ASM = <any> 'ASM',
    AND = <any> 'AND',
    AGO = <any> 'AGO',
    AIA = <any> 'AIA',
    ATA = <any> 'ATA',
    ATG = <any> 'ATG',
    ARG = <any> 'ARG',
    ARM = <any> 'ARM',
    ABW = <any> 'ABW',
    AUS = <any> 'AUS',
    AUT = <any> 'AUT',
    AZE = <any> 'AZE',
    BHS = <any> 'BHS',
    BHR = <any> 'BHR',
    BGD = <any> 'BGD',
    BRB = <any> 'BRB',
    BLR = <any> 'BLR',
    BEL = <any> 'BEL',
    BLZ = <any> 'BLZ',
    BEN = <any> 'BEN',
    BMU = <any> 'BMU',
    BTN = <any> 'BTN',
    BOL = <any> 'BOL',
    BES = <any> 'BES',
    BIH = <any> 'BIH',
    BWA = <any> 'BWA',
    BVT = <any> 'BVT',
    BRA = <any> 'BRA',
    IOT = <any> 'IOT',
    BRN = <any> 'BRN',
    BGR = <any> 'BGR',
    BFA = <any> 'BFA',
    BDI = <any> 'BDI',
    CPV = <any> 'CPV',
    KHM = <any> 'KHM',
    CMR = <any> 'CMR',
    CAN = <any> 'CAN',
    CYM = <any> 'CYM',
    CAF = <any> 'CAF',
    TCD = <any> 'TCD',
    CHL = <any> 'CHL',
    CHN = <any> 'CHN',
    CXR = <any> 'CXR',
    CCK = <any> 'CCK',
    COL = <any> 'COL',
    COM = <any> 'COM',
    COD = <any> 'COD',
    COG = <any> 'COG',
    COK = <any> 'COK',
    CRI = <any> 'CRI',
    HRV = <any> 'HRV',
    CUB = <any> 'CUB',
    CUW = <any> 'CUW',
    CYP = <any> 'CYP',
    CZE = <any> 'CZE',
    CIV = <any> 'CIV',
    DNK = <any> 'DNK',
    DJI = <any> 'DJI',
    DMA = <any> 'DMA',
    DOM = <any> 'DOM',
    ECU = <any> 'ECU',
    EGY = <any> 'EGY',
    SLV = <any> 'SLV',
    GNQ = <any> 'GNQ',
    ERI = <any> 'ERI',
    EST = <any> 'EST',
    SWZ = <any> 'SWZ',
    ETH = <any> 'ETH',
    FLK = <any> 'FLK',
    FRO = <any> 'FRO',
    FJI = <any> 'FJI',
    FIN = <any> 'FIN',
    FRA = <any> 'FRA',
    GUF = <any> 'GUF',
    PYF = <any> 'PYF',
    ATF = <any> 'ATF',
    GAB = <any> 'GAB',
    GMB = <any> 'GMB',
    GEO = <any> 'GEO',
    DEU = <any> 'DEU',
    GHA = <any> 'GHA',
    GIB = <any> 'GIB',
    GRC = <any> 'GRC',
    GRL = <any> 'GRL',
    GRD = <any> 'GRD',
    GLP = <any> 'GLP',
    GUM = <any> 'GUM',
    GTM = <any> 'GTM',
    GGY = <any> 'GGY',
    GIN = <any> 'GIN',
    GNB = <any> 'GNB',
    GUY = <any> 'GUY',
    HTI = <any> 'HTI',
    HMD = <any> 'HMD',
    VAT = <any> 'VAT',
    HND = <any> 'HND',
    HKG = <any> 'HKG',
    HUN = <any> 'HUN',
    ISL = <any> 'ISL',
    IND = <any> 'IND',
    IDN = <any> 'IDN',
    IRN = <any> 'IRN',
    IRQ = <any> 'IRQ',
    IRL = <any> 'IRL',
    IMN = <any> 'IMN',
    ISR = <any> 'ISR',
    ITA = <any> 'ITA',
    JAM = <any> 'JAM',
    JPN = <any> 'JPN',
    JEY = <any> 'JEY',
    JOR = <any> 'JOR',
    KAZ = <any> 'KAZ',
    KEN = <any> 'KEN',
    KIR = <any> 'KIR',
    PRK = <any> 'PRK',
    KOR = <any> 'KOR',
    KWT = <any> 'KWT',
    KGZ = <any> 'KGZ',
    LAO = <any> 'LAO',
    LVA = <any> 'LVA',
    LBN = <any> 'LBN',
    LSO = <any> 'LSO',
    LBR = <any> 'LBR',
    LBY = <any> 'LBY',
    LIE = <any> 'LIE',
    LTU = <any> 'LTU',
    LUX = <any> 'LUX',
    MAC = <any> 'MAC',
    MDG = <any> 'MDG',
    MWI = <any> 'MWI',
    MYS = <any> 'MYS',
    MDV = <any> 'MDV',
    MLI = <any> 'MLI',
    MLT = <any> 'MLT',
    MHL = <any> 'MHL',
    MTQ = <any> 'MTQ',
    MRT = <any> 'MRT',
    MUS = <any> 'MUS',
    MYT = <any> 'MYT',
    MEX = <any> 'MEX',
    FSM = <any> 'FSM',
    MDA = <any> 'MDA',
    MCO = <any> 'MCO',
    MNG = <any> 'MNG',
    MNE = <any> 'MNE',
    MSR = <any> 'MSR',
    MAR = <any> 'MAR',
    MOZ = <any> 'MOZ',
    MMR = <any> 'MMR',
    NAM = <any> 'NAM',
    NRU = <any> 'NRU',
    NPL = <any> 'NPL',
    NLD = <any> 'NLD',
    NCL = <any> 'NCL',
    NZL = <any> 'NZL',
    NIC = <any> 'NIC',
    NER = <any> 'NER',
    NGA = <any> 'NGA',
    NIU = <any> 'NIU',
    NFK = <any> 'NFK',
    MNP = <any> 'MNP',
    NOR = <any> 'NOR',
    OMN = <any> 'OMN',
    PAK = <any> 'PAK',
    PLW = <any> 'PLW',
    PSE = <any> 'PSE',
    PAN = <any> 'PAN',
    PNG = <any> 'PNG',
    PRY = <any> 'PRY',
    PER = <any> 'PER',
    PHL = <any> 'PHL',
    PCN = <any> 'PCN',
    POL = <any> 'POL',
    PRT = <any> 'PRT',
    PRI = <any> 'PRI',
    QAT = <any> 'QAT',
    MKD = <any> 'MKD',
    ROU = <any> 'ROU',
    RUS = <any> 'RUS',
    RWA = <any> 'RWA',
    REU = <any> 'REU',
    BLM = <any> 'BLM',
    SHN = <any> 'SHN',
    KNA = <any> 'KNA',
    LCA = <any> 'LCA',
    MAF = <any> 'MAF',
    SPM = <any> 'SPM',
    VCT = <any> 'VCT',
    WSM = <any> 'WSM',
    SMR = <any> 'SMR',
    STP = <any> 'STP',
    SAU = <any> 'SAU',
    SEN = <any> 'SEN',
    SRB = <any> 'SRB',
    SYC = <any> 'SYC',
    SLE = <any> 'SLE',
    SGP = <any> 'SGP',
    SXM = <any> 'SXM',
    SVK = <any> 'SVK',
    SVN = <any> 'SVN',
    SLB = <any> 'SLB',
    SOM = <any> 'SOM',
    ZAF = <any> 'ZAF',
    SGS = <any> 'SGS',
    SSD = <any> 'SSD',
    ESP = <any> 'ESP',
    LKA = <any> 'LKA',
    SDN = <any> 'SDN',
    SUR = <any> 'SUR',
    SJM = <any> 'SJM',
    SWE = <any> 'SWE',
    CHE = <any> 'CHE',
    SYR = <any> 'SYR',
    TWN = <any> 'TWN',
    TJK = <any> 'TJK',
    TZA = <any> 'TZA',
    THA = <any> 'THA',
    TLS = <any> 'TLS',
    TGO = <any> 'TGO',
    TKL = <any> 'TKL',
    TON = <any> 'TON',
    TTO = <any> 'TTO',
    TUN = <any> 'TUN',
    TUR = <any> 'TUR',
    TKM = <any> 'TKM',
    TCA = <any> 'TCA',
    TUV = <any> 'TUV',
    UGA = <any> 'UGA',
    UKR = <any> 'UKR',
    ARE = <any> 'ARE',
    GBR = <any> 'GBR',
    UMI = <any> 'UMI',
    USA = <any> 'USA',
    URY = <any> 'URY',
    UZB = <any> 'UZB',
    VUT = <any> 'VUT',
    VEN = <any> 'VEN',
    VNM = <any> 'VNM',
    VGB = <any> 'VGB',
    VIR = <any> 'VIR',
    WLF = <any> 'WLF',
    ESH = <any> 'ESH',
    YEM = <any> 'YEM',
    ZMB = <any> 'ZMB',
    ZWE = <any> 'ZWE',
    ALA = <any> 'ALA'
}
/**
 * 
 * @export
 * @interface IBenchmark
 */
export interface IBenchmark {
    /**
     * 
     * @type {string}
     * @memberof IBenchmark
     */
    benchmarkClass?: string;
    /**
     * 
     * @type {BenchmarkStatus}
     * @memberof IBenchmark
     */
    benchmarkStatus?: BenchmarkStatus;
    /**
     * 
     * @type {string}
     * @memberof IBenchmark
     */
    benchmarkUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof IBenchmark
     */
    externalIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof IBenchmark
     */
    externalName?: string;
    /**
     * 
     * @type {ExternalSource}
     * @memberof IBenchmark
     */
    externalSource?: ExternalSource;
    /**
     * 
     * @type {string}
     * @memberof IBenchmark
     */
    oecdStatus?: string;
    /**
     * 
     * @type {Date}
     * @memberof IBenchmark
     */
    startDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof IBenchmark
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IBenchmark
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof IBenchmark
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IEuroExchangeRate
 */
export interface IEuroExchangeRate {
    /**
     * 
     * @type {ExternalSource}
     * @memberof IEuroExchangeRate
     */
    externalSource?: ExternalSource;
    /**
     * 
     * @type {string}
     * @memberof IEuroExchangeRate
     */
    quoteCurrencyCode?: string;
    /**
     * 
     * @type {number}
     * @memberof IEuroExchangeRate
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IEuroExchangeRate
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof IEuroExchangeRate
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IMarketContract
 */
export interface IMarketContract {
    /**
     * 
     * @type {string}
     * @memberof IMarketContract
     */
    assetType?: string;
    /**
     * 
     * @type {FundCategory}
     * @memberof IMarketContract
     */
    category?: FundCategory;
    /**
     * 
     * @type {Unit}
     * @memberof IMarketContract
     */
    currency?: Unit;
    /**
     * 
     * @type {string}
     * @memberof IMarketContract
     */
    externalIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof IMarketContract
     */
    externalName?: string;
    /**
     * 
     * @type {string}
     * @memberof IMarketContract
     */
    externalSource?: string;
    /**
     * 
     * @type {number}
     * @memberof IMarketContract
     */
    historicalDataContextDtoId?: number;
    /**
     * 
     * @type {string}
     * @memberof IMarketContract
     */
    isin?: string;
    /**
     * 
     * @type {Geography}
     * @memberof IMarketContract
     */
    region?: Geography;
    /**
     * 
     * @type {FundRegulatoryType}
     * @memberof IMarketContract
     */
    regulatoryType?: FundRegulatoryType;
    /**
     * 
     * @type {Date}
     * @memberof IMarketContract
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof IMarketContract
     */
    status?: string;
    /**
     * 
     * @type {FundStrategy}
     * @memberof IMarketContract
     */
    strategy?: FundStrategy;
    /**
     * 
     * @type {number}
     * @memberof IMarketContract
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IMarketContract
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof IMarketContract
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ISingleStock
 */
export interface ISingleStock {
    /**
     * 
     * @type {string}
     * @memberof ISingleStock
     */
    downloadStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ISingleStock
     */
    exchange?: string;
    /**
     * 
     * @type {string}
     * @memberof ISingleStock
     */
    externalIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ISingleStock
     */
    externalName?: string;
    /**
     * 
     * @type {string}
     * @memberof ISingleStock
     */
    externalSource?: string;
    /**
     * 
     * @type {string}
     * @memberof ISingleStock
     */
    isin?: string;
    /**
     * 
     * @type {string}
     * @memberof ISingleStock
     */
    lei?: string;
    /**
     * 
     * @type {Date}
     * @memberof ISingleStock
     */
    listingDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ISingleStock
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof ISingleStock
     */
    sector?: string;
    /**
     * 
     * @type {string}
     * @memberof ISingleStock
     */
    shortName?: string;
    /**
     * 
     * @type {number}
     * @memberof ISingleStock
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ISingleStock
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ISingleStock
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ITimeSeriesData
 */
export interface ITimeSeriesData {
    /**
     * 
     * @type {Date}
     * @memberof ITimeSeriesData
     */
    dateCreated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ITimeSeriesData
     */
    fromDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ITimeSeriesData
     */
    id?: number;
    /**
     * 
     * @type {MarketObservableDto}
     * @memberof ITimeSeriesData
     */
    marketObservable?: MarketObservableDto;
    /**
     * 
     * @type {number}
     * @memberof ITimeSeriesData
     */
    marketObservableId?: number;
    /**
     * 
     * @type {Array<TimeSeriesSampleDto>}
     * @memberof ITimeSeriesData
     */
    timeSeries?: Array<TimeSeriesSampleDto>;
    /**
     * 
     * @type {TimeSeriesDataType}
     * @memberof ITimeSeriesData
     */
    timeSeriesDataType?: TimeSeriesDataType;
    /**
     * 
     * @type {TimeSeriesFrequency}
     * @memberof ITimeSeriesData
     */
    timeSeriesFrequency?: TimeSeriesFrequency;
    /**
     * 
     * @type {Date}
     * @memberof ITimeSeriesData
     */
    toDate?: Date;
}
/**
 * 
 * @export
 * @interface ITimeSeriesPoint
 */
export interface ITimeSeriesPoint {
    /**
     * 
     * @type {number}
     * @memberof ITimeSeriesPoint
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof ITimeSeriesPoint
     */
    pointInTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof ITimeSeriesPoint
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface MarketObservableDto
 */
export interface MarketObservableDto {
    /**
     * 
     * @type {number}
     * @memberof MarketObservableDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketObservableDto
     */
    identifier?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketObservableDto
     */
    name?: string;
}
// /**
//  * 
//  * @export
//  * @interface PayoutCurrency
//  */
// export interface PayoutCurrency extends Unit {
// }
/**
 * 
 * @export
 * @enum {string}
 */
export enum TimeSeriesDataType {
    NotSet = <any> 'NotSet',
    Duration = <any> 'Duration',
    ExchangeRate = <any> 'ExchangeRate',
    InceptionTotalReturn = <any> 'InceptionTotalReturn',
    InterestRate = <any> 'InterestRate',
    ModifiedDuration = <any> 'ModifiedDuration',
    Nav = <any> 'Nav',
    None = <any> 'None',
    PriceIndex = <any> 'PriceIndex',
    ReturnIndex = <any> 'ReturnIndex',
    RedemptionYield = <any> 'RedemptionYield',
    TotalReturn = <any> 'TotalReturn',
    ZeroYield = <any> 'ZeroYield',
    Stock = <any> 'Stock'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TimeSeriesFrequency {
    NotSet = <any> 'NotSet',
    Daily = <any> 'Daily',
    DailyBusiness = <any> 'DailyBusiness',
    Monthly = <any> 'Monthly',
    Quarterly = <any> 'Quarterly'
}
/**
 * 
 * @export
 * @interface TimeSeriesSampleDto
 */
export interface TimeSeriesSampleDto {
    /**
     * 
     * @type {number}
     * @memberof TimeSeriesSampleDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof TimeSeriesSampleDto
     */
    pointInTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof TimeSeriesSampleDto
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface TimeSeriesSetRequest
 */
export interface TimeSeriesSetRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof TimeSeriesSetRequest
     */
    identifiers?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof TimeSeriesSetRequest
     */
    fromDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimeSeriesSetRequest
     */
    toDate?: Date;
}
/**
 * 
 * @export
 * @interface TimeSeriesWithIdentifier
 */
export interface TimeSeriesWithIdentifier {
    /**
     * 
     * @type {Array<ITimeSeriesPoint>}
     * @memberof TimeSeriesWithIdentifier
     */
    dataPoints?: Array<ITimeSeriesPoint>;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriesWithIdentifier
     */
    identifier?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Unit {
    NotSet = <any> 'NotSet',
    None = <any> 'None',
    Local = <any> 'Local',
    CNY = <any> 'CNY',
    EUR = <any> 'EUR',
    INR = <any> 'INR',
    JPY = <any> 'JPY',
    NOK = <any> 'NOK',
    RUB = <any> 'RUB',
    SEK = <any> 'SEK',
    THB = <any> 'THB',
    USD = <any> 'USD',
    GBP = <any> 'GBP',
    AUD = <any> 'AUD',
    CHF = <any> 'CHF',
    CAD = <any> 'CAD',
    DKK = <any> 'DKK',
    ISK = <any> 'ISK'
}
/**
 * 
 * @export
 * @interface V2TimeseriessetBody
 */
export interface V2TimeseriessetBody extends TimeSeriesSetRequest {
}
/**
 * 
 * @export
 * @interface V2TimeseriessetBody1
 */
export interface V2TimeseriessetBody1 extends TimeSeriesSetRequest {
}
/**
 * 
 * @export
 * @interface V2TimeseriessetBody2
 */
export interface V2TimeseriessetBody2 extends TimeSeriesSetRequest {
}
/**
 * HistoricalDataController2Api - fetch parameter creator
 * @export
 */
export const HistoricalDataController2ApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} benchmarkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataBenchmarkBenchmarkIdGet(benchmarkId: string, options: any = {}): FetchArgs {
            // verify required parameter 'benchmarkId' is not null or undefined
            if (benchmarkId === null || benchmarkId === undefined) {
                throw new RequiredError('benchmarkId','Required parameter benchmarkId was null or undefined when calling apiV2MasterDataBenchmarkBenchmarkIdGet.');
            }
            const localVarPath = `/api/v2/master-data/benchmark/{benchmarkId}`
                .replace(`{${"benchmarkId"}}`, encodeURIComponent(String(benchmarkId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataBenchmarkGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/v2/master-data/benchmark`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataCurrencyExchangeRateGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/v2/master-data/currency-exchange-rate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataCurrencyExchangeRateIdentifierGet(identifier: string, options: any = {}): FetchArgs {
            // verify required parameter 'identifier' is not null or undefined
            if (identifier === null || identifier === undefined) {
                throw new RequiredError('identifier','Required parameter identifier was null or undefined when calling apiV2MasterDataCurrencyExchangeRateIdentifierGet.');
            }
            const localVarPath = `/api/v2/master-data/currency-exchange-rate/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataMarketContractGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/v2/master-data/market-contract`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataMarketContractMarketContractIdGet(marketContractId: string, options: any = {}): FetchArgs {
            // verify required parameter 'marketContractId' is not null or undefined
            if (marketContractId === null || marketContractId === undefined) {
                throw new RequiredError('marketContractId','Required parameter marketContractId was null or undefined when calling apiV2MasterDataMarketContractMarketContractIdGet.');
            }
            const localVarPath = `/api/v2/master-data/market-contract/{marketContractId}`
                .replace(`{${"marketContractId"}}`, encodeURIComponent(String(marketContractId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataSingleStockGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/v2/master-data/single-stock`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataSingleStockIsinGet(isin: string, options: any = {}): FetchArgs {
            // verify required parameter 'isin' is not null or undefined
            if (isin === null || isin === undefined) {
                throw new RequiredError('isin','Required parameter isin was null or undefined when calling apiV2MasterDataSingleStockIsinGet.');
            }
            const localVarPath = `/api/v2/master-data/single-stock/{isin}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} isin 
         * @param {Date} [date] 
         * @param {PayoutCurrency} [payoutCurrency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MeasurementsAnnualReturnIsinGet(isin: string, date?: Date, payoutCurrency?: Unit, options: any = {}): FetchArgs {
            // verify required parameter 'isin' is not null or undefined
            if (isin === null || isin === undefined) {
                throw new RequiredError('isin','Required parameter isin was null or undefined when calling apiV2MeasurementsAnnualReturnIsinGet.');
            }
            const localVarPath = `/api/v2/measurements/annual-return/{isin}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (payoutCurrency !== undefined) {
                localVarQueryParameter['payoutCurrency'] = payoutCurrency;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} benchmarkId 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesBenchmarkBenchmarkIdGet(benchmarkId: string, fromDate?: Date, toDate?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'benchmarkId' is not null or undefined
            if (benchmarkId === null || benchmarkId === undefined) {
                throw new RequiredError('benchmarkId','Required parameter benchmarkId was null or undefined when calling apiV2TimeSeriesBenchmarkBenchmarkIdGet.');
            }
            const localVarPath = `/api/v2/time-series/benchmark/{benchmarkId}`
                .replace(`{${"benchmarkId"}}`, encodeURIComponent(String(benchmarkId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any).toISOString();
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} identifier 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesCurrencyExchangeRateIdentifierGet(identifier: string, fromDate?: Date, toDate?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'identifier' is not null or undefined
            if (identifier === null || identifier === undefined) {
                throw new RequiredError('identifier','Required parameter identifier was null or undefined when calling apiV2TimeSeriesCurrencyExchangeRateIdentifierGet.');
            }
            const localVarPath = `/api/v2/time-series/currency-exchange-rate/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any).toISOString();
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} identifier 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesIdentifierGet(identifier: string, fromDate?: Date, toDate?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'identifier' is not null or undefined
            if (identifier === null || identifier === undefined) {
                throw new RequiredError('identifier','Required parameter identifier was null or undefined when calling apiV2TimeSeriesIdentifierGet.');
            }
            const localVarPath = `/api/v2/time-series/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any).toISOString();
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} isoCurrencyCode 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesInflationIsoCurrencyCodeGet(isoCurrencyCode: string, fromDate?: Date, toDate?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'isoCurrencyCode' is not null or undefined
            if (isoCurrencyCode === null || isoCurrencyCode === undefined) {
                throw new RequiredError('isoCurrencyCode','Required parameter isoCurrencyCode was null or undefined when calling apiV2TimeSeriesInflationIsoCurrencyCodeGet.');
            }
            const localVarPath = `/api/v2/time-series/inflation/{isoCurrencyCode}`
                .replace(`{${"isoCurrencyCode"}}`, encodeURIComponent(String(isoCurrencyCode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any).toISOString();
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [isin] 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesMarketContractGet(isin?: string, fromDate?: Date, toDate?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/api/v2/time-series/market-contract`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (isin !== undefined) {
                localVarQueryParameter['isin'] = isin;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any).toISOString();
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} marketContractId 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesMarketContractMarketContractIdGet(marketContractId: string, fromDate?: Date, toDate?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'marketContractId' is not null or undefined
            if (marketContractId === null || marketContractId === undefined) {
                throw new RequiredError('marketContractId','Required parameter marketContractId was null or undefined when calling apiV2TimeSeriesMarketContractMarketContractIdGet.');
            }
            const localVarPath = `/api/v2/time-series/market-contract/{marketContractId}`
                .replace(`{${"marketContractId"}}`, encodeURIComponent(String(marketContractId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any).toISOString();
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {V2TimeseriessetBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesSetPost(body?: V2TimeseriessetBody, options: any = {}): FetchArgs {
            const localVarPath = `/api/v2/time-series-set`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"V2TimeseriessetBody2" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} isin 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesSingleStockIsinGet(isin: string, fromDate?: Date, toDate?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'isin' is not null or undefined
            if (isin === null || isin === undefined) {
                throw new RequiredError('isin','Required parameter isin was null or undefined when calling apiV2TimeSeriesSingleStockIsinGet.');
            }
            const localVarPath = `/api/v2/time-series/single-stock/{isin}`
                .replace(`{${"isin"}}`, encodeURIComponent(String(isin)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any).toISOString();
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoricalDataController2Api - functional programming interface
 * @export
 */
export const HistoricalDataController2ApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} benchmarkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataBenchmarkBenchmarkIdGet(benchmarkId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IBenchmark> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2MasterDataBenchmarkBenchmarkIdGet(benchmarkId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataBenchmarkGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IBenchmark>> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2MasterDataBenchmarkGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataCurrencyExchangeRateGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IEuroExchangeRate>> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2MasterDataCurrencyExchangeRateGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataCurrencyExchangeRateIdentifierGet(identifier: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IEuroExchangeRate> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2MasterDataCurrencyExchangeRateIdentifierGet(identifier, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataMarketContractGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<IMarketContract>> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2MasterDataMarketContractGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} marketContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataMarketContractMarketContractIdGet(marketContractId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IMarketContract> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2MasterDataMarketContractMarketContractIdGet(marketContractId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataSingleStockGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ISingleStock>> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2MasterDataSingleStockGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataSingleStockIsinGet(isin: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ISingleStock> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2MasterDataSingleStockIsinGet(isin, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} isin 
         * @param {Date} [date] 
         * @param {Unit} [payoutCurrency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MeasurementsAnnualReturnIsinGet(isin: string, date?: Date, payoutCurrency?: Unit, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AnnualReturnResponse> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2MeasurementsAnnualReturnIsinGet(isin, date, payoutCurrency, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} benchmarkId 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesBenchmarkBenchmarkIdGet(benchmarkId: string, fromDate?: Date, toDate?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ITimeSeriesPoint>> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2TimeSeriesBenchmarkBenchmarkIdGet(benchmarkId, fromDate, toDate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} identifier 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesCurrencyExchangeRateIdentifierGet(identifier: string, fromDate?: Date, toDate?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ITimeSeriesPoint>> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2TimeSeriesCurrencyExchangeRateIdentifierGet(identifier, fromDate, toDate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} identifier 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesIdentifierGet(identifier: string, fromDate?: Date, toDate?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ITimeSeriesPoint>> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2TimeSeriesIdentifierGet(identifier, fromDate, toDate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} isoCurrencyCode 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesInflationIsoCurrencyCodeGet(isoCurrencyCode: string, fromDate?: Date, toDate?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ITimeSeriesPoint>> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2TimeSeriesInflationIsoCurrencyCodeGet(isoCurrencyCode, fromDate, toDate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [isin] 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesMarketContractGet(isin?: string, fromDate?: Date, toDate?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Array<ITimeSeriesPoint>>> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2TimeSeriesMarketContractGet(isin, fromDate, toDate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} marketContractId 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesMarketContractMarketContractIdGet(marketContractId: string, fromDate?: Date, toDate?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ITimeSeriesPoint>> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2TimeSeriesMarketContractMarketContractIdGet(marketContractId, fromDate, toDate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {V2TimeseriessetBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesSetPost(body?: V2TimeseriessetBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<TimeSeriesWithIdentifier>> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2TimeSeriesSetPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} isin 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesSingleStockIsinGet(isin: string, fromDate?: Date, toDate?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ITimeSeriesPoint>> {
            const localVarFetchArgs = HistoricalDataController2ApiFetchParamCreator(configuration).apiV2TimeSeriesSingleStockIsinGet(isin, fromDate, toDate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * HistoricalDataController2Api - factory interface
 * @export
 */
export const HistoricalDataController2ApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} benchmarkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataBenchmarkBenchmarkIdGet(benchmarkId: string, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2MasterDataBenchmarkBenchmarkIdGet(benchmarkId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataBenchmarkGet(options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2MasterDataBenchmarkGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataCurrencyExchangeRateGet(options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2MasterDataCurrencyExchangeRateGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataCurrencyExchangeRateIdentifierGet(identifier: string, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2MasterDataCurrencyExchangeRateIdentifierGet(identifier, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataMarketContractGet(options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2MasterDataMarketContractGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} marketContractId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataMarketContractMarketContractIdGet(marketContractId: string, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2MasterDataMarketContractMarketContractIdGet(marketContractId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataSingleStockGet(options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2MasterDataSingleStockGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} isin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MasterDataSingleStockIsinGet(isin: string, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2MasterDataSingleStockIsinGet(isin, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} isin 
         * @param {Date} [date] 
         * @param {Unit} [payoutCurrency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2MeasurementsAnnualReturnIsinGet(isin: string, date?: Date, payoutCurrency?: Unit, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2MeasurementsAnnualReturnIsinGet(isin, date, payoutCurrency, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} benchmarkId 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesBenchmarkBenchmarkIdGet(benchmarkId: string, fromDate?: Date, toDate?: Date, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2TimeSeriesBenchmarkBenchmarkIdGet(benchmarkId, fromDate, toDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} identifier 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesCurrencyExchangeRateIdentifierGet(identifier: string, fromDate?: Date, toDate?: Date, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2TimeSeriesCurrencyExchangeRateIdentifierGet(identifier, fromDate, toDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} identifier 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesIdentifierGet(identifier: string, fromDate?: Date, toDate?: Date, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2TimeSeriesIdentifierGet(identifier, fromDate, toDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} isoCurrencyCode 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesInflationIsoCurrencyCodeGet(isoCurrencyCode: string, fromDate?: Date, toDate?: Date, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2TimeSeriesInflationIsoCurrencyCodeGet(isoCurrencyCode, fromDate, toDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [isin] 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesMarketContractGet(isin?: string, fromDate?: Date, toDate?: Date, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2TimeSeriesMarketContractGet(isin, fromDate, toDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} marketContractId 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesMarketContractMarketContractIdGet(marketContractId: string, fromDate?: Date, toDate?: Date, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2TimeSeriesMarketContractMarketContractIdGet(marketContractId, fromDate, toDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {V2TimeseriessetBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesSetPost(body?: V2TimeseriessetBody, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2TimeSeriesSetPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} isin 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2TimeSeriesSingleStockIsinGet(isin: string, fromDate?: Date, toDate?: Date, options?: any) {
            return HistoricalDataController2ApiFp(configuration).apiV2TimeSeriesSingleStockIsinGet(isin, fromDate, toDate, options)(fetch, basePath);
        },
    };
};

/**
 * HistoricalDataController2Api - object-oriented interface
 * @export
 * @class HistoricalDataController2Api
 * @extends {BaseAPI}
 */
export class HistoricalDataController2Api extends BaseAPI {
    /**
     * 
     * @param {string} benchmarkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2MasterDataBenchmarkBenchmarkIdGet(benchmarkId: string, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2MasterDataBenchmarkBenchmarkIdGet(benchmarkId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2MasterDataBenchmarkGet(options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2MasterDataBenchmarkGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2MasterDataCurrencyExchangeRateGet(options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2MasterDataCurrencyExchangeRateGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} identifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2MasterDataCurrencyExchangeRateIdentifierGet(identifier: string, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2MasterDataCurrencyExchangeRateIdentifierGet(identifier, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2MasterDataMarketContractGet(options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2MasterDataMarketContractGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} marketContractId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2MasterDataMarketContractMarketContractIdGet(marketContractId: string, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2MasterDataMarketContractMarketContractIdGet(marketContractId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2MasterDataSingleStockGet(options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2MasterDataSingleStockGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} isin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2MasterDataSingleStockIsinGet(isin: string, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2MasterDataSingleStockIsinGet(isin, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} isin 
     * @param {Date} [date] 
     * @param {Unit} [payoutCurrency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2MeasurementsAnnualReturnIsinGet(isin: string, date?: Date, payoutCurrency?: Unit, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2MeasurementsAnnualReturnIsinGet(isin, date, payoutCurrency, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} benchmarkId 
     * @param {Date} [fromDate] 
     * @param {Date} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2TimeSeriesBenchmarkBenchmarkIdGet(benchmarkId: string, fromDate?: Date, toDate?: Date, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2TimeSeriesBenchmarkBenchmarkIdGet(benchmarkId, fromDate, toDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} identifier 
     * @param {Date} [fromDate] 
     * @param {Date} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2TimeSeriesCurrencyExchangeRateIdentifierGet(identifier: string, fromDate?: Date, toDate?: Date, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2TimeSeriesCurrencyExchangeRateIdentifierGet(identifier, fromDate, toDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} identifier 
     * @param {Date} [fromDate] 
     * @param {Date} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2TimeSeriesIdentifierGet(identifier: string, fromDate?: Date, toDate?: Date, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2TimeSeriesIdentifierGet(identifier, fromDate, toDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} isoCurrencyCode 
     * @param {Date} [fromDate] 
     * @param {Date} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2TimeSeriesInflationIsoCurrencyCodeGet(isoCurrencyCode: string, fromDate?: Date, toDate?: Date, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2TimeSeriesInflationIsoCurrencyCodeGet(isoCurrencyCode, fromDate, toDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [isin] 
     * @param {Date} [fromDate] 
     * @param {Date} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2TimeSeriesMarketContractGet(isin?: string, fromDate?: Date, toDate?: Date, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2TimeSeriesMarketContractGet(isin, fromDate, toDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} marketContractId 
     * @param {Date} [fromDate] 
     * @param {Date} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2TimeSeriesMarketContractMarketContractIdGet(marketContractId: string, fromDate?: Date, toDate?: Date, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2TimeSeriesMarketContractMarketContractIdGet(marketContractId, fromDate, toDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {V2TimeseriessetBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2TimeSeriesSetPost(body?: V2TimeseriessetBody, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2TimeSeriesSetPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} isin 
     * @param {Date} [fromDate] 
     * @param {Date} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataController2Api
     */
    public apiV2TimeSeriesSingleStockIsinGet(isin: string, fromDate?: Date, toDate?: Date, options?: any) {
        return HistoricalDataController2ApiFp(this.configuration).apiV2TimeSeriesSingleStockIsinGet(isin, fromDate, toDate, options)(this.fetch, this.basePath);
    }

}
/**
 * OperationsApi - fetch parameter creator
 * @export
 */
export const OperationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2OperationsIngestionGet(fromDate?: Date, toDate?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/api/v2/operations/ingestion`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any).toISOString();
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2OperationsTimeseriesSegmentsIdentifierGet(identifier: string, options: any = {}): FetchArgs {
            // verify required parameter 'identifier' is not null or undefined
            if (identifier === null || identifier === undefined) {
                throw new RequiredError('identifier','Required parameter identifier was null or undefined when calling apiV2OperationsTimeseriesSegmentsIdentifierGet.');
            }
            const localVarPath = `/api/v2/operations/timeseries-segments/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperationsApi - functional programming interface
 * @export
 */
export const OperationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2OperationsIngestionGet(fromDate?: Date, toDate?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = OperationsApiFetchParamCreator(configuration).apiV2OperationsIngestionGet(fromDate, toDate, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2OperationsTimeseriesSegmentsIdentifierGet(identifier: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ITimeSeriesData>> {
            const localVarFetchArgs = OperationsApiFetchParamCreator(configuration).apiV2OperationsTimeseriesSegmentsIdentifierGet(identifier, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OperationsApi - factory interface
 * @export
 */
export const OperationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {Date} [fromDate] 
         * @param {Date} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2OperationsIngestionGet(fromDate?: Date, toDate?: Date, options?: any) {
            return OperationsApiFp(configuration).apiV2OperationsIngestionGet(fromDate, toDate, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} identifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV2OperationsTimeseriesSegmentsIdentifierGet(identifier: string, options?: any) {
            return OperationsApiFp(configuration).apiV2OperationsTimeseriesSegmentsIdentifierGet(identifier, options)(fetch, basePath);
        },
    };
};

/**
 * OperationsApi - object-oriented interface
 * @export
 * @class OperationsApi
 * @extends {BaseAPI}
 */
export class OperationsApi extends BaseAPI {
    /**
     * 
     * @param {Date} [fromDate] 
     * @param {Date} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationsApi
     */
    public apiV2OperationsIngestionGet(fromDate?: Date, toDate?: Date, options?: any) {
        return OperationsApiFp(this.configuration).apiV2OperationsIngestionGet(fromDate, toDate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} identifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationsApi
     */
    public apiV2OperationsTimeseriesSegmentsIdentifierGet(identifier: string, options?: any) {
        return OperationsApiFp(this.configuration).apiV2OperationsTimeseriesSegmentsIdentifierGet(identifier, options)(this.fetch, this.basePath);
    }

}
