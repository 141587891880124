import { Add, AddBox, AddCircle, AddCircleOutline, Delete, Settings, SettingsOutlined } from "@mui/icons-material";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { InvestmentAccountAllocation, InvestmentAllocationRequest, InvestmentStrategyType, ProductCodeContract, RiskLevel } from "../../api_client";
import { optimizePortfolio } from "../../api_client/tsClient";
import { useDebounce } from "../../hooks/useDebounce";
import { CurrencyFormatCustom, PercentFormatCustom } from "../../NumberFormatCustom";
import { SettingsContext } from "../../settings/SettingsContext";
import { ProductCodeVM, WeightCalculationType } from "../Portfolio";
import FundSelect from "./FundSelect";
import { InvestmentPortfolioView } from "./InvestmentPortfolioView";


export function InvestmentAllocationsView(props: {
    investmentAllocations: InvestmentAccountAllocation[] | InvestmentAllocationRequest[];
    setInvestmentAllocations: (
        newValue: InvestmentAccountAllocation[] | InvestmentAllocationRequest[],
        strategySettings: {
            weightCalculationType: WeightCalculationType,
            optimizationHorizonInYears: number,
            optimizationRiskLevel: RiskLevel,
            investmentStrategy: InvestmentStrategyType
        }) => void;
    title: string;
    strategySettings: {
        weightCalculationType: WeightCalculationType,
        optimizationHorizonInYears: number,
        optimizationRiskLevel: RiskLevel,
        investmentStrategy: InvestmentStrategyType
    };
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const settings = useContext(SettingsContext);
    const [portfolioNameDialogOpen, setPortfolioNameDialogOpen] = useState(false);

    const [referenceInvestmentAllocations, setReferenceInvestmentAllocations] = useState(undefined as InvestmentAccountAllocation[] | InvestmentAllocationRequest[]);
    const [referenceStrategySettings, setReferenceStrategySettings] = useState(
        {
            investmentStrategy: InvestmentStrategyType.RebalanceToPlan,
            optimizationHorizonInYears: 10,
            optimizationRiskLevel: RiskLevel.MediumHighRisk,
            weightCalculationType: WeightCalculationType.NoCalculation
        } as {
            weightCalculationType: WeightCalculationType,
            optimizationHorizonInYears: number,
            optimizationRiskLevel: RiskLevel,
            investmentStrategy: InvestmentStrategyType
        });

    const debouncedStrategySettings = useDebounce(referenceStrategySettings, 500);

    const handlePortfolioNameDialogClose = () => {
        setInvestmentAllocations(referenceInvestmentAllocations, referenceStrategySettings);
        setPortfolioNameDialogOpen(false);
    };

    const handlePortfolioNameDialogCloseCancel = () => {
        setPortfolioNameDialogOpen(false);
    };

    const marks = [
        {
            value: 0,
            label: 'No risk',
            riskLevel: RiskLevel.NoRisk
        },
        {
            value: 1,
            label: 'Low',
            riskLevel: RiskLevel.LowRisk
        },
        {
            value: 2,
            label: 'Low-medium',
            riskLevel: RiskLevel.MediumLowRisk
        },
        {
            value: 3,
            label: 'Medium',
            riskLevel: RiskLevel.MediumHighRisk
        },
        {
            value: 4,
            label: 'Medium-high',
            riskLevel: RiskLevel.HighRisk
        },
        {
            value: 5,
            label: 'High',
            riskLevel: RiskLevel.VeryHighRisk
        },
    ];



    useEffect(() => {
        if (debouncedStrategySettings !== undefined && debouncedStrategySettings.weightCalculationType === WeightCalculationType.Optimized) {
            optimizePortfolio(referenceInvestmentAllocations, debouncedStrategySettings.optimizationHorizonInYears, debouncedStrategySettings.optimizationRiskLevel, settings.currentScenarioSet.id)
                .then(r => setReferenceInvestmentAllocations(r.allocations));

        }
    }, [debouncedStrategySettings.weightCalculationType, debouncedStrategySettings.optimizationHorizonInYears, debouncedStrategySettings.optimizationRiskLevel])

    const { investmentAllocations, setInvestmentAllocations, title, strategySettings } = props;

    const productCodes = investmentAllocations.map(a => a.productCode);

    return (<Grid container spacing={1} style={{ paddingLeft: theme.spacing(1), paddingTop: theme.spacing(1) }}>
        <Grid container>
            <Grid item xs>
                <Typography style={{ paddingTop: theme.spacing(1), paddingLeft: theme.spacing(1) }} variant='body2'>{title}</Typography>
            </Grid>
            <Grid item xs='auto'>
                <div style={{ paddingTop: 2 }}>
                    <IconButton
                        style={{ color: theme.palette.text.secondary }}
                        size='small'
                        onClick={() => {
                            setReferenceInvestmentAllocations(JSON.parse(JSON.stringify(investmentAllocations)));
                            setReferenceStrategySettings(JSON.parse(JSON.stringify(strategySettings)));
                            setPortfolioNameDialogOpen(true);
                        }}>
                        <Settings fontSize="small" />
                    </IconButton >
                    <Dialog fullScreen={fullScreen} maxWidth={'lg'} PaperProps={{ style: { background: theme.palette.background.default } }} onClose={handlePortfolioNameDialogClose} aria-labelledby="edit-dialog-title" open={portfolioNameDialogOpen}>
                        <DialogTitle id="edit-dialog-title">Investment portfolio</DialogTitle>
                        <DialogContent style={{ padding: theme.spacing(2) }}>
                            {/* <div style={{ paddingTop: theme.spacing(1) }}></div> */}
                            <InvestmentPortfolioView
                                investmentAllocations={referenceInvestmentAllocations}
                                setInvestmentAllocations={(allocations, settings) => {
                                    setReferenceInvestmentAllocations(allocations);
                                    setReferenceStrategySettings(settings)
                                }}
                                strategySettings={referenceStrategySettings}
                                referenceInvestmentAllocations={props.investmentAllocations} />
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={handlePortfolioNameDialogClose} color="primary">
                                Cancel
                            </Button> */}
                            <Button
                                onClick={() => {
                                    handlePortfolioNameDialogCloseCancel();
                                }}
                                disabled={false}
                                color="primary"
                                variant="text">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    handlePortfolioNameDialogClose();
                                }}
                                disabled={false}
                                color="primary"
                                variant="contained">
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Grid>
            <Grid item xs='auto'>
                <IconButton
                    style={{ color: theme.palette.text.secondary }}
                    size='small'
                    onClick={
                        () => addAllocation(investmentAllocations, setInvestmentAllocations, strategySettings, settings.mappedFunds.filter(f => !investmentAllocations.map(a => a.productCode.code).includes(f.code))[0] as ProductCodeVM)
                    }>
                    <AddCircleOutline />
                </IconButton >
            </Grid>
        </Grid>
        {investmentAllocations.map((a, i) => {
            return (
                <Grid key={a.productCode?.code} item xs={12}>
                    <InvestmentAllocationView
                        allocation={a}
                        key={a.productCode?.code}
                        setAllocation={(e) => {
                            if (investmentAllocations[i].currentValue !== e.currentValue) {
                                setInvestmentAllocations(calculateWeights(strategySettings.weightCalculationType, investmentAllocations.map((ia, ii) => i === ii ? e : ia)), props.strategySettings)
                            }
                            else {
                                setInvestmentAllocations(investmentAllocations.map((ia, ii) => i === ii ? e : ia), props.strategySettings)
                            }
                        }}
                        onDelete={() => {
                            const newAllocations = calculateWeights(strategySettings.weightCalculationType, investmentAllocations.filter((ia, ii) => i !== ii));
                            setInvestmentAllocations(newAllocations, props.strategySettings);
                        }}
                        filteredFunds={productCodes}
                    />
                </Grid>
            );
        }
        )}
    </Grid>);
}

function InvestmentAllocationView(props: { allocation: InvestmentAccountAllocation | InvestmentAllocationRequest; setAllocation: (newValue: InvestmentAccountAllocation | InvestmentAllocationRequest) => void; onDelete: () => void; filteredFunds?: ProductCodeContract[] }) {
    const theme = useTheme();

    const { setAllocation, onDelete, allocation, filteredFunds } = props;

    return (
        <>
            <Grid container>
                <Grid item xs>
                    <FundSelect
                        style={{ marginBottom: theme.spacing(1) }}
                        selectedFund={allocation.productCode}
                        setSelectedFund={(e) => setAllocation({ ...allocation, productCode: e })}
                        filteredFunds={filteredFunds}
                    />
                </Grid>
                <Grid item xs='auto'>
                    <IconButton
                        style={{ color: theme.palette.text.secondary }}
                        size='small'
                        onClick={(e) => onDelete()}>
                        <Delete />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs>
                    <TextField
                        error={allocation.strategyWeight < 0}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputComponent: CurrencyFormatCustom }}
                        style={{ marginBottom: theme.spacing(1) }}
                        margin='none'
                        size="small"
                        fullWidth={true}
                        label='Current value'
                        onChange={(e) => {
                            setAllocation({ ...allocation, currentValue: Number(e.target.value), acquisitionValue: Number(e.target.value) })
                        }}
                        value={allocation.currentValue} />
                </Grid>
                <Grid item xs='auto'>
                    <TextField
                        error={allocation.annualFee < 0}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputComponent: PercentFormatCustom }}
                        margin='none'
                        fullWidth={true}
                        sx={{ width: theme.spacing(14) }}
                        size="small"
                        required
                        label='Fee'
                        onChange={(e) => setAllocation({ ...allocation, annualFee: Number(e.target.value) / 100 })}
                        value={allocation.annualFee * 100} />
                </Grid>
            </Grid>
        </>
    );
}

export function addAllocation(investmentAllocations: InvestmentAccountAllocation[] | InvestmentAllocationRequest[],
    setInvestmentAllocations: (
        newValue: InvestmentAccountAllocation[] | InvestmentAllocationRequest[],
        strategySettings: {
            // deriveWeightsFromCurrentValue: boolean, 
            weightCalculationType: WeightCalculationType,
            optimizationHorizonInYears: number,
            optimizationRiskLevel: RiskLevel,
            investmentStrategy: InvestmentStrategyType
        }) => void,
    strategySettings: {
        // deriveWeightsFromCurrentValue: boolean, 
        weightCalculationType: WeightCalculationType,
        optimizationHorizonInYears: number,
        optimizationRiskLevel: RiskLevel,
        investmentStrategy: InvestmentStrategyType
    }, productCode: ProductCodeVM) {
    let totalWeight = investmentAllocations.map(a => a.strategyWeight).reduce((a, b) => a + b, 0);

    let newAllocations = [...investmentAllocations,
    {
        spreadOverGrowthRate: 0,
        annualFee: 0,
        strategyWeight: 1 - totalWeight,
        productCode: productCode,
        currentValue: 0
    }
    ];
    newAllocations = calculateWeights(strategySettings.weightCalculationType, newAllocations);
    setInvestmentAllocations(newAllocations, strategySettings)
}



// function calculateWeights(investmentAllocations: InvestmentAccountAllocation[]): InvestmentAccountAllocation[] | InvestmentAllocationRequest[] {
export function calculateWeights(weightCalculationType: WeightCalculationType, investmentAllocations: InvestmentAccountAllocation[] | InvestmentAllocationRequest[]): InvestmentAccountAllocation[] | InvestmentAllocationRequest[] {
    if (weightCalculationType === WeightCalculationType.DeriveWeightsFromCurrentValue) {
        const totalValue = investmentAllocations.map(a => a.currentValue).reduce((a, b) => a + b, 0);
        const isZero = totalValue === 0;
        const zeroWeights = 1 / investmentAllocations.length;
        return investmentAllocations.map(a => ({ ...a, strategyWeight: Math.round((isZero ? zeroWeights : a.currentValue / totalValue) * 1000) / 1000 }));
    }
    // else if (weightCalculationType === WeightCalculationType.Optimized) {
    //     optimizePortfolio(investmentAllocations, props.strategySettings.optimizationHorizonInYears, props.strategySettings.optimizationRiskLevel, settings.currentScenarioSet.id)
    //         .then(r => props.setInvestmentAllocations(r, { ...props.strategySettings }));

    // }
    return investmentAllocations;
}

export function addAllocationAsync(investmentAllocations: InvestmentAccountAllocation[] | InvestmentAllocationRequest[],
    setInvestmentAllocations: (
        newValue: InvestmentAccountAllocation[] | InvestmentAllocationRequest[],
        strategySettings: {
            // deriveWeightsFromCurrentValue: boolean, 
            weightCalculationType: WeightCalculationType,
            optimizationHorizonInYears: number,
            optimizationRiskLevel: RiskLevel,
            investmentStrategy: InvestmentStrategyType
        }) => void,
    strategySettings: {
        // deriveWeightsFromCurrentValue: boolean, 
        weightCalculationType: WeightCalculationType,
        optimizationHorizonInYears: number,
        optimizationRiskLevel: RiskLevel,
        investmentStrategy: InvestmentStrategyType
    },
    productCode: ProductCodeVM,
    currentScenarioSetId: string,
    replaceProductCode: ProductCodeVM = undefined) {
    let totalWeight = investmentAllocations.map(a => a.strategyWeight).reduce((a, b) => a + b, 0);

    let newAllocations = [...investmentAllocations.filter(a => a.productCode.code !== replaceProductCode?.code),
    {
        spreadOverGrowthRate: 0,
        annualFee: 0,
        strategyWeight: 1 - totalWeight,
        productCode: productCode,
        currentValue: 0
    }
    ];
    calculateWeightsAsync(strategySettings.weightCalculationType, newAllocations, currentScenarioSetId, strategySettings)
        .then(newAllocations => setInvestmentAllocations(newAllocations, strategySettings));

}

export function calculateWeightsAsync(weightCalculationType: WeightCalculationType,
    investmentAllocations: InvestmentAccountAllocation[] | InvestmentAllocationRequest[],
    currentScenarioSetId: string,
    strategySettings: {
        weightCalculationType: WeightCalculationType,
        optimizationHorizonInYears: number,
        optimizationRiskLevel: RiskLevel,
        investmentStrategy: InvestmentStrategyType
    })
    : Promise<InvestmentAccountAllocation[] | InvestmentAllocationRequest[]> {
    if (investmentAllocations.length > 0) {
        if (weightCalculationType === WeightCalculationType.DeriveWeightsFromCurrentValue) {
            const totalValue = investmentAllocations.map(a => a.currentValue).reduce((a, b) => a + b, 0);
            const isZero = totalValue === 0;
            const zeroWeights = 1 / investmentAllocations.length;
            return Promise.resolve(investmentAllocations.map(a => ({ ...a, strategyWeight: Math.round((isZero ? zeroWeights : a.currentValue / totalValue) * 1000) / 1000 })));
        }
        else if (weightCalculationType === WeightCalculationType.Optimized) {
            return optimizePortfolioOnlyAllocations(investmentAllocations, strategySettings.optimizationHorizonInYears, strategySettings.optimizationRiskLevel, currentScenarioSetId);
        }
    }
    return Promise.resolve(investmentAllocations);
}

async function optimizePortfolioOnlyAllocations(investmentAllocations: InvestmentAllocationRequest[], horizonInYears: number, riskLevel: RiskLevel, scenarioSetId: string): Promise<InvestmentAllocationRequest[]> {
    const result = await optimizePortfolio(investmentAllocations, horizonInYears, riskLevel, scenarioSetId);
    return result.allocations;
}
