import React, { Fragment, useRef, useState } from 'react';
import { ReferenceLine, Area, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend, Tooltip, ComposedChart, Bar } from 'recharts';
// import { wideOutcomePercentiles, mediumOutcomePercentiles, narrowOutcomePercentiles } from './appConstants';
import { useAppColors } from '../theming/useAppColors';
import { TextNumberFormat } from '../NumberFormatCustom'
import { Alert, IconButton, Paper, Snackbar, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import { Frequency, GroupResultForecastResponse } from '../api_client';
import moment from 'moment';
import { StackedLineChart, BarChart, OpenInFull, Screenshot } from '@mui/icons-material';
import html2canvas from 'html2canvas';


function GroupResultsChart(props: { groupResults: { forecast?: GroupResultForecastResponse[], name: string, color?: string }[], horizon: number, height: any, showLedgend?: boolean, showXAxis?: boolean, startDate?: Date, setGroupChartDialogOpen?: (val: any) => void, observationFrequency: Frequency }) {
    const { groupResults, horizon, height, showLedgend, showXAxis, startDate, setGroupChartDialogOpen, observationFrequency } = props;
    const colors = useAppColors();
    const theme = useTheme();
    const [displayAsBars, setDisplayAsBars] = useState(false);
    const canvasRef = useRef();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const isFullScreen = setGroupChartDialogOpen === undefined;
    const fontSize = isFullScreen ? 12 : 10;


    if (groupResults === undefined || groupResults.length === 0) {
        return <></>;
    }

    const xAxisLength = observationFrequency === Frequency.Annual ? horizon + 1 : horizon * 12 + 1;


    const startYear = startDate ? moment(startDate).toDate().getFullYear() : 0;


    const chartData = groupResults[0].forecast.slice(0, xAxisLength).map((o: any, i: any) => {
        var result = { name: '' + (i), year: (startYear + (observationFrequency === Frequency.Annual ? i : i / 12)) };

        let sum = 0;
        groupResults.forEach((group: any) => {
            sum += group.forecast[i]?.expectedValue;
            result = { ...result, [group.groupId]: group.forecast[i]?.expectedValue }
            // result = { ...result, [group.groupId]: group.forecast[i].expectedValue }
        });

        return { ...result, sum: sum};
    });

    const areaColors = [
        colors.spendingItems.interestPayment,
        colors.spendingItems.investmentAmount,
        colors.spendingItems.monthlyAmortisation,
        colors.spendingItems.taxes,
        colors.spendingItems.ordinaryConsumption,
        colors.spendingItems.maintenanceCost
    ];


    var areas = groupResults
        .map((g: any, i: number) =>
            displayAsBars ? <Bar key={i} name={g.name} animationDuration={500} type="monotone" dataKey={g.groupId} stackId="1" strokeWidth={2} strokeOpacity={1} stroke={g.color !== undefined ? '#999' : "url(#color" + i + ")"} fillOpacity={0.7} fill={g.color !== undefined ? "url(#color" + g.color.replace("#", "") + ")" : "url(#color" + i + ")"} />
                : <Area key={i} name={g.name} animationDuration={500} type="monotone" dataKey={g.groupId} stackId="1" strokeWidth={2} strokeOpacity={1} stroke={g.color !== undefined ? g.color : "url(#color" + i + ")"} fillOpacity={0.5} fill={g.color !== undefined ? "url(#color" + g.color.replace("#", "") + ")" : "url(#color" + i + ")"} />
        )

    var graphColors = groupResults
        .filter(g => g.color !== undefined)
        .map((g: any, i: number) =>
            <linearGradient id={"color" + g.color.replace("#", "")} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={g.color} stopOpacity={1} />
                <stop offset="95%" stopColor={g.color} stopOpacity={0.7} />
            </linearGradient>);

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <Paper style={{ padding: 4, opacity: 0.9 }} square>
                        <Typography variant='body2'>
                            Horizon: {timeStepToHorizon(payload, observationFrequency)}<br />
                            Total: <TextNumberFormat key={'grouptotal'} hidePrefix roundToNearest={1} value={payload[0].payload.sum} /><br />
                            {groupResults.map((g: any, i: any) => {
                                return (<><span style={{ color: g.color !== undefined ? g.color : areaColors[i] }}>&#9679;</span> {g.name}: <TextNumberFormat key={g.id} hidePrefix roundToNearest={1} value={payload[0].payload[g.groupId]} /><br /></>);
                            })}
                        </Typography>
                    </Paper>
                </div>
            );
        }

        return null;
    };


    const renderLegend = (props: any) => {
        const { payload } = props as any;
        return (
            <ul>  
                {
                    payload.map((entry: any, index: any) => (
                        <Fragment key={entry.value}><span style={{ borderWidth: 1, fontSize: 20, color: entry.color !== undefined ? entry.color.replace("url(#color", "#").replace(")", "") : areaColors[index] }}>&#9679;</span> {entry.value}<br /></Fragment>
                    ))
                }
            </ul>
        );
    }

    const CustomizedAxisTick = (props: any) => {
        const {
            x, y, payload,
        } = props;

        return (
            <>
                <TextNumberFormat hidePrefix roundToNearest={1} value={payload.value} style={{ fontSize: fontSize }}
                    renderText={(value: any) =>
                        <g transform={`translate(${x},${y})`}>
                            <text x={0} y={0} dy={2} textAnchor="end" style={{ fontSize: fontSize }} fill={colors.chart.axisLines} >{value}</text>
                        </g>
                    }
                />

            </>
        );
    }

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    return (
        <div ref={canvasRef} style={{ height: height !== undefined ? height : '14rem' }}>
            <Paper style={{ boxShadow: "none", display: 'inline-block', marginTop: theme.spacing(1), marginLeft: theme.spacing(1), padding: '2px' }} >
                {!isFullScreen ?
                    <IconButton onClick={() => setGroupChartDialogOpen(true)} size='small' style={{ marginRight: 2 }}>
                        <OpenInFull fontSize='small' />
                    </IconButton> : <></>}
                <ToggleButtonGroup
                    style={{ display: 'inline-block' }}
                    value={displayAsBars ? 'bar' : 'area'}
                    exclusive
                    onChange={(e, v) => {
                        setDisplayAsBars(v === 'bar')
                    }}
                    aria-label="text alignment"
                >
                    <ToggleButton size='small' value="area" aria-label="left aligned">
                        <StackedLineChart fontSize='small' />
                    </ToggleButton>
                    <ToggleButton size='small' value="bar" aria-label="centered">
                        <BarChart fontSize='small' />
                    </ToggleButton>
                </ToggleButtonGroup>
                {isFullScreen ?
                    <IconButton style={{ marginLeft: 2 }} onClick={() => {
                        html2canvas(canvasRef.current, { backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#eee' }).then(canvas => {
                            try {
                                canvas.toBlob(blob => navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]));
                                setSnackbarOpen(true);
                            } catch (error) {
                                console.log(error);
                            }
                        });
                    }}>
                        <Screenshot fontSize='small' />
                    </IconButton>
                    : <></>}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert severity="success">Image saved to clipboard!</Alert>
                </Snackbar>
            </Paper>
            <ResponsiveContainer width="100%" height="100%">
                <ComposedChart stackOffset='sign' syncId={1} data={chartData} margin={{
                    top: 20, right: 20, left: 23, bottom: 50,
                }}>
                    <defs>
                        <linearGradient id="color0" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={areaColors[0]} stopOpacity={1} />
                            <stop offset="95%" stopColor={areaColors[0]} stopOpacity={1} />
                        </linearGradient>
                        <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={areaColors[1]} stopOpacity={1} />
                            <stop offset="95%" stopColor={areaColors[1]} stopOpacity={1} />
                        </linearGradient>
                        <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={areaColors[2]} stopOpacity={1} />
                            <stop offset="95%" stopColor={areaColors[2]} stopOpacity={1} />
                        </linearGradient>
                        <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={areaColors[3]} stopOpacity={1} />
                            <stop offset="95%" stopColor={areaColors[3]} stopOpacity={1} />
                        </linearGradient>
                        <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={areaColors[4]} stopOpacity={1} />
                            <stop offset="95%" stopColor={areaColors[4]} stopOpacity={1} />
                        </linearGradient>
                        <linearGradient id="color4" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={areaColors[5]} stopOpacity={1} />
                            <stop offset="95%" stopColor={areaColors[5]} stopOpacity={1} />
                        </linearGradient>
                        {graphColors}
                    </defs>
                    <CartesianGrid strokeOpacity={0.3} vertical={false} />
                    {showXAxis ? <XAxis type="number" domain={[startYear, startYear + horizon]} tick={{ fontSize: fontSize, strokeWidth: 0, fill: colors.chart.axisLines }} allowDecimals={false} tickCount={40} interval={'preserveStartEnd'} dataKey="year" /> : <></>}
                    <YAxis width={35} type="number" tick={<CustomizedAxisTick />} />
                    {areas}
                    {/* <Area animationDuration={500} type="monotone" dataKey="firstGroup" stackId="1" strokeOpacity={0.3} stroke="#333333" fillOpacity={1} fill="#1a6b9d" />
                <Area animationDuration={500} type="monotone" dataKey="secondGroup" stackId="1" strokeOpacity={0.3} stroke="#333333" fillOpacity={1} fill="#8fc1d9" /> */}
                    {/* <ReferenceLine stroke="#333333" strokeDasharray="3 3" y={0} /> */}
                    {/* {showLedgend ? <Legend iconType='square' align='center' layout='vertical' verticalAlign='bottom'></Legend> : <></>} */}
                    {showLedgend ? <Legend layout='vertical' iconType='square' content={renderLegend} align='center' verticalAlign='bottom'></Legend> : <></>}
                    <Tooltip content={<CustomTooltip />} />
                </ComposedChart>
            </ResponsiveContainer>
        </div>);
}

function timeStepToHorizon(payload: any, observationFrequency: Frequency) {
    const numYears = observationFrequency === Frequency.Annual ? payload?.[0].payload.name : Math.floor(payload?.[0].payload.name / 12);
    const numMonths = observationFrequency === Frequency.Annual ? 0 : payload?.[0].payload.name - numYears * 12;
    return 'Horizon: ' + numYears + ' years' + (numMonths > 0 ? ' ' + numMonths + ' months' : '');
}

export function areEqual(prevProps: any, nextProps: any) {
    var prevProps2 = Object.assign({}, prevProps);
    delete prevProps2.targetLabel;

    var nextProps2 = Object.assign({}, nextProps);
    delete nextProps2.targetLabel;

    if (JSON.stringify(prevProps2) === JSON.stringify(nextProps2)) {

        return true;
    }
    return false;
}

export default React.memo(GroupResultsChart, areEqual);
