import { Circle, CircleOutlined, CircleTwoTone, Fullscreen, Language, LanguageTwoTone } from "@mui/icons-material";
import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogTitle, Divider, Grid, Paper, Rating, Tab, Tabs, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import { Fragment, useContext, useEffect, useState } from "react";
import { CashFlowObservationsRequest, Domicile, FinancialPlanningV3Request, InflationAdjustmentContract, InvestmentAccountTaxationType, InvestmentStrategyType, ProductCodeContract, ProductCodeType, CurrencyRequest, RiskLevel } from "../../api_client";
import { callFinancialPlanning, getCluster, getDefaultInvestmentGoalPlanningResult, InvestmentGoalPlanningResult, optimizePortfolio } from "../../api_client/tsClient";
import { ClusterFundContract } from "../../clusteringApiClient";
import { ITimeSeriesPoint } from "../../historicalApiClient";
import { staticFundData } from "../../historicalApiClient/funds";
import useHistoricalApi from "../../hooks/useHistoricalApi";
import { SettingsContext } from "../../settings/SettingsContext";
import { useAppColors } from "../../theming/useAppColors";
import PercentileFanChart from "../../visualization/PercentileFanChart";
import { ProductCodeVM } from "../Portfolio";
import { PortfolioStats } from "./PortfolioStats";


export interface FundInfo {
    isin: string;
    rank: number;
    name: string;
    dayReturn: number;
    yearReturn: number;
    fee: number;
    nav: number;
    regions: { name: string, percentage: number }[],
    industries: { name: string, percentage: number }[],
    holdings: { name: string, percentage: number }[],
    rating: number,
    riskLevel: number,
    sustainabilityRating: number,
    assetClasses: { name: string, percentage: number }[],
    description: string;
    currency: string;
}

export function ProductView(props: { open: boolean, handleClose: () => void, selectedFund: ProductCodeVM; setSelectedFund: (newValue: ProductCodeContract) => void; style?: any; }) {

    const theme = useTheme();
    const settings = useContext(SettingsContext);
    const colors = useAppColors();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { selectedFund } = props;

    const { priceIndex } = useHistoricalApi();


    const [selectedPanel, setSelectedPanel] = useState(0);
    const [investmentAllocationsStatistics, setInvestmentAllocationsStatistics] = useState(undefined as { horizon: number, cagr: number, volatility: number }[]);

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedPanel(newValue);
    };

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }



    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <>{children}</>
                )}
            </div>
        );
    }

    const optionsFilter = (options: any, state: any) => {
        return options.filter((o: any) => o.name.toLowerCase().includes(state.inputValue.toLowerCase()));
    };

    const fundsInStatic = staticFundData.funds.map((f: any) => f.isin);
    const funds = settings.mappedFunds.filter(f => f.codeType === ProductCodeType.Custom || fundsInStatic.find(s => s === f.code) !== undefined);

    const [resultState, setResultState] = useState<{ forecast: InvestmentGoalPlanningResult | undefined, referenceForecast: InvestmentGoalPlanningResult | undefined }>({ forecast: getDefaultInvestmentGoalPlanningResult(), referenceForecast: getDefaultInvestmentGoalPlanningResult() });
    const [historicalTimeSeries, setHistoricalTimeSeries] = useState(undefined as ITimeSeriesPoint[]);
    const [cluster, setCluster] = useState(undefined as FundInfo[]);
    const [fundInfo, setFundInfo] = useState(undefined as FundInfo);
    const [horizon, setHorizon] = useState(10);

    useEffect(() => {
        const getFundInfo = (series: ITimeSeriesPoint[], isin: string, rank: number) => {
            if (!series) return;

            series.sort((a, b) => a.pointInTime > b.pointInTime ? 1 : -1);
            const lastPoint = series[series.length - 1];
            const previousYearDate = moment(lastPoint.pointInTime).add(-1, 'years').toDate();
            const previousYear = series.find(p => {
                return moment(p.pointInTime).toDate() >= previousYearDate;
            }
            );

            const staticData = staticFundData.funds.find((f: any) => f.isin === isin);

            return ({
                isin: isin,
                rank: rank,
                name: (settings.mappedFunds.find(f => f.code === isin) as ProductCodeVM).name,
                nav: lastPoint.value,
                dayReturn: ((lastPoint.value / series[series.length - 2].value) - 1) * 100,
                yearReturn: ((lastPoint.value / previousYear.value) - 1) * 100,
                regions: staticData?.geographical_regions,
                fee: staticData?.yearly_fee_pp,
                assetClasses: staticData?.asset_classes,
                holdings: staticData?.holdings.slice(0, 10),
                industries: staticData?.sectors,
                rating: staticData?.morningstar_rating,
                riskLevel: staticData?.risk,
                sustainabilityRating: 3,
                description: staticData?.description,
                currency: staticData?.currecy
            } as FundInfo);
        }

        if (settings.currentScenarioSet === null || settings.currentScenarioSet === undefined || props.selectedFund?.code === undefined)
            return;
        if (props.selectedFund.codeType === ProductCodeType.Isin) {
            priceIndex(props.selectedFund.code)
                .then(async result => {
                    if (!result) return;
                    try {
                        setFundInfo(getFundInfo(result, props.selectedFund.code, 0));
                        const startValue = result?.length > 0 ? result[result.length - 1].value : 1;
                        const request = createRequest(startValue, props.selectedFund, settings.currentScenarioSet.id);
                        callFinancialPlanning(20, request)
                            .then(async result2 => {
                                try {
                                    setHistoricalTimeSeries(result);
                                    setResultState({ forecast: result2 as InvestmentGoalPlanningResult, referenceForecast: undefined });
                                } finally {
                                }
                            }).catch(async e => {
                            });
                    } finally {
                    }
                }).catch(async e => {
                });
            getCluster(props.selectedFund.code)
                .then(async result => {
                    if (result) {
                        const cluster = result
                            .sort((a, b) => a.rank > b.rank ? 1 : -1)
                            .filter(c => c.isin !== props.selectedFund.code)
                            .slice(0, 5)
                            .map(async c => {
                                const series = await priceIndex(c.isin);
                                return getFundInfo(series, c.isin, c.rank);
                            });

                        Promise.all(cluster).then(c => {
                            setCluster(c)
                        });
                    }
                }
                );
        }
        else {
            const request = createRequest(1, props.selectedFund, settings.currentScenarioSet.id);

            callFinancialPlanning(20, request)
                .then(async result => {
                    try {
                        setFundInfo(undefined);
                        setHistoricalTimeSeries([]);
                        setResultState({ forecast: result as InvestmentGoalPlanningResult, referenceForecast: undefined });
                    } finally {
                    }
                }).catch(async e => {
                });
        }
    }, [settings.currentScenarioSet, props.selectedFund, settings.currentScenarioSet.id, settings.mappedFunds, priceIndex])

    useEffect(() => {

        if (settings.currentScenarioSet === null || settings.currentScenarioSet === undefined || selectedFund === undefined)
            return;

        const optimizations = [1, 3, 5, 10].map(async h => ({ horizon: h, result: await optimizePortfolio([{ productCode: selectedFund, annualFee: 0, currentValue: 1, strategyWeight: 1, spreadOverGrowthRate: 0 }], h, RiskLevel.MediumLowRisk, settings.currentScenarioSet.id) }));
        Promise.all(optimizations).then(r => {
            const results = r.map(o => ({ horizon: o.horizon, cagr: o.result.statisticsCurrent?.cagr, volatility: o.result.statisticsCurrent?.volatility }));

            setInvestmentAllocationsStatistics(results);
        });


    }, [settings.currentScenarioSet, settings.currentScenarioSet.id, selectedFund])

    return (
        <Dialog fullScreen={fullScreen} maxWidth={'lg'} PaperProps={{ style: { background: theme.palette.background.default } }} onClose={props.handleClose} aria-labelledby="simulation-dialog-title" open={props.open}>
            <DialogTitle id="simulation-dialog-title"><Grid container>
                <Grid item xs={12} sm>{props.selectedFund ? props.selectedFund?.name : ''}</Grid>
                <Grid item xs={12} sm='auto'>
                    <Autocomplete
                        style={{ ...props.style, width: theme.spacing(36) }}
                        id="combo-box"
                        size="small"
                        filterOptions={optionsFilter}
                        options={funds as any[]}
                        isOptionEqualToValue={(option, value) => option.code === value.code}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, v) => {
                            props.setSelectedFund(v);
                        }}
                        componentsProps={{
                            paper: {
                                sx: {
                                    width: 300
                                }
                            }
                        }}
                        value={props.selectedFund}
                        renderInput={(params) => <TextField {...params} label="Selected fund" variant="outlined" />}
                    />
                </Grid>
            </Grid>
            </DialogTitle>
            {/* {fundInfo !== undefined ? */}
            <Grid container spacing={2} style={{ padding: theme.spacing(2) }} alignItems='stretch'>
                <Grid item xs={12} sm={8} style={{ paddingLeft: theme.spacing(3) }} >
                    {fundInfo !== undefined ? compactInfo([fundInfo], true) : <></>}
                    <Grid item xs={12}>
                        <PercentileFanChart leftPadding={10} height={theme.spacing(36)} historicalTimeSeries={historicalTimeSeries} targetAmount={0} horizon={horizon} hideHorizon={true} currentAge={0} portfolioDevelopmentTimeSeries={resultState?.forecast?.financialPlanningResponseModel?.percentileForecast} referencePortfolioDevelopmentTimeSeries={undefined} filled={true} />
                        {/* {JSON.stringify(resultState)} */}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='caption' color='text.secondary'>Description</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='caption'>{fundInfo?.description}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant='outlined' style={{ padding: theme.spacing(1), marginTop: theme.spacing(1) }}>
                            <PortfolioStats statistics={investmentAllocationsStatistics} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper variant='outlined'
                            sx={{ height: { sm: theme.spacing(24) }, }}
                            style={{ padding: theme.spacing(1), marginTop: theme.spacing(1) }}>
                            <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Similar funds</Typography>
                            {cluster !== undefined ? compactInfo(cluster, false) : <></>}
                        </Paper>
                    </Grid>
                    
                </Grid>
                <Grid item xs={12} sm={4} style={{ display: "flex", flexDirection: "column" }}>
                    <Paper variant='outlined' style={{ padding: theme.spacing(1), marginTop: theme.spacing(1) }}>
                        <Grid container>
                            <Grid item xs>
                                <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Risk</Typography>
                            </Grid>
                            <Grid item xs='auto'>
                                <Rating readOnly size='small'
                                    value={fundInfo?.riskLevel} max={7}
                                    icon={<CircleTwoTone fontSize="inherit" />}
                                    emptyIcon={<CircleOutlined fontSize="inherit" />}
                                    style={{ color: theme.palette.text.primary }} />

                                {/* <Typography>4 of 7</Typography> */}
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container style={{ paddingTop: 2 }}>
                            <Grid item xs>
                                <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Rating</Typography>
                            </Grid>
                            <Grid item xs='auto'>
                                <Rating readOnly size='small' style={{ color: theme.palette.text.primary }} value={fundInfo?.rating} />

                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container style={{ paddingTop: 2 }}>
                            <Grid item xs>
                                <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Type</Typography>
                            </Grid>
                            <Grid item xs='auto'>
                                {fundInfo?.assetClasses?.filter(a => a.percentage >= 5).map((s, i) => <Chip style={{ marginLeft: 2 }} variant="outlined" size="small" key={i} label={s.name}></Chip>)}
                                {/* <Typography>{fundInfo.industries[0].name}</Typography> */}
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container style={{ paddingTop: 2 }}>
                            <Grid item xs>
                                <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Sustainability</Typography>
                            </Grid>
                            <Grid item xs='auto'>
                                <Rating readOnly size='small'
                                    value={fundInfo?.sustainabilityRating}
                                    icon={<LanguageTwoTone fontSize="inherit" />}
                                    emptyIcon={<Language fontSize="inherit" />}
                                    style={{ color: theme.palette.text.primary }} />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container style={{ paddingTop: 2 }}>
                            <Grid item xs>
                                <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>ISIN</Typography>
                            </Grid>
                            <Grid item xs='auto'>
                                <Typography>{fundInfo?.isin}</Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container style={{ paddingTop: 2 }}>
                            <Grid item xs>
                                <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Currency</Typography>
                            </Grid>
                            <Grid item xs='auto'>
                                <Typography>{fundInfo?.currency}</Typography>
                            </Grid>
                        </Grid>

                    </Paper>
                    <Paper variant='outlined' style={{ marginTop: theme.spacing(1), padding: theme.spacing(1) }}>
                        <Grid container style={{ paddingTop: 2 }}>
                            <Grid item xs={12}>
                                <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Asset classes</Typography>
                            </Grid>
                            {fundInfo?.assetClasses?.map(a => <Fragment key={a.name}><Grid item xs>
                                <Typography variant='caption'>{a.name}</Typography>
                            </Grid>
                                <Grid item xs='auto'>
                                    <Typography>{a.percentage}%</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                </Grid></Fragment>)}
                        </Grid>
                    </Paper>
                    <Paper variant='outlined' style={{ marginTop: theme.spacing(1), padding: theme.spacing(1), height: '100%' }}>
                        <Typography variant='caption' style={{ textTransform: "uppercase" }} color='text.secondary'>Exposure</Typography>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs sx={{ minHeight: 'auto', height: 'auto' }}
                                variant="fullWidth" value={selectedPanel} onChange={handleChange} aria-label="basic tabs example">
                                <Tab sx={{ minHeight: 'auto', height: 'auto' }} label={<Typography variant='caption'>Regions</Typography>} {...a11yProps(0)} />
                                <Tab sx={{ minHeight: 'auto', height: 'auto' }} label={<Typography variant='caption'>Industries</Typography>} {...a11yProps(1)} />
                                <Tab sx={{ minHeight: 'auto', height: 'auto' }} label={<Typography variant='caption'>Holdings</Typography>} {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={selectedPanel} index={0}>
                            <Grid container>
                                {fundInfo?.regions?.map(r =>
                                    <Fragment key={r.name}><Grid item xs>
                                        <Typography variant='caption'>{r.name}</Typography>
                                    </Grid>
                                        <Grid item xs='auto'>
                                            <Typography>{r.percentage}%</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                        </Grid></Fragment>)}

                            </Grid>
                        </TabPanel>
                        <TabPanel value={selectedPanel} index={1}>
                            <Grid container>
                                {fundInfo?.industries?.map(r =>
                                    <Fragment key={r.name}>
                                        <Grid item xs>
                                            <Typography variant='caption'>{r.name}</Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography>{r.percentage}%</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                        </Grid>
                                    </Fragment>)}
                            </Grid>

                        </TabPanel>
                        <TabPanel value={selectedPanel} index={2}>
                            <Grid container>

                                {fundInfo?.holdings?.map(r =>
                                    <Fragment key={r.name}>
                                        <Grid item xs>
                                            <Typography variant='caption'>{r.name}</Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography>{r.percentage}%</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                        </Grid>
                                    </Fragment>)}
                            </Grid>

                        </TabPanel>
                    </Paper>
                </Grid>
            </Grid>
            {/* : <></>} */}
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Done
                </Button>
            </DialogActions>
        </Dialog>

    );

    function compactInfo(fundInfos: FundInfo[], hideNames: boolean) {

        const round = (val: number) => Math.round((val + Number.EPSILON) * 100) / 100;
        const color = (val: number) => round(val) === 0 ? theme.palette.text.secondary : val > 0 ? colors.spendingItems.investmentAmount : colors.spendingItems.interestPayment;

        const itemWidth = 7;

        const style = hideNames ? { width: theme.spacing(28) } : {};

        return fundInfo !== undefined ? <Grid container style={style}>
            {hideNames ? <></> : <Grid item xs>
                <Typography variant='caption' color='text.secondary'>Name</Typography><br />
            </Grid>}
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption' color='text.secondary'>1D %</Typography><br />
                </div>
            </Grid>
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption' color='text.secondary'>1Y %</Typography><br />
                </div>
            </Grid>
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption' color='text.secondary'>Fee %</Typography><br />
                </div>
            </Grid>
            <Grid item xs='auto'>
                <div style={{ width: theme.spacing(itemWidth) }}>
                    <Typography variant='caption' color='text.secondary'>NAV</Typography><br />
                </div>
            </Grid>
            <Grid item xs={12}></Grid>
            {fundInfos.map(fundInfo =>
                <Fragment key={fundInfo.name}>
                    {hideNames ? <></> : <Grid item xs>
                        <Typography variant='caption'>{fundInfo.name}</Typography>
                    </Grid>}
                    <Grid item xs='auto'>
                        <div style={{ width: theme.spacing(itemWidth) }}>
                            <span style={{ color: color(fundInfo.dayReturn) }}>{round(fundInfo.dayReturn)}%</span>
                        </div>
                    </Grid>
                    <Grid item xs='auto'>
                        <div style={{ width: theme.spacing(itemWidth) }}>
                            <span style={{ color: color(fundInfo.yearReturn) }}>{round(fundInfo.yearReturn)}%</span>
                        </div>
                    </Grid>
                    <Grid item xs='auto'>
                        <div style={{ width: theme.spacing(itemWidth) }}>
                            {fundInfo.fee !== undefined ? fundInfo.fee + '%' : '--'}
                        </div>
                    </Grid>
                    <Grid item xs='auto'>
                        <div style={{ width: theme.spacing(itemWidth) }}>
                            {fundInfo.nav}
                        </div>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Fragment>
            )}
        </Grid> : <></>;
    }
}

function createRequest(initialValue: number, selectedFund: ProductCodeVM, scenarioSet: string): FinancialPlanningV3Request {
    const monthlyContributionAccountId = 'c43fca7d-ab42-4efc-9b7f-1463a3a5c795';
    const startDate = moment("2023-01-01T00:00:00.000Z");

    const numYears = 40;

    const observationDates = [...Array(numYears + 1).keys()].map(index => startDate.clone().add(index, 'years').toDate());

    return {
        baseCurrency: CurrencyRequest.SEK,
        simulationSettings: {
            scenarioSetId: scenarioSet,
            percentiles: [0.9, 0.5, 0.1],
            cashFlowObservations: CashFlowObservationsRequest.None,
            inflationAdjustment: InflationAdjustmentContract.NoAdjustment,
            observationTimeSteps: observationDates.map((_, i) => i * 12)
        },
        startDate: { year: startDate.year(), month: startDate.month() + 1 },
        assetsAndDebts: {
            investmentAccounts: [
                {
                    taxationType: InvestmentAccountTaxationType.NoTax,
                    annualAccountFee: 0,
                    id: monthlyContributionAccountId,
                    strategy: InvestmentStrategyType.RebalanceToPlan,
                    investmentAllocations: [{
                        acquisitionValue: initialValue,
                        annualFee: 0,
                        currentValue: initialValue,
                        productCode: selectedFund,
                        spreadOverGrowthRate: 0,
                        strategyWeight: 1
                    }]
                }]
        },
        cashFlows: {
        },
        taxInformation: {
            sweTax: { municipalityTaxRate: 0.31 },
            taxPaymentAssetsAndDebtsItemId: monthlyContributionAccountId,
        },
        domicile: Domicile.Swe,
        dateOfBirth: { year: 1978, month: 9 },
    };
}
