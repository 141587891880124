import { AccountCircle, Add, BusinessCenter, Delete, Done, Edit, FeedSharp, Home, HomeOutlined, Percent, Warning } from "@mui/icons-material";
import { Badge, Button, Collapse, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Stack, SvgIcon, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useContext } from "react";
import { InvestmentAccountRequest, InvestmentAccountTaxationType, InvestmentStrategyType, RetirementRateRequest, RiskLevel } from "../../api_client";
import { DisplayState, InvestmentAccountVM, NationalPensionVM, PayoutSchemeVM, ProductCodeVM, WeightCalculationType } from "../Portfolio";
import { CurrencyFormatCustom, NumberFormatCustom, PercentFormatCustom, TextNumberFormat } from "../../NumberFormatCustom";
import { SettingsContext } from "../../settings/SettingsContext";
import FundSelect from "./FundSelect";
import { InvestmentAllocationsView } from "./InvestmentAllocationsView";
import { AssetsAndDebtsDisplayHeader } from "./AssetsAndDebtsDisplayHeader";

export function NationalPensionView(props: { nationalPension: NationalPensionVM, setNationalPension: (newValue: NationalPensionVM) => void, onDelete: () => void }) {

    const pension = props.nationalPension as NationalPensionVM;
    const setInvestmentAccount = props.setNationalPension;
    const theme = useTheme();
    const settings = useContext(SettingsContext);
    //ProductCode must only appear once per account

    const hasDuplicate = Object.values(pension.premiumPensionAccount.investmentAllocations.map(a => a.productCode?.code).reduce((total: any, value) => {
        total[value] = (total[value] || 0) + 1;
        return total;
    }, {})).find(count => count > 1) !== undefined;

    const hasInvalidWeight = Math.abs(1 - pension.premiumPensionAccount.investmentAllocations.reduce((a, b) => a + b.strategyWeight, 0)) > 0.01;

    const errorText = (hasInvalidWeight ? 'Total weight of allocations must be 100%. ' : '') + (hasDuplicate ? 'Product must only appear once per account.' : '');
    return (
        <div>
            <Collapse in={pension.displayState === DisplayState.Edit || pension.displayState === undefined} timeout='auto' unmountOnExit>
                <Grid container>
                    <Grid item xs>
                        <TextField
                            variant='filled'
                            disabled={true}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <BusinessCenter />
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginBottom: theme.spacing(0) }}
                            margin='none'
                            size='small'
                            fullWidth={true}
                            required label={'Name'}
                            onChange={(e) => {
                                setInvestmentAccount({ ...pension, name: e.target.value });
                            }}
                            value={pension.name} />
                    </Grid>
                    <Grid item xs='auto'>
                        <Tooltip title={errorText}>
                            <Badge color='warning' badgeContent={errorText === '' ? undefined : '!'}>
                                <IconButton style={{ marginLeft: theme.spacing(1), marginTop: 4 }} onClick={() => setInvestmentAccount({ ...pension, displayState: DisplayState.Display })}>
                                    <Done />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs>
                        <Typography style={{ paddingTop: theme.spacing(1), paddingLeft: theme.spacing(1) }} variant='body2'>Retirement plan</Typography>
                    </Grid>
                    <Grid item xs='auto'>
                        <IconButton onClick={() => {
                            setInvestmentAccount({
                                ...pension,
                                payoutScheme: [...pension.payoutScheme, { retirementRate: RetirementRateRequest.Percent100, ageOfRetirement: 66, timeStep: 250 } as PayoutSchemeVM]
                            })

                        }}>
                            <Add />
                        </IconButton >
                    </Grid>
                </Grid>
                {pension.payoutScheme.map((ar, i) => {
                    const a = ar as PayoutSchemeVM;
                    return (<div
                        key={i + ''}
                        style={{ marginBottom: theme.spacing(0) }}>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <TextField
                                    error={a.ageOfRetirement < 62 || a.ageOfRetirement > 70}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ inputComponent: NumberFormatCustom }}
                                    style={{ marginBottom: theme.spacing(1) }}
                                    margin='none'
                                    size="small"
                                    fullWidth={true}
                                    required
                                    label='Age'
                                    onChange={(e) => {
                                        setInvestmentAccount({ ...pension, payoutScheme: pension.payoutScheme.map((ia, ii) => i === ii ? { ...ia, ageOfRetirement: Number(e.target.value) } : ia) });
                                    }}
                                    value={a.ageOfRetirement} />
                            </Grid>
                            <Grid item xs='auto'>
                                <FormControl size="small">
                                    <InputLabel id="strategy-select">Rate</InputLabel>
                                    <Select label="Rate"
                                        labelId="strategy-select"
                                        style={{ width: theme.spacing(14) }}
                                        value={a.retirementRate} onChange={e => {
                                            setInvestmentAccount({ ...pension, payoutScheme: pension.payoutScheme.map((ia, ii) => i === ii ? { ...ia, retirementRate: e.target.value as RetirementRateRequest } : ia) });
                                        }}>
                                        <MenuItem value={RetirementRateRequest.Percent25}>25%</MenuItem>
                                        <MenuItem value={RetirementRateRequest.Percent50}>50%</MenuItem>
                                        <MenuItem value={RetirementRateRequest.Percent75}>75%</MenuItem>
                                        <MenuItem value={RetirementRateRequest.Percent100}>100%</MenuItem>
                                    </Select>
                                </FormControl>
                                {/* {a.timeStep} */}

                            </Grid>

                            <Grid item xs='auto'>
                                <IconButton
                                    onClick={(e) => {
                                        setInvestmentAccount({
                                            ...pension,
                                            payoutScheme: pension.payoutScheme.filter((ia, ii) => i !== ii)
                                        })
                                    }}>
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>)
                })}
                <Grid item xs={12}>
                    <Typography variant="body2" style={{ paddingLeft: theme.spacing(1) }}>Income pension</Typography>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputComponent: CurrencyFormatCustom }}
                        style={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(1) }}
                        margin='none'
                        size="small"
                        fullWidth={true}
                        required
                        label='Current value'
                        onChange={(e) => {
                            setInvestmentAccount({
                                ...pension,
                                incomePensionAccount: {
                                    ...pension.incomePensionAccount,
                                    currentValue: Number(e.target.value)
                                }
                            })
                        }}
                        value={pension.incomePensionAccount.currentValue} />
                </Grid>
                <InvestmentAllocationsView
                    title="Premium pension allocations"
                    investmentAllocations={pension.premiumPensionAccount.investmentAllocations}
                    setInvestmentAllocations={(e, s) => {
                        setInvestmentAccount({
                            ...pension,
                            // deriveWeightsFromCurrentValue: s.deriveWeightsFromCurrentValue,
                            weightCalculationType: s.weightCalculationType,
                            optimizationHorizonInYears: s.optimizationHorizonInYears,
                            optimizationRiskLevel: s.optimizationRiskLevel,
                            premiumPensionAccount: {
                                ...pension.premiumPensionAccount,
                                investmentAllocations: e,
                                strategy: s.investmentStrategy
                            }
                        })
                    }}
                    strategySettings={{
                        // deriveWeightsFromCurrentValue: pension.deriveWeightsFromCurrentValue ? pension.deriveWeightsFromCurrentValue : false, 
                        weightCalculationType: pension.weightCalculationType !== undefined ? pension.weightCalculationType : WeightCalculationType.NoCalculation,
                        optimizationHorizonInYears: pension.optimizationHorizonInYears !== undefined ? pension.optimizationHorizonInYears : 10,
                        optimizationRiskLevel: pension.optimizationRiskLevel !== undefined ? pension.optimizationRiskLevel : RiskLevel.MediumHighRisk,
                        investmentStrategy: pension.premiumPensionAccount.strategy
                    }}
                // setStrategySettings={(newValue: { deriveWeightsFromCurrentValue: boolean, investmentStrategy: InvestmentStrategyType }) => {
                //     setInvestmentAccount({
                //         ...pension,
                //         deriveWeightsFromCurrentValue: newValue.deriveWeightsFromCurrentValue,
                //         premiumPensionAccount: {
                //             ...pension.premiumPensionAccount,
                //             strategy: newValue.investmentStrategy
                //         }
                //     })
                // }}
                />
                <div style={{ paddingTop: theme.spacing(1) }}></div>
                {/* <FormControl fullWidth={true} size="small">
                    <InputLabel id="strategy-select">Strategy</InputLabel>
                    <Select label="Strategy"
                        labelId="strategy-select"
                        fullWidth={true} value={pension.premiumPensionAccount.strategy} onChange={e => {
                            setInvestmentAccount({ ...pension, premiumPensionAccount: { ...pension.premiumPensionAccount, strategy: e.target.value as InvestmentStrategyType } });
                        }}>
                        <MenuItem value={InvestmentStrategyType.BuyAndHold}>Buy and hold</MenuItem>
                        <MenuItem value={InvestmentStrategyType.RebalanceToPlan}>Rebalance to plan</MenuItem>
                    </Select>
                </FormControl> */}
            </Collapse>
            <Collapse in={pension.displayState === DisplayState.Display} timeout="auto" unmountOnExit>
                <AssetsAndDebtsDisplayHeader
                    name={pension.name}
                    currentValue={pension.premiumPensionAccount.investmentAllocations?.reduce((accumulator, currentValue) => accumulator + currentValue.currentValue, 0) + pension.incomePensionAccount.currentValue}
                    delete={props.onDelete}
                    color={pension.color}
                    edit={() => setInvestmentAccount({ ...pension, displayState: DisplayState.Edit })}
                    errorText={errorText} />
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="caption" color='text.secondary'>{pension.premiumPensionAccount.strategy}</Typography>
                    </Grid>
                    {pension.premiumPensionAccount.investmentAllocations.map((a, i) => {
                        return (<Grid key={a?.productCode?.code} item xs={12}>
                            <Grid container>
                                <Grid item xs>
                                    <Typography style={{ marginLeft: theme.spacing(1) }} variant="caption">{(a.productCode as ProductCodeVM)?.name}</Typography>
                                </Grid>
                                <Grid item xs='auto'>
                                    <Typography style={{ paddingRight: theme.spacing(1) }} variant="caption">{Math.round(a.strategyWeight * 10000) / 100}%</Typography>
                                </Grid>
                            </Grid>
                        </Grid>)
                    })}

                </Grid>
            </Collapse>
        </div>
    );

}

export function areEqual(prevProps: any, nextProps: any) {

    if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
        return true;
    }
    return false;
}

export default React.memo(NationalPensionView);