import { Slider, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { OutcomeResponse, PercentileForecastResponse } from "../api_client";
import { SettingsContext } from "../settings/SettingsContext";
import PercentileFanChart from "../visualization/PercentileFanChart";

export default function GoalChart(props: {
    goals: { horizonInYears: number, name: string }[],
    setGoals: any,
    portfolioDevelopmentTimeSeries: PercentileForecastResponse[],
    referencePortfolioDevelopmentTimeSeries?: PercentileForecastResponse[],
    horizon?: number,
    setPercentileChartDialogOpen?: (val: any) => void,
    height: any,
    showLedgend?: boolean,
    showXAxis?: boolean,
    startDate?: Date
}) {
    const theme = useTheme();
    const settings = useContext(SettingsContext);

    const { goals, setGoals, portfolioDevelopmentTimeSeries, referencePortfolioDevelopmentTimeSeries, horizon, setPercentileChartDialogOpen, height, showLedgend, showXAxis, startDate } = props;

    const [currenctSliderIndex, setCurrenctSliderIndex] = useState(-1);

    // const goals = props.goals;

    const sliderIndices = goals.map((g, i) => ({ horizonInYears: g.horizonInYears, index: i })).sort((a, b) => a.horizonInYears - b.horizonInYears);
    const horizons = sliderIndices.map(g => g.horizonInYears);

    const horizonSliderUpdated = (event: any, values: any, index: any) => {
        let goalIndex = sliderIndices[index].index;
        if (currenctSliderIndex === -1)
            setCurrenctSliderIndex(goalIndex);
        else
            goalIndex = currenctSliderIndex;

        const newGoals = goals.map(a => { return { ...a } });
        newGoals[goalIndex].horizonInYears = values[index];
        setGoals(newGoals);
    };

    const horizonSliderComitted = (event: React.SyntheticEvent | Event, value: number | Array<number>) => {
        setCurrenctSliderIndex(-1);
    }
    const marks = [
        {
            value: 0,
            label: '0',
        }, ...([...Array(horizon / 10).keys()].map((i: any) => ({ value: (i + 1) * 10, label: '' + (i + 1) * 10 })))
    ];

    return (
        <div>
            <PercentileFanChart showLedgend={showLedgend} height={height} targetAmount={undefined} showXAxis={showXAxis} horizon={horizon} hideHorizon={true} currentAge={0} portfolioDevelopmentTimeSeries={portfolioDevelopmentTimeSeries} referencePortfolioDevelopmentTimeSeries={referencePortfolioDevelopmentTimeSeries} startDate={startDate} filled={settings.chartSettings.fanFilled} setPercentileChartDialogOpen={setPercentileChartDialogOpen} />
            {showXAxis ? <></>
                : <div style={{ paddingLeft: theme.spacing(7), paddingRight: 20 }}>
                    <Slider
                        track={false}
                        onChange={horizonSliderUpdated}
                        onChangeCommitted={horizonSliderComitted}
                        size='small'
                        disabled={goals.length === 0}
                        value={horizons}
                        valueLabelDisplay={'on'}
                        valueLabelFormat={(value, index) => {
                            return goals[sliderIndices[index].index].name + ' in ' + value + ' years';
                        }}
                        max={horizon}
                        // disableSwap={true}
                        marks={marks}

                    />
                </div>
            }
        </div>
    );
}