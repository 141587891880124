export const wideOutcomePercentiles = [
    0.1, 0.9
];

export const mediumOutcomePercentiles = [
    0.30, 0.70
];

export const narrowOutcomePercentiles = [
    0.45, 0.55
];

export const percentiles = [
    wideOutcomePercentiles[0], 
    mediumOutcomePercentiles[0], 
    narrowOutcomePercentiles[0], 
    0.5, 
    narrowOutcomePercentiles[1], 
    mediumOutcomePercentiles[1], 
    wideOutcomePercentiles[1]
];

