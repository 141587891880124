/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Portfolio Analysis API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface FeeRequestModel
 */
export interface FeeRequestModel {
    /**
     * 
     * @type {number}
     * @memberof FeeRequestModel
     */
    annualFee?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeRequestModel
     */
    performanceFee?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeRequestModel
     */
    purchaseFee?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeRequestModel
     */
    salesFee?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeRequestModel
     */
    transactionCost?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Frequency {
    Monthly = <any> 'Monthly',
    Annual = <any> 'Annual'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InflationAdjustmentContract {
    NoAdjustment = <any> 'NoAdjustment',
    ValueAsOfStartDate = <any> 'ValueAsOfStartDate'
}
/**
 * 
 * @export
 * @interface InvestmentAccountRequestModel
 */
export interface InvestmentAccountRequestModel {
    /**
     * 
     * @type {string}
     * @memberof InvestmentAccountRequestModel
     */
    id: string;
    /**
     * 
     * @type {Array<InvestmentAllocationRequestModel>}
     * @memberof InvestmentAccountRequestModel
     */
    investmentAllocations: Array<InvestmentAllocationRequestModel>;
    /**
     * The type of taxation the account is subject to
     * @type {InvestmentAccountTaxationType}
     * @memberof InvestmentAccountRequestModel
     */
    taxationType: InvestmentAccountTaxationType;
    /**
     * 
     * @type {InvestmentStrategyType}
     * @memberof InvestmentAccountRequestModel
     */
    strategy: InvestmentStrategyType;
    /**
     * Typically between 0 and 1
     * @type {number}
     * @memberof InvestmentAccountRequestModel
     */
    annualAccountFee?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InvestmentAccountTaxationType {
    NoTax = <any> 'NoTax',
    SweIsk = <any> 'SweIsk',
    SweEndowmentInsurance = <any> 'SweEndowmentInsurance',
    GbrIsa = <any> 'GbrIsa',
    GbrSipp = <any> 'GbrSipp',
    SweCapitalGains = <any> 'SweCapitalGains'
}
/**
 * 
 * @export
 * @interface InvestmentAllocationRequestModel
 */
export interface InvestmentAllocationRequestModel {
    /**
     * Fees typically have a value between 0 and 1
     * @type {FeeRequestModel}
     * @memberof InvestmentAllocationRequestModel
     */
    fees: FeeRequestModel;
    /**
     * Typically between 0 and 1
     * @type {number}
     * @memberof InvestmentAllocationRequestModel
     */
    outperformanceAssumption?: number;
    /**
     * Sum of all investment allocation weights must be 1
     * @type {number}
     * @memberof InvestmentAllocationRequestModel
     */
    weight?: number;
    /**
     * Acquired from /product/mapped-funds
     * @type {ProductCodeContract}
     * @memberof InvestmentAllocationRequestModel
     */
    productCode: ProductCodeContract;
    /**
     * 
     * @type {number}
     * @memberof InvestmentAllocationRequestModel
     */
    acquisitionValue?: number;
    /**
     * 
     * @type {number}
     * @memberof InvestmentAllocationRequestModel
     */
    currentValue?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum InvestmentStrategyType {
    RebalanceToPlan = <any> 'RebalanceToPlan',
    BuyAndHold = <any> 'BuyAndHold'
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Level {
    Low = <any> 'Low',
    Medium = <any> 'Medium',
    High = <any> 'High'
}
/**
 * 
 * @export
 * @interface MarketContractFundamentalsRequest
 */
export interface MarketContractFundamentalsRequest {
    /**
     * 
     * @type {Array<ProductCodeContract>}
     * @memberof MarketContractFundamentalsRequest
     */
    contractsToCompare?: Array<ProductCodeContract>;
}
/**
 * 
 * @export
 * @interface MarketContractFundamentalsResponse
 */
export interface MarketContractFundamentalsResponse {
    /**
     * 
     * @type {ProductCodeContract}
     * @memberof MarketContractFundamentalsResponse
     */
    marketContract?: ProductCodeContract;
    /**
     * 
     * @type {Array<StatisticsResultResponse>}
     * @memberof MarketContractFundamentalsResponse
     */
    results?: Array<StatisticsResultResponse>;
}
/**
 * 
 * @export
 * @interface OneTimeTransaction
 */
export interface OneTimeTransaction {
    /**
     * The id of the account from which a transaction will be withdrawn
     * @type {string}
     * @memberof OneTimeTransaction
     */
    sourceId?: string;
    /**
     * The id of the account to which a transaction will be added
     * @type {string}
     * @memberof OneTimeTransaction
     */
    targetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof OneTimeTransaction
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof OneTimeTransaction
     */
    amount?: number;
    /**
     * 
     * @type {InflationAdjustmentContract}
     * @memberof OneTimeTransaction
     */
    inflationAdjustment?: InflationAdjustmentContract;
}
/**
 * 
 * @export
 * @interface PortfolioAnalysisMarketContractFundamentalsBody
 */
export interface PortfolioAnalysisMarketContractFundamentalsBody extends MarketContractFundamentalsRequest {
}
/**
 * 
 * @export
 * @interface PortfolioAnalysisMarketContractFundamentalsBody1
 */
export interface PortfolioAnalysisMarketContractFundamentalsBody1 extends MarketContractFundamentalsRequest {
}
/**
 * 
 * @export
 * @interface PortfolioAnalysisMarketContractFundamentalsBody2
 */
export interface PortfolioAnalysisMarketContractFundamentalsBody2 extends MarketContractFundamentalsRequest {
}
/**
 * 
 * @export
 * @interface PortfolioAnalysisMarketContractFundamentalsBody3
 */
export interface PortfolioAnalysisMarketContractFundamentalsBody3 extends MarketContractFundamentalsRequest {
}
/**
 * 
 * @export
 * @interface PortfolioAnalysisPortfolioStatisticsWithLevelsBody
 */
export interface PortfolioAnalysisPortfolioStatisticsWithLevelsBody extends PortfolioStatisticsWithLevelsRequest {
}
/**
 * 
 * @export
 * @interface PortfolioAnalysisPortfolioStatisticsWithLevelsBody1
 */
export interface PortfolioAnalysisPortfolioStatisticsWithLevelsBody1 extends PortfolioStatisticsWithLevelsRequest {
}
/**
 * 
 * @export
 * @interface PortfolioAnalysisPortfolioStatisticsWithLevelsBody2
 */
export interface PortfolioAnalysisPortfolioStatisticsWithLevelsBody2 extends PortfolioStatisticsWithLevelsRequest {
}
/**
 * 
 * @export
 * @interface PortfolioAnalysisPortfolioStatisticsWithLevelsBody3
 */
export interface PortfolioAnalysisPortfolioStatisticsWithLevelsBody3 extends PortfolioStatisticsWithLevelsRequest {
}
/**
 * 
 * @export
 * @interface PortfolioAnalysisPortfolioStressScenarioSetBody
 */
export interface PortfolioAnalysisPortfolioStressScenarioSetBody extends PortfolioStressScenarioSetRequest {
}
/**
 * 
 * @export
 * @interface PortfolioAnalysisPortfolioStressScenarioSetBody1
 */
export interface PortfolioAnalysisPortfolioStressScenarioSetBody1 extends PortfolioStressScenarioSetRequest {
}
/**
 * 
 * @export
 * @interface PortfolioAnalysisPortfolioStressScenarioSetBody2
 */
export interface PortfolioAnalysisPortfolioStressScenarioSetBody2 extends PortfolioStressScenarioSetRequest {
}
/**
 * 
 * @export
 * @interface PortfolioAnalysisPortfolioStressScenarioSetBody3
 */
export interface PortfolioAnalysisPortfolioStressScenarioSetBody3 extends PortfolioStressScenarioSetRequest {
}
/**
 * 
 * @export
 * @interface PortfolioStatisticsWithLevelsRequest
 */
export interface PortfolioStatisticsWithLevelsRequest {
    /**
     * 
     * @type {string}
     * @memberof PortfolioStatisticsWithLevelsRequest
     */
    balanceSheetPredictContextId?: string;
    /**
     * 
     * @type {InvestmentAccountRequestModel}
     * @memberof PortfolioStatisticsWithLevelsRequest
     */
    portfolio?: InvestmentAccountRequestModel;
    /**
     * 
     * @type {Transactions}
     * @memberof PortfolioStatisticsWithLevelsRequest
     */
    transactions?: Transactions;
    /**
     * 
     * @type {Array<number>}
     * @memberof PortfolioStatisticsWithLevelsRequest
     */
    timesteps?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PortfolioStatisticsWithLevelsRequest
     */
    varAndESPercentiles?: number;
    /**
     * 
     * @type {number}
     * @memberof PortfolioStatisticsWithLevelsRequest
     */
    targetValue?: number;
    /**
     * 
     * @type {UtilityParametersRequest}
     * @memberof PortfolioStatisticsWithLevelsRequest
     */
    utilityParameters?: UtilityParametersRequest;
}
/**
 * 
 * @export
 * @interface PortfolioStatisticsWithLevelsResponse
 */
export interface PortfolioStatisticsWithLevelsResponse {
    /**
     * 
     * @type {Array<ValueWithLevel>}
     * @memberof PortfolioStatisticsWithLevelsResponse
     */
    expectedReturn?: Array<ValueWithLevel>;
    /**
     * 
     * @type {Array<ValueWithLevel>}
     * @memberof PortfolioStatisticsWithLevelsResponse
     */
    sharpeRatio?: Array<ValueWithLevel>;
    /**
     * 
     * @type {Array<ValueWithLevel>}
     * @memberof PortfolioStatisticsWithLevelsResponse
     */
    diversification?: Array<ValueWithLevel>;
    /**
     * 
     * @type {Array<ValueWithLevel>}
     * @memberof PortfolioStatisticsWithLevelsResponse
     */
    probabilityToExceedValue?: Array<ValueWithLevel>;
    /**
     * 
     * @type {Array<ValueWithLevel>}
     * @memberof PortfolioStatisticsWithLevelsResponse
     */
    valueAtRisk?: Array<ValueWithLevel>;
    /**
     * 
     * @type {Array<ValueWithLevel>}
     * @memberof PortfolioStatisticsWithLevelsResponse
     */
    expectedShortfall?: Array<ValueWithLevel>;
    /**
     * 
     * @type {Array<ValueWithLevel>}
     * @memberof PortfolioStatisticsWithLevelsResponse
     */
    utility?: Array<ValueWithLevel>;
}
/**
 * 
 * @export
 * @interface PortfolioStressScenarioResponse
 */
export interface PortfolioStressScenarioResponse {
    /**
     * 
     * @type {string}
     * @memberof PortfolioStressScenarioResponse
     */
    scenarioName: string;
    /**
     * 
     * @type {number}
     * @memberof PortfolioStressScenarioResponse
     */
    value: number;
}
/**
 * 
 * @export
 * @interface PortfolioStressScenarioSetRequest
 */
export interface PortfolioStressScenarioSetRequest {
    /**
     * 
     * @type {string}
     * @memberof PortfolioStressScenarioSetRequest
     */
    balanceSheetPredictContextId?: string;
    /**
     * 
     * @type {InvestmentAccountRequestModel}
     * @memberof PortfolioStressScenarioSetRequest
     */
    portfolio: InvestmentAccountRequestModel;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ProductCodeCodeType {
    Insurance = <any> 'Insurance',
    Isin = <any> 'Isin'
}
/**
 * 
 * @export
 * @interface ProductCodeContract
 */
export interface ProductCodeContract {
    /**
     * 
     * @type {ProductCodeCodeType}
     * @memberof ProductCodeContract
     */
    codeType: ProductCodeCodeType;
    /**
     * 
     * @type {string}
     * @memberof ProductCodeContract
     */
    code: string;
}
/**
 * 
 * @export
 * @interface RecurringTransaction
 */
export interface RecurringTransaction {
    /**
     * The id of the account from which a transaction will be withdrawn
     * @type {string}
     * @memberof RecurringTransaction
     */
    sourceId?: string;
    /**
     * The id of the account to which a transaction will be added
     * @type {string}
     * @memberof RecurringTransaction
     */
    targetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof RecurringTransaction
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof RecurringTransaction
     */
    endDate: Date;
    /**
     * How often the transaction occurs
     * @type {Frequency}
     * @memberof RecurringTransaction
     */
    frequency: Frequency;
    /**
     * 
     * @type {number}
     * @memberof RecurringTransaction
     */
    amount: number;
    /**
     * 
     * @type {InflationAdjustmentContract}
     * @memberof RecurringTransaction
     */
    inflationAdjustment?: InflationAdjustmentContract;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum StatisticsClassificationResponse {
    Low = <any> 'Low',
    Medium = <any> 'Medium',
    High = <any> 'High'
}
/**
 * 
 * @export
 * @interface StatisticsResultResponse
 */
export interface StatisticsResultResponse {
    /**
     * 
     * @type {Date}
     * @memberof StatisticsResultResponse
     */
    periodStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StatisticsResultResponse
     */
    periodEnd?: Date;
    /**
     * 
     * @type {number}
     * @memberof StatisticsResultResponse
     */
    totalReturn?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsResultResponse
     */
    sharpe?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsResultResponse
     */
    expectedShortfall?: number;
    /**
     * 
     * @type {StatisticsClassificationResponse}
     * @memberof StatisticsResultResponse
     */
    relativeTotalReturnClassification?: StatisticsClassificationResponse;
    /**
     * 
     * @type {StatisticsClassificationResponse}
     * @memberof StatisticsResultResponse
     */
    absoluteTotalReturnClassification?: StatisticsClassificationResponse;
    /**
     * 
     * @type {StatisticsClassificationResponse}
     * @memberof StatisticsResultResponse
     */
    relativeSharpeClassification?: StatisticsClassificationResponse;
    /**
     * 
     * @type {StatisticsClassificationResponse}
     * @memberof StatisticsResultResponse
     */
    absoluteSharpeClassification?: StatisticsClassificationResponse;
    /**
     * 
     * @type {StatisticsClassificationResponse}
     * @memberof StatisticsResultResponse
     */
    relativeExpectedShortfallClassification?: StatisticsClassificationResponse;
    /**
     * 
     * @type {StatisticsClassificationResponse}
     * @memberof StatisticsResultResponse
     */
    absoluteExpectedShortfallClassification?: StatisticsClassificationResponse;
}
/**
 * 
 * @export
 * @interface TransactionArray
 */
export interface TransactionArray {
    /**
     * The id of the account from which a transaction will be withdrawn
     * @type {string}
     * @memberof TransactionArray
     */
    sourceId?: string;
    /**
     * The id of the account to which a transaction will be added
     * @type {string}
     * @memberof TransactionArray
     */
    targetId?: string;
    /**
     * 
     * @type {Date}
     * @memberof TransactionArray
     */
    startDate?: Date;
    /**
     * How often the transaction occurs
     * @type {Frequency}
     * @memberof TransactionArray
     */
    frequency?: Frequency;
    /**
     * 
     * @type {Array<number>}
     * @memberof TransactionArray
     */
    amounts?: Array<number>;
    /**
     * 
     * @type {InflationAdjustmentContract}
     * @memberof TransactionArray
     */
    inflationAdjustment?: InflationAdjustmentContract;
}
/**
 * 
 * @export
 * @interface Transactions
 */
export interface Transactions {
    /**
     * 
     * @type {Array<OneTimeTransaction>}
     * @memberof Transactions
     */
    oneTimeTransactions: Array<OneTimeTransaction>;
    /**
     * 
     * @type {Array<RecurringTransaction>}
     * @memberof Transactions
     */
    recurringTransactions: Array<RecurringTransaction>;
    /**
     * 
     * @type {Array<TransactionArray>}
     * @memberof Transactions
     */
    transactionArrays: Array<TransactionArray>;
    /**
     * 
     * @type {boolean}
     * @memberof Transactions
     */
    hasTransactions?: boolean;
}
/**
 * 
 * @export
 * @interface UtilityParametersRequest
 */
export interface UtilityParametersRequest {
    /**
     * 
     * @type {number}
     * @memberof UtilityParametersRequest
     */
    riskAversion?: number;
    /**
     * 
     * @type {number}
     * @memberof UtilityParametersRequest
     */
    normalisingConstant?: number;
}
/**
 * 
 * @export
 * @interface ValueWithLevel
 */
export interface ValueWithLevel {
    /**
     * 
     * @type {number}
     * @memberof ValueWithLevel
     */
    value?: number;
    /**
     * 
     * @type {Level}
     * @memberof ValueWithLevel
     */
    level?: Level;
}
/**
 * PortfolioAnalysisApi - fetch parameter creator
 * @export
 */
export const PortfolioAnalysisApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PortfolioAnalysisMarketContractFundamentalsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsecasePortfolioAnalysisMarketContractFundamentalsPost(body?: PortfolioAnalysisMarketContractFundamentalsBody, options: any = {}): FetchArgs {
            const localVarPath = `/api/usecase/PortfolioAnalysis/MarketContractFundamentals`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PortfolioAnalysisMarketContractFundamentalsBody3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PortfolioAnalysisPortfolioStatisticsWithLevelsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsecasePortfolioAnalysisPortfolioStatisticsWithLevelsPost(body: PortfolioAnalysisPortfolioStatisticsWithLevelsBody, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling apiUsecasePortfolioAnalysisPortfolioStatisticsWithLevelsPost.');
            }
            const localVarPath = `/api/usecase/PortfolioAnalysis/PortfolioStatisticsWithLevels`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PortfolioAnalysisPortfolioStatisticsWithLevelsBody3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PortfolioAnalysisPortfolioStressScenarioSetBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsecasePortfolioAnalysisPortfolioStressScenarioSetPost(body?: PortfolioAnalysisPortfolioStressScenarioSetBody, options: any = {}): FetchArgs {
            const localVarPath = `/api/usecase/PortfolioAnalysis/PortfolioStressScenarioSet`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PortfolioAnalysisPortfolioStressScenarioSetBody3" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortfolioAnalysisApi - functional programming interface
 * @export
 */
export const PortfolioAnalysisApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PortfolioAnalysisMarketContractFundamentalsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsecasePortfolioAnalysisMarketContractFundamentalsPost(body?: PortfolioAnalysisMarketContractFundamentalsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MarketContractFundamentalsResponse>> {
            const localVarFetchArgs = PortfolioAnalysisApiFetchParamCreator(configuration).apiUsecasePortfolioAnalysisMarketContractFundamentalsPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PortfolioAnalysisPortfolioStatisticsWithLevelsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsecasePortfolioAnalysisPortfolioStatisticsWithLevelsPost(body: PortfolioAnalysisPortfolioStatisticsWithLevelsBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PortfolioStatisticsWithLevelsResponse> {
            const localVarFetchArgs = PortfolioAnalysisApiFetchParamCreator(configuration).apiUsecasePortfolioAnalysisPortfolioStatisticsWithLevelsPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {PortfolioAnalysisPortfolioStressScenarioSetBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsecasePortfolioAnalysisPortfolioStressScenarioSetPost(body?: PortfolioAnalysisPortfolioStressScenarioSetBody, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PortfolioStressScenarioResponse>> {
            const localVarFetchArgs = PortfolioAnalysisApiFetchParamCreator(configuration).apiUsecasePortfolioAnalysisPortfolioStressScenarioSetPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PortfolioAnalysisApi - factory interface
 * @export
 */
export const PortfolioAnalysisApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {PortfolioAnalysisMarketContractFundamentalsBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsecasePortfolioAnalysisMarketContractFundamentalsPost(body?: PortfolioAnalysisMarketContractFundamentalsBody, options?: any) {
            return PortfolioAnalysisApiFp(configuration).apiUsecasePortfolioAnalysisMarketContractFundamentalsPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PortfolioAnalysisPortfolioStatisticsWithLevelsBody} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsecasePortfolioAnalysisPortfolioStatisticsWithLevelsPost(body: PortfolioAnalysisPortfolioStatisticsWithLevelsBody, options?: any) {
            return PortfolioAnalysisApiFp(configuration).apiUsecasePortfolioAnalysisPortfolioStatisticsWithLevelsPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {PortfolioAnalysisPortfolioStressScenarioSetBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsecasePortfolioAnalysisPortfolioStressScenarioSetPost(body?: PortfolioAnalysisPortfolioStressScenarioSetBody, options?: any) {
            return PortfolioAnalysisApiFp(configuration).apiUsecasePortfolioAnalysisPortfolioStressScenarioSetPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * PortfolioAnalysisApi - object-oriented interface
 * @export
 * @class PortfolioAnalysisApi
 * @extends {BaseAPI}
 */
export class PortfolioAnalysisApi extends BaseAPI {
    /**
     * 
     * @param {PortfolioAnalysisMarketContractFundamentalsBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAnalysisApi
     */
    public apiUsecasePortfolioAnalysisMarketContractFundamentalsPost(body?: PortfolioAnalysisMarketContractFundamentalsBody, options?: any) {
        return PortfolioAnalysisApiFp(this.configuration).apiUsecasePortfolioAnalysisMarketContractFundamentalsPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PortfolioAnalysisPortfolioStatisticsWithLevelsBody} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAnalysisApi
     */
    public apiUsecasePortfolioAnalysisPortfolioStatisticsWithLevelsPost(body: PortfolioAnalysisPortfolioStatisticsWithLevelsBody, options?: any) {
        return PortfolioAnalysisApiFp(this.configuration).apiUsecasePortfolioAnalysisPortfolioStatisticsWithLevelsPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {PortfolioAnalysisPortfolioStressScenarioSetBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioAnalysisApi
     */
    public apiUsecasePortfolioAnalysisPortfolioStressScenarioSetPost(body?: PortfolioAnalysisPortfolioStressScenarioSetBody, options?: any) {
        return PortfolioAnalysisApiFp(this.configuration).apiUsecasePortfolioAnalysisPortfolioStressScenarioSetPost(body, options)(this.fetch, this.basePath);
    }

}
