import React, { Component, ErrorInfo, ReactNode, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Button, Fab, Grid, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { FinancialPlanningDeveloper } from './FinancialPlanningDeveloper';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MultiGoalLifeEvents from './MultiGoal/MultiGoalLifeEvents';
import { SettingsContext } from './settings/SettingsContext';
import { PortfolioContext } from './settings/PortfolioContext';
import { getStressScenarioSets, getCurrencies, getMappedFunds, getScenarioSets } from './api_client/tsClient';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { ChartIFrame } from './ChartIFrame';
import { deleteDatabase, initDB, updateStore } from './db/dbFunctions';
import { defaultPortfolio, Portfolio } from './financialPlanning/Portfolio';
// import HayahDemo from './MultiGoal/HayahDemo';
import { createInvestmentAccount, createTransactionAccount } from './MultiGoal/MultiGoalRequestFactory';
import { Frequency, ProductCodeType, ScenarioSetResponseModel } from './api_client';
import { rallyBlue, rallyOrange } from './theming/useAppColors';
import HayahDemo from './MultiGoal/HayahDemo';
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { loginRequest } from './authConfig';


// const darkTheme = createTheme({
//   palette: {
//     mode: 'light',
//   },
// });

export const createThemeDelegate = (isLight: boolean) => createTheme({
  typography: {
    fontFamily: "Roboto Condensed, Roboto, sans-serif",
    h2: {
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: 48,
      }
    }
  },
  palette: {
    mode: isLight ? 'light' : 'dark',
    primary: {
      // main: isLight ? '#003f6d' : '#8d98aa',
      main: isLight ? '#003f6d' : rallyBlue[500],
    },
    secondary: {
      main: isLight ? '#60d3f6' : rallyOrange[900]
    },
    background: {
      default: isLight ? '#f5f5f5' : '#11111d',
      paper: isLight ? '#fafafa' : '#191924',
    },
    divider: isLight ? '#e5e5e5' : '#33333d',
  },
});

export const isLightDefault = false;


function AppContent() {

  const [theme, setTheme] = useState(createThemeDelegate(isLightDefault));

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: 'create',
      })
      .catch((error) => console.log(error));
  };

  const [isInitialized, setIsInitialized] = useState(false);

  const [settings, setSettings] = useState({
    locale: 'se',
    currency: 'SEK',
    availableCurrencies: ['SEK'],
    setCurrency: (currency: any) => {
      setSettings((state: any) => ({ ...state, currency: currency }));
    },
    isLightTheme: isLightDefault,
    setTheme: (isLight: any) => {
      setTheme(createThemeDelegate(isLight));
      setSettings((state: any) => ({ ...state, isLightTheme: isLight }));
    },
    errorMessage: undefined,
    setErrorMessage: (errorMessage: any) => {
      setSettings((state: any) => ({ ...state, errorMessage: errorMessage }));
    },
    rateRelativeSek: 1,
    currentScenarioSet: { name: 'Loading...', id: '' },
    setCurrentScenarioSet: (set: any) => {
      setSettings((state: any) => ({ ...state, currentScenarioSet: set }));
    },
    availableScenarioSets: [{ name: 'Loading...', id: '' }],
    availableStressScenarioSets: [{ name: 'Loading...', id: '' }],
    mappedFunds: [],
    resetDatabase: () => { deleteDatabase(); },
    percentiles: [
      { pecentile: 0.1, enabled: true },
      { pecentile: 0.3, enabled: true },
      { pecentile: 0.5, enabled: true },
      { pecentile: 0.7, enabled: true },
      { pecentile: 0.9, enabled: true }
    ],
    setPercentiles: (percentiles: any) => { setSettings((state: any) => ({ ...state, percentiles: percentiles })); },
    chartSettings: { fanFilled: true, resultAsValueOfToday: false, horizonInYears: 30 },
    setChartSettings: (chartSettings: any) => { setSettings((state: any) => ({ ...state, chartSettings: chartSettings })) },
    observationFrequency: Frequency.Annual,
    setObservationFrequency: (frequency: Frequency) => { setSettings((state: any) => ({ ...state, observationFrequency: frequency })) }
  } as any);

  useEffect(() => {
    Promise.all([getScenarioSets(), getStressScenarioSets()])
      .then(r => {
        if (isInitialized) {
          setSettings((state: any) =>
          ({
            ...state,
            currentScenarioSet: { name: r[0][0].name, id: r[0][0].scenarioSetId },
            availableScenarioSets: r[0].map((s: ScenarioSetResponseModel) => ({ name: s.name, id: s.scenarioSetId })),
            availableStressScenarioSets: r[1].map((s: ScenarioSetResponseModel) => ({ name: s.name, id: s.scenarioSetId }))
          }));
        }
      }
      );
  }, [isInitialized]);

  const [portfolioContext, setPortfolioContext] = useState({
    portfolios: [
      // defaultPortfolio()
      //{
      //   name: 'My Finances',
      //   assetsAndDebts: {
      //     investmentAccounts: [
      //       createInvestmentAccount('Growth ISK', '4fa85f64-5717-4562-b3fc-2c963f66afa6', { name: 'Sweden/DefaultOccupationalPension', code: 'Sweden/DefaultOccupationalPension', codeType: ProductCodeCodeType.Insurance }, 1000),
      //       createInvestmentAccount('Growth ISK2', 'aaa85f64-5717-4562-b3fc-2c963f66afa6', { name: 'Sweden/DefaultOccupationalPension', code: 'Sweden/DefaultOccupationalPension', codeType: ProductCodeCodeType.Insurance }, 1000)],
      //     transactionAccounts: [
      //       createTransactionAccount('Test account 1', '335b55df-9334-4e12-bf8d-bc70b621e8cc', 1000, 'SEK'),
      //       createTransactionAccount('Test account 2', '042d6ddd-ac9c-425b-8fdc-074d37c385f9', 1000, 'SEK')
      //     ],
      //     realEstate: [],
      //     mortgages: [],
      //     pensionAccounts: { sweIncomePensionAccounts: [], sweOccupationalPensionAccounts: [], swePremiumPensionAccounts: [] },
      //     unlistedShares: []
      //   },
      //   transactions: []
      // },
      // {
      //   name: 'Reference Portfolio',
      //   assetsAndDebts: {
      //     investmentAccounts: [
      //       createInvestmentAccount('Growth ISK', '4fa85f64-5717-4562-b3fc-2c963f66afa6', { name: 'Sweden/VeryHighRiskPortfolio', code: 'Sweden/DefaultOccupationalPension', codeType: ProductCodeCodeType.Insurance }, 1000),
      //       createInvestmentAccount('Growth ISK2', 'aaa85f64-5717-4562-b3fc-2c963f66afa6', { name: 'Sweden/DefaultOccupationalPension', code: 'Sweden/DefaultOccupationalPension', codeType: ProductCodeCodeType.Insurance }, 1000)],
      //     transactionAccounts: [
      //       createTransactionAccount('Test account 1', '335b55df-9334-4e12-bf8d-bc70b621e8cc', 1000, 'SEK'),
      //       createTransactionAccount('Test account 2', '042d6ddd-ac9c-425b-8fdc-074d37c385f9', 1000, 'SEK')
      //     ],
      //     realEstate: [],
      //     mortgages: [],
      //     pensionAccounts: { sweIncomePensionAccounts: [], sweOccupationalPensionAccounts: [], swePremiumPensionAccounts: [] },
      //     unlistedShares: []
      //   },
      //   transactions: []
      // }
    ] as Portfolio[],
    setPortfolios2: (mutator: (currentPortfolios: Portfolio[]) => void) => setPortfolioContext((state: any) => ({ ...state, portfolios: mutator(state.portfolios) })),
    setPortfolios: (portfolios: Portfolio[]) => setPortfolioContext((state: any) => ({ ...state, portfolios: portfolios })),
    activePortfolioIndex: 0,
    setActivePortfolioIndex: (newVal: number) => setPortfolioContext((state: any) => ({ ...state, activePortfolioIndex: newVal })),
    referencePortfolioIndex: 1,
    setReferencePortfolioIndex: (newVal: number) => setPortfolioContext((state: any) => ({ ...state, referencePortfolioIndex: newVal })),
    isLoaded: false
  });

  useEffect(() => {
    const storeConf: any = [
      {
        storeName: 'settings',
        initialState: settings,
        setState: setSettings
      },
      {
        storeName: 'portfolios',
        initialState: portfolioContext,
        setState: (mutator: (currentState: any) => any) => {
          setPortfolioContext((state) => {
            return ({ ...mutator(state), isLoaded: true })
          });
        },
      },
    ];
    initDB(storeConf, setIsInitialized);
  }, [settings, portfolioContext]);

  useEffect(() => {
    updateStore('settings', settings);
  }, [settings]);

  useEffect(() => {
    updateStore('portfolios', portfolioContext);

  }, [portfolioContext]);

  useEffect(() => {
    if (isInitialized) {
      getCurrencies().then((r: any) => setSettings((state: any) => ({ ...state, availableCurrencies: r })));
    }
  }, [isInitialized]);

  useEffect(() => {
    if (settings.currentScenarioSet.id === '')
      return;
    getMappedFunds(settings.currentScenarioSet.id)
      .then(result => {
        setSettings((state: any) => ({ ...state, mappedFunds: result }));
      });
  }, [settings.currentScenarioSet]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthenticatedTemplate>
        <SettingsContext.Provider value={settings}>
          <PortfolioContext.Provider value={portfolioContext}>
            {/* <RouterProvider router={router} /> */}
            {/* <MultiGoalLifeEvents /> */}
            <ErrorBoundary>
              <BrowserRouter>
                <Routes>
                  <Route path="/developer" element={<FinancialPlanningDeveloper />} />
                  {/* <Route path="/chart" element={<ChartIFrame />} /> */}
                  <Route path="/lifeevents" element={<MultiGoalLifeEvents />} />
                  <Route path="/simplegoals" element={<HayahDemo />} />
                  {/* <Route path="/" element={<HayahDemo />} /> */}
                  <Route path="/" element={<MultiGoalLifeEvents />} />
                </Routes>
              </BrowserRouter>
            </ErrorBoundary>
          </PortfolioContext.Provider>
        </SettingsContext.Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Grid container direction="row" justifyContent="flex-start" alignItems='stretch' style={{ minHeight: '100vh' }}>
          <div style={{ margin: 'auto' }}>
            <Grid container
              direction="column"
              justifyContent="center"
              alignItems="center">
              <Grid >
                <img
                  height="50"
                  width="50"
                  src={settings.isLightTheme ? 'logolight.png' : 'logodark.png'}
                  alt=""
                />
              </Grid><br />
              <Grid>
                <Button variant="contained" onClick={handleRedirect} color="primary">
                  Sign in
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>

      </UnauthenticatedTemplate>
    </ThemeProvider>
  );
}

const App = ({ instance }: any) => {
  return (
    <MsalProvider instance={instance}>
      <AppContent />
    </MsalProvider>
  );
};

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  message: string
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    message: ''
  };

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, message: error.message + ' ' + error.stack };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '4px' }}>
          <Typography>An unhandled error has occured</Typography><br />
          <Button
            variant={'outlined'}
            onClick={() => {
              deleteDatabase();
              window.location.reload();
            }}>Delete database</Button><br />
          {this.state.message}
        </div>
      );
    }

    return this.props.children;
  }
}

export default App;
