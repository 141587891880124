import React from 'react';
import { Button, TextField, Typography } from '@mui/material';

export function RequestInput(props: any): any {

    const { jsonRequest, setJsonRequest, token, setToken, baseUri, setBaseUri } = props;

    const displayFile = (e: any) => {
        e.preventDefault()
        const reader = new FileReader()
        reader.onload = (e) => {
            const text = reader.result as string
            var obj = JSON.parse(text)
            var pretty = JSON.stringify(obj, null, 2)
            setJsonRequest(pretty)
        };
        reader.readAsText(e.target.files[0])
    }

    const downloadFile = () => {
        const element = document.createElement("a")
        var requestElement = (document.getElementById("Request-Body") as HTMLInputElement).value
        var obj = JSON.parse(requestElement)
        var pretty = JSON.stringify(obj, null, 2)
        const file = new Blob([pretty],
            { type: "text/plain" })

        element.href = URL.createObjectURL(file)
        element.download = "request.json"
        document.body.appendChild(element)
        element.click()
    }

    const fileInput = React.useRef();

    return (
        <div style={{ marginBottom: '1rem' }}>
            <Typography variant='body1'>
                Request input
            </Typography>
            <form>
                <TextField
                    id="Request-Body"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        style: { fontSize: 14 }
                    }}
                    multiline
                    fullWidth
                    rows={20}
                    value={jsonRequest}
                    onChange={(e) => setJsonRequest(e.target.value)}
                    required
                    placeholder="Request input"
                >
                </TextField>
            </form>
            <div>
                <Button
                    color="primary"
                    onClick={() => {
                        const input: any = fileInput?.current;
                        return input?.click();
                    }}
                >
                    upload file
                </Button>

                <input
                    ref={fileInput}
                    type="file"
                    onChange={displayFile}
                    style={{ display: 'none' }}
                />
                <Button onClick={downloadFile}>Download Request</Button>
            </div>
            <TextField
                    id="Token"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        style: { fontSize: 14 }
                    }}
                    fullWidth
                    value={baseUri}
                    onChange={(e) => setBaseUri(e.target.value)}
                    required
                    placeholder="Base uri"
                >
                </TextField>
            <TextField
                    id="Token"
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                        style: { fontSize: 12 }
                    }}
                    multiline
                    fullWidth
                    rows={4}
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    required
                    placeholder="Bearer token"
                >
                </TextField>
        </div>
    )
}