import { Delete, Edit, ExpandMore, FilterNone } from "@mui/icons-material";
import { Grid, IconButton, IconButtonProps, styled, Typography, useTheme } from "@mui/material";
import { TextNumberFormat } from "../../NumberFormatCustom";

export function GroupDisplayHeader(props: { name: string; currentValue: number; edit: () => void; setIsExpanded: (newVal:boolean) => void; isExpanded:boolean; color: string }) {
    const theme = useTheme();

    return <Grid container style={{ padding: theme.spacing(1) }}>
        <Grid item xs={'auto'}>
            <div style={{ background: props.color, width: 2, height: '100%', marginRight: 3, marginLeft: -7 }}></div>
        </Grid>
        <Grid item xs 
            style={{ paddingTop: -6 }}
        >
            <Typography variant="caption" style={{ 
                color: theme.palette.text.secondary,
                // marginTop: -20,
                maxHeight: 5
                }}>Group</Typography><br/>
            <Typography 
            variant="body2">{props.name}</Typography>
        </Grid>
        <Grid item xs='auto' style={{ paddingTop: 6, width: theme.spacing(12) }}>
            {/* <Typography variant="body2"><TextNumberFormat roundToNearest={1} value={props.currentValue} /></Typography> */}
        </Grid>
        <Grid item xs='auto'>
            <IconButton style={{ color: theme.palette.text.secondary }} size="small" onClick={props.edit}>
                <Edit fontSize="small" />
            </IconButton>
        </Grid>
        <Grid item xs='auto'>
        <ExpandMoreButton
                    expand={props.isExpanded}
                    onClick={() => props.setIsExpanded(!props.isExpanded)}
                    aria-expanded={props.isExpanded}
                    aria-label="show more">
                    <ExpandMore fontSize="small" />
                </ExpandMoreButton>
        </Grid>
    </Grid>;
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}
const ExpandMoreButton = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton size="small"  {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));